import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  private headers: any;
  private userToken: string = '';

  constructor(private http: HttpClient) {
    this.headers = {
      // 'Authorization': 'Bearer ' + this.userToken,
      'hpApp-Token': environment.ENTITY_TOKEN,
    }
  }

  public initVideoSession(apptRefNo: string, facilityId: string, patientId: string, patientName: string, refType: string) {
    let payload = {
      appointmentNo: apptRefNo,
      facilityId: facilityId,
      refType: refType,
      patientId: patientId,
      patientName: patientName
    }
    return new Observable(observer => {
      this.http.post(environment['API_URL'] + '/appointments/initVideoSession', payload, { headers: this.headers }).subscribe((res) => {
        if (res['status'] == 'SUCCESS')
          return observer.next(null);
        return observer.error();
      }, _ => observer.error())
    })
  }

  public getUserToken(): String {
    return this.userToken;
  }
  public getVideoStatus(refNo: string, refType: string): Observable<any> {
    const payload = {
      refNo: [refNo],
      refType
    }
    return new Observable(observer => {
      this.http.post(environment['API_URL'] + '/appointments/getVideoSessionStatus', payload, { headers: this.headers }).subscribe((res) => {
        if (res['status'] == 'SUCCESS')
          return observer.next(res['data']);
        return observer.error();
      }, _ => observer.error())
    })
  }

  public endVideoSession(appointmentId, facilityId): Observable<any> {
    const payload = {
      appointmentNo: appointmentId,
      facilityId: facilityId,
      refType: 'APPT'
    }
    return new Observable(observer => {
      this.http.post(environment['API_URL'] + '/appointments/endVideoSession', payload, { headers: this.headers })
        .subscribe(data => observer.next(data));
    })
  }

  public validateSession(apptRefNo, password): Observable<Object> {
    const payload = {
      apptRefNo: apptRefNo,
      password: password
    }
    return new Observable(observer => {
      this.http.post(environment['API_URL'] + '/schedules/validateAppointment', payload, { headers: this.headers })
        .subscribe(res => {
          if (res['status'] == 'SUCCESS') {
            if (res['data'] && res['data']['token'] && res['data']['token']['token']) {
              this.userToken = res['data']['token']['token'];
              this.headers['Authorization'] = 'Bearer ' + res['data']['token']['token'];
              return observer.next(res['data']);
            }
          }
          return observer.error();
        }, _ => observer.error())
    })
  }
}
