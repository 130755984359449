import { Injectable } from '@angular/core';
import { Idle } from '@ng-idle/core';
import { of, Subject } from 'rxjs';
import { Observable } from 'rxjs'
import { finalize, share, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoaderService } from './loader.service';
import { APPTYPE, WFDriverElement, PERMISSIONSAVAILABLE , BookAppointmentConcept} from '../app.type';
import * as moment from 'moment-timezone';
import {find as _find} from 'lodash';

import { CookieService } from './cookie.service';
import { InterceptorService } from './interceptor.service';
import { UtilitiesService } from './utilities.service';
import { SocketService } from "./socket.service";
import * as AppConstant from '../app.string';
import { CTX, LOGIN_CONTEXT, ALL_COUNTRIES, DEFAULTNORMALCY} from '../app.const';

import { environment } from '@env/environment';
import { visitType } from '@type';
import { ToastServiceService } from './toast-service.service';
import { APP_VERSION, PORTAL_SOURCE, REPO_TOKEN, USER_TOKEN } from '@string';
import { Router, ActivatedRoute } from '@angular/router';
import { FeatureFlagsService } from './feature-flags.service';
import { GoogleTagsService } from './google-tags.service';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';
import { PhoneNumberUtil } from "google-libphonenumber";
import { Platform } from '@angular/cdk/platform';
import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private updateInfo: any;
  private userInfo: any;
  private userSession: any;
  public userId;
  private repoUrl: string;
  private geoLocation: any;
  private userCredential:any;
  private rtl: any;
  private remaindMe: boolean;
  private isHideFacilityCategory: boolean = false;
  public setLanguage: any;
  public setLabels: any;
  public currentLanguage: string = 'en';
  public currentLocale: string = 'en';
  public isPreCheck: boolean;
  public isSSOLogin: boolean;
  public ssoUserCredential: any;
  public ssoUserObject:any;
  public mapApiSet: boolean;
  public isPatientTask:boolean;
  public loyaltyId: any;
  private localeLabels: any;
  public isTagsEnabled: boolean;
  public GTMContainerId: string;
  private roundOrTruncate: 'R' | 'T' = 'R';
  private roundOfDecimals: number | string = 2;
  public defaultAddress = 'Chennai';
  private countryList: Array<Object> = [];
  private loginContext: any;
  private cache: any = null;
  private cachedObservable: any = null;
  private homecareOptions: any = null;
  public appPromoSubject: Subject<any> = new Subject<any>();
  public sectionFocusListener: Subject<any> = new Subject<any>();
  public configLoadedSubject: Subject<any> = new Subject<any>();
  public configLoaded: boolean = false;
  public userUpdateSubject: Subject<any> = new Subject<any>();
  public npsRatingSubject: Subject<any> = new Subject<any>();
  private taskViewSettings:any = {
    scrollTime: 50,
    minimizeFulfilledSection: true
  }
  private patientRegistrationPayload: any;
  private appType: APPTYPE = 'portal'
  private isExtidLogin = true;

  private googleCaptchaKeyv2: string = null;
  private googleCaptchaKeyv3: string = null;
  private googleCaptchaVersion: 2 | 3;
  private passwordPolicy: any = {}
  private localeLabelData:any;
  public pxAppSettings: any;
  private resourceMaster: Array<any>;
  public facilityMaster: {actualData: Array<any>, facilityList: Array<any>, facilityProfile: Array<any>, facilityArray?: Array<string>};
  private combinedAppPermissions: any = {}
  private defaultCountryDetail;
  public passwordRegex: any;
  public minPasswordLength: any;
  public maxPasswordLength: any;
  public normalcyInds: any;
  private npsModalDisplayed: boolean = false;
  private timezones: any = {};
  private offsets: any = {};
  public paymentIntervalForReference: any;
  public defaultFacilityForRegistration: any;
  public isFinancialStatusUpdated: boolean;
  public inclinicSelection: any;
  private payerGrpUpdated = new Subject<any>();
  public payerGrpUpdated$: Observable<any>;
  public mandatoryConsent: any;
  public marketingConsent:any;

  constructor(private cookie: CookieService, private interceptor: InterceptorService, public socket: SocketService, private http: HttpClient, private toast: ToastServiceService, private router: Router, private route: ActivatedRoute, private featureFlagsService: FeatureFlagsService, private gtmService:GoogleTagsService, public platform: Platform,) {
    this.updateInfo = new Array<any>();
    this.userInfo = new Array<any>();
    this.userSession = new Array<any>();
    this.countryList = new Array<Object>();
    this.payerGrpUpdated$ = this.payerGrpUpdated.asObservable();
    this.mapApiSet = false;
    if(window.location['href'].includes(`/${AppConstant.ROUTE_VACCINE}`) || window.location['href'].includes(`/${AppConstant.ROUTE_TASK}/`) || window.location['href'].includes(`/${AppConstant.ROUTE_VIEW}/`))
      this.isPatientTask = true;
    this.retriveStorage();
    this.setTaskViewSettings(null);
    let appType: APPTYPE = this.cookie.getStorage('APPTYPE') as APPTYPE;
    if (appType) {
      this.setAppType(appType);
    }

    let defaultCountryDetail = ALL_COUNTRIES.filter(c => c[2] == environment.COUNTRY_CODE || `+${c[2]}` == environment.COUNTRY_CODE);
    if (defaultCountryDetail.length > 1 && environment['COUNTRYID']) {
      this.defaultCountryDetail = defaultCountryDetail.find(c => c[1] == environment['COUNTRYID'])
    } else {
      this.defaultCountryDetail = defaultCountryDetail[0]
    }
  }

  public getDefaultCountryDetailForSignIn() {
    return this.defaultCountryDetail;
  }

  //NOTE
  // 1. GET SET METHODS
  // 2. GET METHODS
  // 3. API SERVICES

  // GET SET METHODS

  private retriveStorage() {
    if (localStorage.getItem(AppConstant.REMAIND_ME)) {
      this.remaindMe = Boolean(localStorage.getItem(AppConstant.REMAIND_ME));
      this.cookie.setRemaindMe(this.remaindMe);
    }
    let default_settings = [...AppConstant.DEFAULT_STORAGE_SETTINGS];
    default_settings.forEach(setting => {
      let value = this.getLocalStorage(AppConstant[setting]);
      if (!value) {
        value = this.getStorage(AppConstant[setting]);
      }
      if (value) {
        this.setStorage(AppConstant[setting], value);
        switch (AppConstant[setting]) {
          case AppConstant.USER_OBJECT:
            this.setUserInfo(JSON.parse(value), this.remaindMe, false, true);
            break;
          case AppConstant.USER_LANGUAGE:
            let lang = JSON.parse(value);
            this.setCurrentLanguage(lang);
            break;
        }
      }
    });
    this.getGeoLocation().subscribe();
  }

  public setPackages(){
    this.getUpdateInfo('directives').subscribe(data => {
      data.userDefined.find(def => {
        if(def.directiveGroup == 'HOSPSERV'){
          this.setStorage(AppConstant.HEALTHPKG_LIST, JSON.stringify(def));
        }
      })
    });
  }

  public hideFacilityCategory() {
    return this.isHideFacilityCategory;
  }
  public setStorage(key, value) {
    this.cookie.set(key, value);
  }
  public clearStorage() {
    this.cookie.clearStorage();
  }
  public getStorage(key) {
    return this.cookie.getStorage(key);
  }
  public getLocalStorage(key) {
    return this.cookie.getLocalStorage(key);
  }
  public setLoginInfo(data: any, remaindme: boolean, isOpenSSOLogin:boolean = false) {
    this.setUserInfo(data, remaindme, isOpenSSOLogin);
    //Added function to fetch factilities and practitioner based on resourceListing API
    if (this.cookie.getStorage(AppConstant.USER_TOKEN))
      this.getPatientFacilities(true);
    let time = this.getMoment().add(environment.TOKEN_EXPIRY_HOURS, 'hours').valueOf();
    this.setStorage(AppConstant.EXPIRY_TIME, time);
    const obj = {
      event_source: 'Website',
      language: this.currentLanguage,
      'user_id': data.personId
    }
    this.gtmService.pushGTMEvent(null, 'global_variables', obj)
    if (data && data.patientRegistration && data.patientRegistration.enable) {
      this.patientRegistrationPayload = data.patientRegistration
      return true;
    } else {
      this.patientRegistrationPayload = null;
    }
    return false
  }
  public updateUserInfo(obj) {
    let data = JSON.parse(this.getStorage(AppConstant.USER_OBJECT));
    if (!data)
      data = {};
    if (!this.userInfo.personDetails) {
      this.userInfo.personDetails = {}
    }
    Object.keys(obj).forEach(b => {
      switch (b) {
        case 'email':
          this.userInfo.personDetails['email'] = obj['email'];
          data.emailId = obj['email']
          break;
        case 'personName':
          this.userInfo.personDetails['personName'] = obj['personName'];
          data.name = obj['personName']
          break;
        case 'mobile':
          this.userInfo.personDetails['mobile'] = obj['mobile'];
          data.mobile = obj['mobile'];
          break;
        case 'countryCode':
          this.userInfo.personDetails['countryCode'] = obj['countryCode'];
          data.countryCode = obj['countryCode'];
          break;
        case 'photoUrl':
          this.userInfo.personDetails['photoUrl'] = obj['photoUrl'];
          data.photoUrl = obj['photoUrl'];
          break;
      }
    })
    this.setStorage(AppConstant.USER_OBJECT, JSON.stringify(data));
  }
  public setUserInfo(data: any, remaindme: boolean, isOpenSSOLogin:boolean = false, refresh:boolean = false) {
    if (!data) {
      return;
    }
    let personDetails = null;
    let credentials = null;
    let repoDetails = null;
    let linkedPatients = null;
    this.remaindMe = remaindme;
    this.cookie.setRemaindMe(this.remaindMe);
    if (isOpenSSOLogin)
      data.isOpenSSOLogin = isOpenSSOLogin
    this.userSession = data;
    this.userCredential = data.pin;
    personDetails = {
      personId: data.personId,
      personName: data.name,
      photoUrl: data.photoUrl,
      email: data.emailId,
      mobile: data.mobile,
      countryCode: data.countryCode,
      contextPersonName: data.contextPersonName,
      isHof: data.isHof,
      externalId: data.natid
    };
    credentials = {
      token: data?.credentials?.token
    }
    if (data?.credentials?.repositoryAccess?.success) {
      repoDetails = {
        token: data.credentials?.repositoryAccess?.token,
        url: data.credentials?.repositoryAccess?.url,
      }
      if(refresh && localStorage) {
        let data = this.getLocalStorage(REPO_TOKEN);
        this.interceptor.setRepoToken(data);
        this.setStorage(REPO_TOKEN, data);
      } else {
        this.interceptor.setRepoToken(data.credentials?.repositoryAccess?.token);
        this.setStorage(AppConstant.REPO_TOKEN, data.credentials?.repositoryAccess?.token);
    }
    this.repoUrl = data.credentials?.repositoryAccess?.url
  }
    if (data.linkedPatients) {
      linkedPatients = data.linkedPatients;
    }
    this.userInfo = {
      personDetails: personDetails,
      credentials: credentials,
      repoDetails: repoDetails,
      linkedPatients: linkedPatients,
      isOpenSSOLogin: data.isOpenSSOLogin
    }
    console.log(this.userInfo)
    if(refresh && localStorage) {
      let data = this.getLocalStorage(USER_TOKEN);
      this.interceptor.setUserToken(data);
      this.setStorage(USER_TOKEN, data);
    } else {
    this.interceptor.setUserToken(data.credentials?.token);
    this.setStorage(AppConstant.USER_TOKEN, data.credentials?.token);
    }
    this.setStorage(AppConstant.USER_OBJECT, JSON.stringify(data));
    this.getUpdateInfo('configuration').subscribe(data => {
        if(data['profile'] && data['profile']['googleMapApiKey']){
          let key = data['profile']['googleMapApiKey'];
          this.setGoogleApiKey(key);
        }
    });
    this.featureFlagsService.setHOF(personDetails.isHof == 'Y');
    this.checkMapsKey()
  }

  private checkMapsKey() {
    if (this.configLoaded) {
      this.setMapsKey();
    } else {
      this.configLoadedSubject.subscribe(_ => {
        this.setMapsKey();
      })
    }
  }

  private setMapsKey() {
    const data = this.updateInfo['configuration']
    if (data['profile'] && data['profile']['googleMapApiKey']) {
      let key = data['profile']['googleMapApiKey'];
      this.setGoogleApiKey(key);
    }
    this.setTruncateInfo();
  }


  public setGoogleApiKey(key) {
    if (key && !this.mapApiSet) {
        try {
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = `https://maps.googleapis.com/maps/api/js?v=3&key=${key}&libraries=places`; //& needed
            document.body.appendChild(script);
            this.mapApiSet = true;
        } catch (err) {
            this.mapApiSet = false;
        }
    } else {
        console.log('MAP key not configured');
    }
}

  public setSSOCredentials(data, remaindme) {
    this.remaindMe = remaindme;
    this.cookie.setRemaindMe(this.remaindMe);
    this.interceptor.setAccessToken(data.ssocredentials.access_token);
    this.setStorage(AppConstant.SSO_CREDENTIAL, JSON.stringify(data.ssocredentials));
    this.setStorage(AppConstant.SSO_USER_OBJECT, JSON.stringify(data.userInfo));
    this.loyaltyId = data.userInfo.username;
  }

  public setSSOObjectOnUpdate(data) {
    // this.remaindMe = remaindme;
    this.cookie.setRemaindMe(this.remaindMe);
    this.setStorage(AppConstant.SSO_USER_OBJECT, JSON.stringify(data));
    this.loyaltyId = data.username;
  }

  public setAccessToken(token) {
    this.interceptor.setAccessToken(token);
    this.setStorage(AppConstant.ACCESS_TOKEN, token);
  }

  public setAccessTokenOnRefresh(token): Observable<any>{
    return Observable.create(observer => {
      this.interceptor.setAccessToken(token);
      this.setStorage(AppConstant.ACCESS_TOKEN, token);
      observer.next('SUCCESS');
    })
  }

  public setMandatoryConsent(data) {
    this.mandatoryConsent = data;
  }

  public setMarketingConsent(data) {
    this.marketingConsent = data;
  }

  public setInclinicSelection(data) {
    this.inclinicSelection = data;
  }

  public getInclinicSelection() {
    return this.inclinicSelection;
  }

  public clearInclinicSelection() {
    this.inclinicSelection = null;
  }

  public getSSOCredentials(): Observable<any>{
    return Observable.create(observer => {
      this.ssoUserCredential = JSON.parse(sessionStorage.getItem(AppConstant.SSO_CREDENTIAL));
      observer.next(this.ssoUserCredential);
    })
  }

  public getSSOUserObject(): Observable<any>{
    return Observable.create(observer => {
      let userObj = JSON.parse(sessionStorage.getItem(AppConstant.SSO_USER_OBJECT));
      observer.next(userObj);
    })
  }

  public getMandatoryConsent() {
    return this.mandatoryConsent;
  }

  public getMarketingConsent() {
   return this.marketingConsent;
  }

  public getloyaltyId() {
    if(this.loyaltyId)
      return this.loyaltyId
    let userObj = JSON.parse(sessionStorage.getItem(AppConstant.SSO_USER_OBJECT));
    this.loyaltyId = userObj.username
    return userObj.username
  }

  public getSSOUserdetail(){
    this.ssoUserObject = JSON.parse(sessionStorage.getItem(AppConstant.SSO_USER_OBJECT));
    return this.ssoUserObject;
  }

  public getCaptchaKey(version) {
    if (!version) version = this.googleCaptchaVersion;
    return version == 2 ? this.googleCaptchaKeyv2 : this.googleCaptchaKeyv3;
  }
  public getCaptchaVersion() {
    return this.googleCaptchaVersion;
  }

  private setCaptchaKey(keyv3 , version:2|3 = 3) {
    this.googleCaptchaKeyv3 = keyv3;
    this.googleCaptchaVersion = version;
  }

  public setDirection(data){
    this.cookie.setRemaindMe(true);
    this.setStorage(AppConstant.USER_LANGUAGE, JSON.stringify(data));
    location.reload()
  }

  public setDefaultFacilityForRegistration(facilityId) {
    this.defaultFacilityForRegistration = facilityId;
  }

  public getDefaultFacilityForRegistration() {
    return this.defaultFacilityForRegistration;
  }

  public getCurrency() {
    let localeCurrency;
    if(this.localeLabelData){
      localeCurrency=this.localeLabelData['localeCurrency'] ?  this.localeLabelData['localeCurrency'] : this.updateInfo['configuration']['currency'];
    }else{
      this.getLabels().subscribe(data => {
        this.localeLabelData=data;
        localeCurrency=this.localeLabelData['localeCurrency'] ?  this.localeLabelData['localeCurrency'] : this.updateInfo['configuration']['currency'];
      });
    }

    return localeCurrency;
  }

  public getAppDirection() {
    return this.rtl == 'true' ? 'rtl' : 'ltr';
  }
  public setCurrentLanguage(data) {
    console.trace('setCurrentLanguage', data)
    if(data){
      this.currentLanguage = data.localeCode;
      if(data.otherOptions && data.otherOptions != null ){
        this.currentLocale = data.otherOptions.equivalentMomentCode ? data.otherOptions.equivalentMomentCode : data.localeCode;
      } else {
        this.currentLocale = data.localeCode;
      }
      console.log("get rtl", this.currentLocale)
      this.rtl = data.rtl;
      if (data.rtl === 'true')
        document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
      else
        document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
    }
    moment.locale('en');
  }

  public getDirection(): Observable<any> {
    return Observable.create(observer => {
      observer.next(this.rtl);
    })
  }

  public getUserInfo(key?: string): Observable<any> {
    return Observable.create(observer => {
      if (key) {
        if (key in this.userInfo) {
          observer.next(this.userInfo[key]);
        } else {
          observer.next(null);
        }
      } else {
        observer.next(this.userInfo);
      }
    })
  }
  public removeUserInfo() {
    this.userInfo = null;
    sessionStorage.removeItem(AppConstant.USER_OBJECT);
  }
  public setUpdateInfo(data: any): Observable<any> {
    let tempContentResponse = JSON.parse(localStorage.getItem(AppConstant.CONTENT_RESPONSE));
    return Observable.create(observer => {
      if (data) {
        this.updateInfo = data.reduce((obj, item) => {
          if (item.body)
            return { ...obj, [item.header.type]: item.body };
          else if (tempContentResponse)
            return { ...obj, [item.header.type]: tempContentResponse[item.header.type] }
          else
            return obj;
        }, {});
        let contentHeaders = data.reduce((header, d) => {
          if (d.header.type != 'fbcredentials')
            header.push(d.header);
          return header;
        }, [])
        if (environment['RETRIEVE_LOCALSTORAGE']) {
          localStorage.setItem(AppConstant.CONTENT_HEADER, JSON.stringify(contentHeaders));
        }
      } else {
        this.updateInfo = tempContentResponse
      }
      if (environment['RETRIEVE_LOCALSTORAGE']) {
        localStorage.setItem(AppConstant.CONTENT_RESPONSE, JSON.stringify(this.updateInfo));
      } else {
        localStorage.removeItem(AppConstant.CONTENT_HEADER);
        localStorage.removeItem(AppConstant.CONTENT_RESPONSE);
      }
      console.log('updateInfo ==>>', this.updateInfo)
      if(this.updateInfo['configuration'] && this.updateInfo['configuration']['pxAppSettings'] && this.updateInfo['configuration']['pxAppSettings']['enableDataDogIntegration'] =='Y'){
        this.initDataDog(this.updateInfo['configuration']['pxAppSettings']['dataDogConfig']);
      }
      if (this.updateInfo?.['fbcredentials']?.['repoUrl'])
        this.setRepoUrl(this.updateInfo['fbcredentials']['repoUrl']);
      if (this.updateInfo && this.updateInfo['facilityServices'] && this.updateInfo['facilityServices']['facilities']) {
        this.updateInfo['facilityServices']['facilities'] = this.updateInfo['facilityServices']['facilities'].map(fac => {
          fac.specialities = fac.specialities.map(spec => {
            let pracList = fac.practitioners.filter(prac => {
              return prac.specialityId === spec.specialityId;
            });
            let videoPrac = fac.practitioners.filter(prac => {
              return prac.specialityId == spec.specialityId && (prac.schedulerControls['consultType'] == 'HOSPVIDEO' || prac.schedulerControls['consultType'] == 'VIDEO');
            })
            let hospPrac = fac.practitioners.filter(prac => {
              return prac.specialityId == spec.specialityId && (prac.schedulerControls['consultType'] == 'HOSPVIDEO' || prac.schedulerControls['consultType'] != 'VIDEO');
            })
            return { ...spec, pracList: pracList, pracCount: pracList.length, VIDEO: videoPrac, VIDEOCOUNT: videoPrac.length, HOSPITAL: hospPrac, HOSPITALCOUNT: hospPrac.length }
            })
            return fac;
          });

          if(this.cookie.getStorage(AppConstant.USER_TOKEN))
            this.getPatientFacilities(true);

        this.featureFlagsService.initialize({ ...this.updateInfo})

        // if (this.updateInfo['configuration'] && this.updateInfo['configuration']['profile']['googlTagsManager']) {
        //   this.setGTMTagConfig(this.updateInfo['configuration']['profile']['googlTagsManager']);
        // }
        if (this.updateInfo['configuration'] && this.updateInfo['configuration']['homecare']) {
          this.homecareOptions = this.updateInfo['configuration']['homecare'];
        }
        this.presentAppPromotion(this.updateInfo);
        if (this.updateInfo['configuration'] && this.updateInfo['configuration']['profile']['googleCaptchaKey']) {
          this.setCaptchaKey(this.updateInfo['configuration']['profile']['googleCaptchaKey'], 3);
        }
        if (this.updateInfo['configuration'] && this.updateInfo['configuration']['identityRules'] && this.updateInfo['configuration']['identityRules']['passwordpolicy']) {
          this.passwordPolicy = this.updateInfo['configuration']['identityRules']['pxpasswordpolicy']
          console.log(this.passwordPolicy)
        }
        if (this.updateInfo['configuration'] && this.updateInfo['configuration']['identityRules'] && this.updateInfo['configuration']['identityRules']) {
          this.passwordRegex = this.updateInfo['configuration']['identityRules'].pxAppPasswordRegex
          this.minPasswordLength = this.updateInfo['configuration']['identityRules'].minPasswordLength
          this.maxPasswordLength = this.updateInfo['configuration']['identityRules'].maxPasswordLength
        }
        if(this.updateInfo['configuration'] && this.updateInfo['configuration']['pxAppSettings']){
          this.pxAppSettings = this.updateInfo['configuration']['pxAppSettings']
          this.updatePxAppPermissions(this.pxAppSettings);
        }
        console.log('integrationSettings', this.updateInfo.configuration.integrationSettings)
        if (this.updateInfo?.configuration?.integrationSettings?.defaultTimezone) {
          this.setDefaultTimezone(this.updateInfo.configuration.integrationSettings.defaultTimezone);
        }
        if (this.updateInfo['configuration'] && this.updateInfo['configuration']['identityRules'] && this.updateInfo['configuration']['identityRules'] && this.updateInfo['configuration'] && this.updateInfo['configuration']['identityRules'] && this.updateInfo['configuration']['identityRules']['newRegistration'] && this.updateInfo['configuration']['identityRules']['newRegistration']['defaultFacilityForRegistration']) {
          this.setDefaultFacilityForRegistration(this.updateInfo['configuration']['identityRules']['newRegistration']['defaultFacilityForRegistration']);
        }
        if (this.updateInfo?.facilities?.facilities) {
          this.setFacilitiesTimezone(this.updateInfo.facilities.facilities)
        }
        observer.next(null);
      } else {
        observer.next(null);
      }
    })
  }

  public getHomecareOptions() {
    //Mock JSON for homecare service
    // this.homecareOptions = HOME_CARE_SERVICES
    return this.homecareOptions;
  }
  public getUpdateInfo(key?: string): Observable<any> {
    return Observable.create(observer => {
      if (key) {
        if (this.updateInfo[key]) {
          let retVal = this.updateInfo[key]
          // if (key == 'facilities')
          //   retVal['facilityServices'] = this.updateInfo['facilityServices']['facilities']
          if (key == 'facilities' || key == 'facilityServices'){
            let val =  this.getAttributeForListingType(key, true)
            observer.next(val);
          }else observer.next(this.updateInfo[key]);
        } else {
            console.log('getUpdateInfo =>', key)
            this.updateCheck().subscribe(data => {
              // this.setUpdateInfo(data).subscribe(_ => {
                if (key == '*') observer.next(this.updateInfo)
                else observer.next(this.updateInfo[key]);
                this.setTruncateInfo();
              // })
            });
        }
      } else {
        observer.next(this.updateInfo);
      }
    });
  }

  public refreshSession(): Observable<any>{
    let refresh = JSON.parse(sessionStorage.getItem(AppConstant.SSO_CREDENTIAL));
    let payload = {
      refresh_token: refresh.refresh_token,
      source: PORTAL_SOURCE
    }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_EXTERNAL_AUTH}/refreshSession`, payload, { reportProgress: true}).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }
  public getDefaultFacility() {
    return this.updateInfo['facilityServices']['facilities'][0]['facilityId'];
  }
  public getFacility() {
    let facilities = this.getAttributeForListingType('specialities', true)
    return facilities
    // return this.updateInfo['facilityServices']['facilities'];
  }
  //GET METHODS
  public getLanguages(): Observable<any> {
    return Observable.create(observer => {
      this.getConfigurationInfo.subscribe(data => {
        let returnData = data['languages'] ? data['languages'] : [];
        observer.next(returnData);
      });
    });
  }

  public getExternalInterface(): Observable<any> {
    return Observable.create(observer => {
      this.getConfigurationInfo.subscribe(data => {
        let returnData = data['features'].externalInterface ? data['features'].externalInterface : false;
        observer.next(returnData);
      });
    });
  }
  public getLabels(checkCurLang: boolean = false): Observable<any> {
    return Observable.create(observer => {
      if (!this.configLoaded) {
        this.configLoadedSubject.subscribe(_ => {
          const returnData = this.checkLabels();
          observer.next(returnData);
        })
      } else {
        const returnData = this.checkLabels();
        observer.next(returnData);
      }
    });
  }

  private checkLabels() {
    if (this.isPatientTask)
      this.setLabels = this.currentLanguage;
    else
      this.setLabels = this.setLabels != undefined ? this.setLabels : this.currentLanguage;
    let returnData = this.updateInfo['labels'].localization.filter(item => {
      return item.locale === this.setLabels;
    })[0]['labels'];
    return returnData;
  }

  public getLabelsBasedOnLang(key, lang) {
    let langLabels = this.updateInfo['labels'].localization;
    let langObj = langLabels.find(lab => lab.locale == lang);
    if (!langObj) langObj = langLabels[0];
    return langObj.labels[key];
  }

  public getErrorMessage(error) {
    if (typeof error === 'string')
      return error;
    if (error && error.error && error.error.error && error.error.error.message)
      return error.error.error.message;
    return 'Something went wrong';
  }

  public getUserId() {
    this.userId = this.userInfo?.personDetails?.personId
    return Observable.create(observer => {
      observer.next(this.userId);
    })
  }
  public getPersonId() {
    return this.userInfo && this.userInfo.personDetails ?this.userInfo.personDetails.personId:'';
  }
  public getIsOpenSSOLogin() {
    return this.userInfo && this.userInfo.isOpenSSOLogin ? this.userInfo.isOpenSSOLogin: false;
  }
  public getPersonMobile() {
    return this.userInfo && this.userInfo.personDetails ? this.userInfo.personDetails.mobile: '';
  }
  public getPersonName() {
    return this.userInfo && this.userInfo.personDetails ?this.userInfo.personDetails.personName:'';
  }
  public getPersonEmail() {
    return this.userInfo && this.userInfo.personDetails ?this.userInfo.personDetails.email:'';
  }
  public getPersonCountryCode() {
    return this.userInfo && this.userInfo.personDetails ?this.userInfo.personDetails.countryCode:'';
  }
  public getPersonNationalId() {
    return this.userInfo.personDetails.externalId;
  }

  public getUserDetail() {
    let userDetail = this.userInfo.personDetails
    return Observable.create(observer => {
      observer.next(userDetail);
    })
  }

  public getUserCredential(){
    let userDetail =
    {
      personDetails: this.userInfo.personDetails,
      credential: this.userCredential
    }
    return Observable.create(observer => {
      observer.next(userDetail);
    })
  }

  public getRepoUrl() {
    // const proxyurl = 'https://cors-anywhere.herokuapp.com/';
    // this.repoUrl = proxyurl+this.repoUrl
    return this.repoUrl;
  }

  public setRepoUrl(url) {
    this.repoUrl = url;
  }

  public setLinkedPatient(data) {
    this.userInfo.linkedPatients = data.linkedPatients;
    this.userSession.linkedPatients = data.linkedPatients;
    this.updateUserSession();
  }

  public setLinkedPatientFromUser(data) {
    this.userInfo.linkedPatients = data;
    this.userSession.linkedPatients = data;
    this.updateUserSession();
  }

  public updateUserSession() {
    this.setStorage(AppConstant.USER_OBJECT, JSON.stringify(this.userSession));
  }

  public findFacility(facilityId) {
    if(this.isPatientTask) return null;
    let facilities = this.getAttributeForListingType('facList', true)
    let fac = facilities.find(facility => {
      return facility.facilityId == facilityId;
    });
    return fac;
  }
  public findSpeciality(facilityId, specialityId) {
    if(this.isPatientTask) return null;
    if (facilityId) {
      let facilities = this.getAttributeForListingType('specialities', true)
      let fac = facilities.find(facility => {
        return facility.facilityId == facilityId;
      });
      if(!fac) return null;
      let spec = fac['specialities'].find(speciality => {
        return speciality.specialityId == specialityId;
      })
      return spec;
    } else {
      let specList = this.getSpecialities();
      let spec = specList.find(speciality => {
        return speciality.specialityId == specialityId;
      })
      return spec;
    }

  }
  public findPractitioner(facilityId, practitionerId) {
    if(this.isPatientTask) return null;
    if (facilityId) {
      let facilities = this.getAttributeForListingType('specialities', true)
      let fac = facilities.find(facility => {
        return facility.facilityId == facilityId;
      });
      if(!fac) return null;
      let prac = fac['practitioners'].find(practitioner => {
        return practitioner.resourceId == practitionerId;
      })
      return prac;
    } else {
      let pracList = this.getPractitioners();
      let prac = pracList.find(practitioner => {
        return practitioner.resourceId == practitionerId;
      })
      return prac;
    }

  }
  public findLinkedPatient(uhid) {
    return this.userInfo['linkedPatients'].find(patient => {
      return patient.uhid == uhid;
    })
  }

  public getSpecialityByPractId(practId, facId) {
    let facility = this.getAttributeForListingType('specialities', true).find(fac => fac.facilityId == facId);
    if (facility) {
      let practitioner = facility.practitioners.find(pract => pract.resourceId == practId)
      if (!practitioner)
        practitioner = this.getPractitioners().find(pract => pract.resourceId == practId)
      if (practitioner) {
        let specialityId = practitioner.specialityId
        let speciality = facility.specialities.find(spec => spec.specialityId == specialityId);
        console.log('speciality', speciality)
        if (speciality) {
          return speciality
        } else {
          let allSpecialities = this.getSpecialities();
          console.log('allSpecialities', allSpecialities)
          speciality = allSpecialities.find(spec => spec.specialityId == specialityId);
          if (speciality)
            return speciality
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  public getSpecialities(facilityId?: string, type?: visitType, hideExpressSpeciality?, hideSpecialitywithoutPract?) {
    // let facilities = this.updateInfo['facilityServices']['facilities'];
    let facilities = this.getAttributeForListingType('specialities', true)
    if (facilityId) {
      facilities = facilities.filter(facility => {
        return facility.facilityId == facilityId;
      })
    }
    let specialities = facilities.map(facilitity => {
      let fArray = facilitity.specialities
      return fArray.map(f => {
        return {
          ...f,
          facilityId: facilitity.facilityId
        }
      })
    })
    specialities = [].concat.apply([], specialities);
    specialities = specialities.filter((speciality, index, arr) => {
      return arr.findIndex(sp => {
        return speciality.specialityId == sp.specialityId;
      }) == index;
    });
    if(hideExpressSpeciality){
      specialities = specialities.filter(s=>s.specialityId!=='ECSP')
    }
    let combinedSpecialities = specialities.map(speciality => {
      if (type) {
        let practitioners = this.getPractitioners(speciality.specialityId, type, facilityId);
        speciality.pracCount = practitioners.length;
      }
      return speciality
    })
    if(hideSpecialitywithoutPract)
      combinedSpecialities = combinedSpecialities.filter(s=>s.pracCount>0);
    return combinedSpecialities;
  }
  public getPractitioners(specialityId?: string, type?: visitType, facilityId?: string, hideExpressSpeciality?) {
    // let facilities = this.updateInfo['facilityServices']['facilities'];
    let facilities = this.getAttributeForListingType('specialities', true)
    if (facilityId) {
      facilities = facilities.filter(facility => {
        return facility.facilityId == facilityId;
      })
    }
    let practitioners = facilities.map(facilitity => {
      let fArray = facilitity.practitioners
      return fArray.map(f => {
        return {
          ...f,
          tfacilityId: facilitity.facilityId
        }
      })
    })
    practitioners = [].concat.apply([], practitioners);
    practitioners = practitioners.reduce((rv, x) => {
      if (type && type == 'VIDEO' && (x['schedulerControls']['consultType'] == 'HOSPVIDEO' || x['schedulerControls']['consultType'] == 'VIDEO')) {
        (rv[x['resourceId']] = rv[x['resourceId']] || []).push(x);
      } else if (type && type != 'VIDEO' && (x['schedulerControls']['consultType'] == 'HOSPVIDEO' || x['schedulerControls']['consultType'] != 'VIDEO')) {
        (rv[x['resourceId']] = rv[x['resourceId']] || []).push(x);
      } else if (!type) {
        (rv[x['resourceId']] = rv[x['resourceId']] || []).push(x);
      }
      return rv;
    }, {});
    practitioners = Object.values(practitioners).map((prac: Array<any>) => {
      let tempArray = prac[0];
      tempArray['facList'] = [];
      prac.forEach(element => {
        let facility = this.findFacility(element.tfacilityId);
        tempArray['facList'].push(facility);
      });
      return tempArray;
    })
    if(hideExpressSpeciality){
      practitioners = practitioners.filter(s=>s.specialityId!=='ECSP')
    }
    if (specialityId) {
      return practitioners.filter(practitioner => {
        return practitioner.specialityId == specialityId;
      })
    } else {
      return practitioners;
    }
  }

  //API SERVICES

  public updateCheck(): Observable<any> {
    const contentHeaders = this.getRouterBasedUpdate();
    let payload = {
      appVersion: environment['APP_VERSION'],
      versions: contentHeaders
    }
    // return Observable.create(observer => {
    let observable: Observable<any>;
    if (this.cache) {
      observable = of(this.cache);
    } else if (this.cachedObservable) {
      observable = this.cachedObservable;
    } else {
      this.cachedObservable = this.initApiCall(payload).pipe(
        tap(res => this.cache = res),
        share(),
        finalize(() => {
          this.configLoaded = true;
          this.configLoadedSubject.next(true)
          this.cachedObservable = null
        })
      );
      observable = this.cachedObservable;
    }
    return observable;
    // })
  }
  private initApiCall(payload): Observable<any> {
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_CONTENT}/updateCheck`, payload, { reportProgress: true }).subscribe(success => {
        this.setUpdateInfo(success).subscribe(_ => {
          observer.next(success);
          observer.complete();
        });
      }, error => {
        this.setUpdateInfo(null).subscribe(_ => {
          observer.next(null);
          observer.complete();
        });
      })
    });
  }
  public getSlotList(partitionerId:string, facilityId:string, dates:Array<string>, visitType:visitType, clinicCode?): Observable<any> {
    let payload = {
      practitionerId: partitionerId,
      dates: dates
    }
    if (visitType) {
      payload['visitType'] = visitType;
    }
    if (facilityId) {
      payload['facilityId'] = facilityId;
    }
    if(clinicCode){
      payload['clinicCode'] = clinicCode
    }
    return Observable.create(observer => {
      this.http.post(`${this.repoUrl}api/schedules/getavailability`, payload).subscribe(success => {
        observer.next(success['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }
  public confirmAppointment(data:any): Observable<any> {
    let payload = {
      personId: this.userInfo.personDetails.personId,
      // patientPersonId: data.patientPersonId,
      // uhid: data.uhid,
      // facilityId: data.facilityId,
      // clinicCode: data.clinicCode,
      // date: data.date,
      // time: data.time,
      // personName: data.patientName,
      // dob: data.dob,
      // gender: data.gender,
      // practitionerName: data.practitionerName,
      // specialityName: data.specialityName,
      // patientName: data.patientName,
      // visitType: data.visitType,
      localeCode: this.currentLanguage,
      ...data
    }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_APPOINTMENT}/confirm`, payload, { reportProgress: true}).subscribe(success => {
        observer.next(success);
        this.npsRatingSubject.next(true)
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }
  public getUpcomingAppointments(): Observable<any> {
    let payload = {
      personId: this.userInfo.personDetails.personId,
    }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_APPOINTMENT}/getupcoming`, payload, { reportProgress: true}).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public getGeoLocation(): Observable<any>  {
    return Observable.create(observer => {
      if (this.geoLocation) {
        observer.next({ status: true, data: this.geoLocation });
      }
      else if (navigator.geolocation) {
        navigator.permissions.query({ name: 'geolocation' })
          .then(permission => {
            console.log('navigator response----',permission)
            if (permission.state != 'denied') {
              var options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
              }
              navigator.geolocation.getCurrentPosition((position: any) => {
                console.log('Navigator permission success',position)
                if (position) {
                  let lat = position.coords.latitude;
                  let lon = position.coords.longitude;
                  this.setGeoLocation({ lat: lat, lon: lon });
                  observer.next({ status: true, data: { lat: lat, lon: lon } });
                } else {
                  observer.next({ status: false, message: 'ERROR' });
                }
              },(err)=>{
                console.log('navigator permission catch----',err)
                observer.next({ status: false, message: 'ERROR' });
              },
              options)
            } else {
              observer.next({ status: false, message: 'DECLINED' });
            }
          })

      } else {
        observer.next({ status: false, message: 'NOOPTION' });
      }
    })
  }
  public setGeoLocation(val) {
    this.geoLocation = val;
  }
  public getCityDetails(isPatientSignup = false, standalone = false): Observable<any> {
    let payload = {
      locale: this.currentLanguage,
    }
    return Observable.create(observer => {
      if (!isPatientSignup && !standalone) {
        this.http.post(`${this.repoUrl}api/masters/GetCityDetails`, payload).subscribe(data => {
          observer.next(data);
        }, error => {
          observer.error(this.getErrorMessage(error));
        })
      } else if(isPatientSignup){
        this.http.post(`${environment['API_URL']}api/content/getCityCodes`, payload, { reportProgress: true }).subscribe((data) => {
          observer.next(data);
          observer.complete();
        }, error => {
          // this.toast.showToast(null, error['message'], TOAST_ERROR);
          observer.error(this.getErrorMessage(error));
        })
      } else if(standalone){
        this.http.post(`${environment['VISIT_API_URL']}api/patientKiosk/cityCodesMaster`, payload).subscribe((data) => {
          observer.next(data);
          observer.complete();
        }, error => {
          // this.toast.showToast(null, error['message'], TOAST_ERROR);
          observer.error(this.getErrorMessage(error));
        })
      }
    })
  }
  public getMasters(type: string, standalone = false): Observable<any> {
    let payload = {
      master: type,
      locale: this.currentLanguage,
    }
    let url = `${this.repoUrl}api/masters/getMasters`
    if (standalone) {
      url = `${environment['VISIT_API_URL']}api/patientKiosk/getMasters`
    }
    return Observable.create(observer => {
      this.http.post(url, payload).subscribe(data => {
        observer.next(data['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }
  public listPayerGroup(facilityId: string, standalone = false): Observable<any> {
    let payload = {
      facilityId: facilityId
    }
    let url = `${this.repoUrl}api/masters/listPayerGroup`
    if(standalone){
      url = `${environment['VISIT_API_URL']}api/patientKiosk/payerGroupMaster`
    }
    return Observable.create(observer => {
      this.http.post(url, payload).subscribe((data) => {
        observer.next(data['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    });
  }
  public getPayersList(facilityId: string, payerGroupCode: string, standalone = false): Observable<any> {
    let payload = {
      facilityId: facilityId,
      payerGroupCode: payerGroupCode
    }
    let url = `${this.repoUrl}api/masters/listPayer`;
    if (standalone) {
      url = `${environment['VISIT_API_URL']}api/patientKiosk/payerMaster`
    }
    return Observable.create(observer => {
      this.http.post(url, payload).subscribe((data) => {
        observer.next(data['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    });
  }
  public getPolicyList(facilityId: string, payerGroupCode: string, payerCode: string, standalone): Observable<any> {
    let payload = {
      facilityId: facilityId,
      payerGroupCode: payerGroupCode,
      payerCode: payerCode
    }
    let url = `${this.repoUrl}api/policy/getPolicyTypes`;
    if (standalone) {
      url = `${environment['VISIT_API_URL']}api/patientKiosk/policyTypeMaster`
    }
    return Observable.create(observer => {
      this.http.post(url, payload).subscribe((data) => {
        observer.next(data['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    });
  }
  public addLinkedPatient(data): Observable<any> {
    return new Observable(observer => {
      this.getExternalInterface().subscribe(extInterface => {
        if (extInterface) {
          const SSO_TOKEN = this.cookie.getStorage(AppConstant.ACCESS_TOKEN) ? this.cookie.getStorage(AppConstant.ACCESS_TOKEN) : ''
          this.getSSOUserObject().subscribe(userObj => {
            const payload = {
              token: SSO_TOKEN,
              mobile: this.getPersonMobile(),
              countryCode: this.getPersonCountryCode(),
              username: userObj.username
            }
            this.initAddLinkedPatient(data, payload).subscribe(response => {
              observer.next(response);
            }, err => {
              observer.error(err)
            })
          }, err => {
            this.initAddLinkedPatient(data, null).subscribe(response => {
              observer.next(response);
            }, err => {
              observer.error(err)
            })
          })
        } else {
          this.initAddLinkedPatient(data, null).subscribe(response => {
            observer.next(response);
          }, err => {
            observer.error(err)
          })
        }
      }, err => {
        this.initAddLinkedPatient(data, null).subscribe(response => {
          observer.next(response);
        }, err => {
          observer.error(err)
        })
      })
    })
  }
  public initAddLinkedPatient(data, externalInterfaceData): Observable<any> {
    let payload = {
      ...data,
      personId: this.userInfo.personDetails.personId,
      personName: this.userInfo.personDetails.personName,
      mobile: this.userInfo.personDetails.mobile,
      locale: this.currentLanguage,
      ssoDetails: externalInterfaceData
      // email: this.userInfo.personDetails.email,

    }
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}api/persons/registerNewPatient`, payload, { reportProgress: true }).subscribe((data) => {
        observer.next(data['data']);
        this.toast.showToast(null, data['message'] || 'Patient registered successfully', AppConstant.TOAST_INFO);
        this.syncPatientProfile()
        console.info('registerNewPatient', data);
      }, error => {
        observer.error(error);
      })
    });
  }

  public updateAfterPatientModification(repoToken, apiToken) {
    if (repoToken) {
      this.setStorage(REPO_TOKEN, repoToken['token']);
      this.setRepoUrl(repoToken['url']);
      this.interceptor.setRepoToken(repoToken['token'])
    }
    if(apiToken) {
      this.setStorage(USER_TOKEN, apiToken);
      this.interceptor.setUserToken(apiToken);
    }
  }

  public startRegTransaction(regParams, requestParams): Observable<any> {
    let payload = {
      ...regParams,
      personId: this.userInfo.personDetails.personId,
      request: {
        ...requestParams,
        personId: this.userInfo.personDetails.personId,
        personName: this.userInfo.personDetails.personName,
        mobile: this.userInfo.personDetails.mobile,
        email: this.userInfo.personDetails.email,
      }
    }
    return Observable.create(observer => {
      // this.http.post(`${environment['API_URL']}api/persons/registerNewPatient`, payload, { reportProgress: true }).subscribe((data) => {
      //   observer.next(data['data']);
      // }, error => {
      //   observer.error(this.getErrorMessage(error));
      // })
    });
  }
  public getVisitHistory(uhid: string): Observable<any>{
    let payload = {
      patientId: uhid
    }
    return Observable.create(observer => {
      this.http.post(`${this.repoUrl}api/visits/list`, payload).subscribe((data) => {
        observer.next(data['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    });
  }
  public getVisitReport(uhid: string, encounterid: string, type: string, reportId:string=null):Observable<any> {
    let payload = {
      patientId: uhid,
      visitId: encounterid,
      limit: 50,
      reportId: reportId
    };
    return Observable.create(observer => {
      this.http.post(`${this.repoUrl}api/visits/reports`, payload).subscribe((data) => {
        observer.next(data['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    });
  }

  public geReportById(uhid: string, reportId: string, facilityId: string):Observable<any> {
    let payload = {
      patientId: uhid,
      reportId: reportId,
      facilityId: facilityId
    };
    return Observable.create(observer => {
      this.http.post(`${this.repoUrl}api/visits/getReportById`, payload).subscribe((data) => {
        observer.next(data['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    });
  }
  public getUploadedReport(uhid: string, encounterid: string, type: string): Observable<any> {

    let payload = {
      patientId: uhid,
      source:'BOTH'
    };
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/persons/getreports`, payload, {reportProgress: true}).subscribe((data) => {
        observer.next(data['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    });
  }

  public removePersonalReport(docId: string):Observable<any> {
    let payload = {
      docId: docId
    };
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/persons/removereport`, payload, {reportProgress: true}).subscribe((data) => {
        observer.next(data['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    });
  }
  public convertToPDF(data):Observable<any> {
    let payload = {...data};
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/persons/convertToPDF`, payload, { reportProgress: true }).subscribe((data) => {
        observer.next(data['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    });
  }
  public getDownloadContent(url: string, token: string, isCommonView?:boolean): Observable<any> {
    let header = {
      'Authorization': token
    }
    if (isCommonView) {
      url = `${url}?preview=common`
    }
    let responseType:'json' = 'text' as 'json'
    return Observable.create(observer => {
      this.http.get<string>(url, { headers: new HttpHeaders(header), responseType: responseType }).subscribe((data) => {
        observer.next(data);
      }, error => {
        console.error(error)
        observer.error(this.getErrorMessage(error));
      })
    });
  }
  public openFacilityLocation(facilityId) {
    let fac = this.findFacility(facilityId);
    let location = `https://www.google.com/maps/search/?api=1&query=${fac['facilityGeoLocation']}`;
    this.openLocation(location, '_blank');
  }
  public getRegisterationParams(isPatientSignup, standalone): Observable<any> {
    let payload = {};
    return Observable.create(observer => {
      if (!isPatientSignup && !standalone) {
        this.http.post(`${environment['API_URL']}api/persons/getRegistrationParamsDetails`, payload, { reportProgress: true }).subscribe((data) => {
          observer.next(data['data']);
        }, error => {
          // this.toast.showToast(null, error['message'], TOAST_ERROR);
          observer.error(this.getErrorMessage(error));
        })
      } else if (isPatientSignup) {
        this.http.post(`${environment['API_URL']}api/security/getSignUpConfig`, payload, { reportProgress: true }).subscribe((data) => {
          const signupData = this.generateSignupData(data['data'])
          observer.next(signupData);
        }, error => {
          // this.toast.showToast(null, error['message'], TOAST_ERROR);
          observer.error(this.getErrorMessage(error));
        })
      } else if (standalone) {
        this.http.post(`${environment['VISIT_API_URL']}api/patientKiosk/getRegistrationParamsDetails`, payload, { reportProgress: false }).subscribe((data) => {
          const regData = data['data']['registrationParams'] //this.generateRegData()
          observer.next(regData);
        }, error => {
          // this.toast.showToast(null, error['message'], TOAST_ERROR);
          observer.error(this.getErrorMessage(error));
        })
      }
    });
  }
  public getPatientPolicy(payload): Observable<any> {
    payload.mobile = this.getPersonCountryCode() + this.getPersonMobile();
    return new Observable(observer => {
      this.http.post(`${this.getRepoUrl()}api/outpatients/getinsuranceDetails`, payload, { reportProgress: false }).subscribe((data) => {
        observer.next(data['data']);
      }, error => {
        observer.error(error);
      })
    });
  }

  public setPayerGrpSubject(){
    this.payerGrpUpdated.next(true);
  }

  public getPatientPolicyDetails(data): Observable<any> {
    let payload = data.map(d => {
      d.version = 'v1';
      return d;
    })
    return new Observable(observer => {
      this.http.post(`${this.getRepoUrl()}api/schedules/getPatientInsuranceDetails`, payload, { reportProgress: false }).subscribe(data => {
        observer.next(data['data']);
      }, error => {
        observer.error(error);
      })
    });
  }
  public getCountryList(isPatientSignup = false, standalone = false): Observable<any> {
    let payload = {
      locale: this.currentLanguage
    };

    return Observable.create(observer => {
      if (!isPatientSignup && !standalone) {
        if (this.countryList && this.countryList.length > 0) {
          observer.next(this.countryList);
          observer.complete();
          return;
        }
        this.http.post(`${this.repoUrl}api/masters/countrycodes`, payload).subscribe((data: any) => {
          this.countryList = data;
          observer.next(data);
          observer.complete();
        }, error => {
          observer.error(this.getErrorMessage(error));
        })
      } else if(isPatientSignup) {
        this.http.post(`${environment['API_URL']}api/content/getCountryCodes`, payload, { reportProgress: true }).subscribe((data) => {
          observer.next(data);
          observer.complete();
        }, error => {
          // this.toast.showToast(null, error['message'], TOAST_ERROR);
          observer.error(this.getErrorMessage(error));
        })
      } else if (standalone) {
        this.http.post(`${environment['VISIT_API_URL']}api/patientKiosk/countryCodesMaster`, payload, { reportProgress: false }).subscribe((data) => {
          observer.next(data);
          observer.complete();
        }, error => {
          // this.toast.showToast(null, error['message'], TOAST_ERROR);
          observer.error(this.getErrorMessage(error));
        })
      }
    });
  }
  public getBloodGroups(standalone): Observable<any> {
    let payload = {
      locale: this.currentLanguage
    };
    let url = `${this.repoUrl}api/masters/bloodgroups`
    if(standalone)
      url = `${environment['VISIT_API_URL']}api/patientKiosk/bloodGroupMaster`
    return Observable.create(observer => {
      this.http.post(url, payload).subscribe((data: any) => {
        observer.next(data);
        observer.complete();
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    });
  }

  public getSavedCountryList() {
    return this.countryList;
  }
  public getUserCatalogs() {
    return [];
  }
  public getGenFeedbackData(directiveGrp, conceptGrp): Observable<any> {
    let payload = {
      patientId: '',
      encounterId: '',
      directiveCode: directiveGrp,
      conceptCode: conceptGrp,
      templateType: 'FEEDBACK'
    };
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/content/getTemplate`, payload, { reportProgress: true }).subscribe((data) => {
        observer.next(data['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    });
  }
  public recordGeneralFeedback(payload): Observable<any> {
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/content/recordTemplate`, payload, { reportProgress: true }).subscribe((data) => {
        observer.next(data);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    });
  }
  public getUserProfile(): Observable<any> {
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_EXTERNAL_AUTH}/getUserProfile`, {}, { reportProgress: true }).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public getClinics(filter: object): Observable<any>{
    let payload = {
      filter: filter
    }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/persons/listClinics`, payload, { reportProgress: true }).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }


  public getUserIdForPatient(payload): Observable<any> {
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/persons/getAltIdForPatient`, payload, { reportProgress: true }).subscribe((data) => {
        observer.next(data['data']);
      }, error => {
        observer.error(error);
      })
    });
  }

  public getClinicInfo(facilityId,code,skillSet): Observable<any>{
    let payload = {
      facilityId: facilityId,
      clinicCode: code,
      skillSets: skillSet
    };
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/persons/getClinicInfo`, payload, { reportProgress: true }).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public getListSkillSetMasters(): Observable<any>{
    let payload = {};
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/persons/listSkillSetMasters`, payload, { reportProgress: true }).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public openSocialMedia(type) {
    if(type == 'fb'){
      let link = 'https://www.facebook.com/nahdihope';
      this.openLocation(link, "_blank");
    }
    if(type == 'yt'){
      let link = 'https://www.youtube.com/user/nahdihope';
      this.openLocation(link, "_blank");
    }
    if(type == 'insta'){
      let link = 'https://www.instagram.com/nahdihope';
      this.openLocation(link, "_blank");
    }
    if(type == 'linkedin'){
      let link = 'https://www.linkedin.com/company/al-nahdi-medical-co-';
      this.openLocation(link, "_blank");
    }
    if(type == 'twitter'){
      let link = 'https://twitter.com/nahdihope';
      this.openLocation(link, "_blank");
    }

  }

  public download(type){
    if(type == 'android'){
      let link = 'https://play.google.com/store/apps/details?id=com.nahdi.nahdipx';
      this.openLocation(link, "_blank");
    }
    if(type == 'apple'){
      let link = 'https://apps.apple.com/nz/app/nahdicare-clinics/id1462753220';
      this.openLocation(link, "_blank");
    }
  }

  public validatePatientTask(key: string): Observable<any> {
    let payload = {
      key: key,
    };
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/schedules/validatePatientTask`, payload, { reportProgress: true }).subscribe((data) => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    });
  }

  public validatePatientView(key: string, patientId?:string, otp?:string): Observable<any> {
    let payload = {
      key: key,
      patientId: patientId,
      otp:otp
    };
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/schedules/validatePatientView`, payload, { reportProgress: true }).subscribe((data) => {
        observer.next(data['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
        // observer.next({type: 'LOGIN'})
      })
    });
  }

  public validatePatientAppointment(key): Observable<any>{
    const payload = {
      key: key
    }
    // const payload = { "apptRefNo": "171783", "password": "8weJEmWB", "linkType": "P" }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/schedules/validatePatientAppointment`, payload, { reportProgress: true }).subscribe((data) => {
        observer.next(data['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
        // observer.next({type: 'LOGIN'})
      })
    });
  }
  public validatePatientBill(key): Observable<any>{
    const payload = {
      key: key
    }
    // const payload = { "apptRefNo": "171783", "password": "8weJEmWB", "linkType": "P" }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/schedules/validatePatientBill`, payload, { reportProgress: true }).subscribe((data) => {
        observer.next(data['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
        // observer.next({type: 'LOGIN'})
      })
    });
  }

  public getApplicableStatus(task) {
    if(!task || !task['applicableStatus']) return [];
    const status = task['applicableStatus'].find(status => status['code'] == task['currentStatus']);
    if(!status) return ;
    const seqNo = parseInt(status['seqNo']);
    return task['applicableStatus'].filter(status => (parseInt(status['seqNo']) >= seqNo) && status['code'] != task['currentStatus']);
  }
  public getCurrentStatus(task) {
    if(!task || !task['applicableStatus']) return [];
    const status = task['applicableStatus'].find(status => status['code'] == task['currentStatus']);
    return status;
  }

  public getConcept(taskDetail: Object, task: Object): any {
    let noteContent;
    if (taskDetail['taskGroup']['multiDisciplinaryControls'] && taskDetail['taskGroup']['multiDisciplinaryControls']['multiDisciplinaryDocWfyn'] === 'Y') {
      if (task['multiDisciplinaryNote'])
        noteContent = task['multiDisciplinaryNote'];
      else
        noteContent = task['noteContent'];
      noteContent['topics'].map(t => {
        let showControl = _find(task['multiDisciplinaryNoteControl'], { topicId: t['id'] });
        t['displayOption'] = showControl ? showControl['options'] : 'E';
      });
    } else {
      noteContent = task['noteContent'];
    }

    let concept = {
      label: task['taskDesc'],
      noteCode: task['noteTypeCode'] || task['noteCode'],
      id: task['noteTypeCode'] || task['noteCode'],
      noteContent: noteContent ? noteContent : task['noteContent'],
      noteControls: task['noteControls']
    }
    return concept;
  }

  public prefillWorkflowDrivers(concept: any, wfDrivers: Array<WFDriverElement>) {
    if (!Array.isArray(wfDrivers) || wfDrivers.length == 0) return concept;
    if (concept.noteContent && Array.isArray(concept.noteContent.topics)) {
      concept.noteContent.topics.map(t => {
        t.sections.map(s => {
          let wfDataForSection = wfDrivers.find(ds => ds.domainCode === s.domainCode && ds.domainName === s.domainName);
          if (wfDataForSection && wfDataForSection.domainValue) {
            if (s.type === 'masterLinked') {
              const splittedVal = wfDataForSection.domainValue.split('#');
              const selected = { name: splittedVal[1], code: splittedVal[0] };
              s['selectedMaster'] = selected;
              s['selectedValue'] = selected.name;
              s['selectedCode'] = selected.code;
            } else {
              s['selected'] = wfDataForSection.domainValue;
            }
          }
        });
      })
    }
    return concept;
  }

  public updateTaskInfo(payload): Observable<any> {
    payload['locale'] = this.currentLanguage;
    return Observable.create(observer => {
      this.http.post(environment['API_URL'] + 'api/workflow/updateTaskStatus', payload, { reportProgress: true }).subscribe(result => {
        let data = [];
        if (result && result['status'] === "SUCCESS" && Array.isArray(result['tasksList']))
          data = result['tasksList'];
        // result['taskList'].forEach(rt=> Array.isArray(rt['ret_task_info']) ? rt['ret_task_info'].forEach(t=> data.push(t)) : null)
        observer.next(data || true);
      }, error => {
        observer.error(false);
        console.error('Unable to retrive task details');
      });
    });
  }

  public getPayLoad(task, eventInfo, noteCode, noteContent, action, outcome, type, reportingOutcomes, taskAscNo, info, contractSeq?): any {
    console.log('action ', action);
    if (type !== 'save' && !action) return;
    if(noteContent && Array.isArray(noteContent.topics) && noteContent.topics.some(t=> Array.isArray(t.sections) && t.sections.some(s=>s.type==='richTextEditor' && s.templateVaraibleValues))){
      let value = {};
      if(contractSeq)
        value['SYS_CONTRACT_S_NO'] = contractSeq;
      noteContent.topics.forEach(t=>{
        t.sections.forEach(s=>{
          if(s.type==='richTextEditor' && s.templateVaraibleValues){
            Object.assign(value, s.templateVaraibleValues);
            delete s.templateVaraibleValues
          }else if(s.domainCode && s.selected && s.type!=='richTextEditor'){
            value[s.domainCode] = s.selected;
          }
        })
      });
      noteContent['templateVaraibleValues'] = value;
    }

    var payload = {
      "header": eventInfo,
      "taskUpdates": [
        {
          "entityId": info['entityId'],
          "facilityId": task['facilityId'],
          "patientId": task['patientId'] || eventInfo['patientId'],
          "taskId": taskAscNo,
          "taskSeqNo": task['taskSeqNo'],
          "currentStatus": task['currentStatus'],
          "newStatus": action,
          "updatedBy": info['personId'],
          "updatedDateTime": this.getMoment().format(),
          "linkedNoteId": noteCode,
          "linkedNoteContent": noteContent,
          "outcomes": outcome,
          "reportingFields": reportingOutcomes,
          "taskdtlascnno": task['taskdetailacsnno'],
          "encounterSeq": task['encSeq'],
          "taskStartDttm": task['_taskStartTime'],
          "actionType": task['_actionType'],
          "externalEventType": task['externalEventType'] || null
        }
      ],
      "printLabels": false
    }
    if (type == 'submit')
      payload.taskUpdates[0]['newStatus'] = action;
    else if (type == 'save') {
      payload.taskUpdates[0]['newStatus'] = task['currentStatus'];
      payload.taskUpdates[0]['actionType'] = 'DRAFT';
    }


    return payload;
  }

  public startSurvey(key: string): Observable<any> {
    let payload = {
      key: key,
    };
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/security/startsurvey`, payload, { reportProgress: true }).subscribe((data) => {
        observer.next(data['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    });
  }

  public authSurvey(payload): Observable<any> {
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/security/authsurvey`, payload, { reportProgress: true }).subscribe((data) => {
        observer.next(data);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    });
  }

  // set info from validatePatientTask Api
  public setUserInfoForTask(userInfo, credentials, repo) {
    this.userInfo = {
      personDetails: userInfo,
      credentials: credentials,
      repoDetails: repo,
    }
  }

  public setPatientTask(status:boolean) {
    this.isPatientTask = status;
  }
  public getPatientTask() {
    return this.isPatientTask;
  }
  public getPersonInfo(){
    return this.userInfo;
  }

  public setLocaleLabels(labels) {
    this.localeLabels = labels;
  }
  public getLocaleLabels() {
    return this.localeLabels;
  }
  // public setGTMTagConfig({ enabled, key }) {
  //   this.isTagsEnabled = enabled;
  //   this.GTMContainerId = key;
  // }
  // public getGTMContainerId(): string {
  //   // console.log(this.isTagsEnabled, this.GTMContainerId);
  //   return this.isTagsEnabled ? this.GTMContainerId : 'GTM';
  // }
  // public isGTMEnabled(): boolean {
  //   return ;
  // }

  public setTruncateInfo(){
    this.getConfigurationInfo.subscribe(data => {
      if (data['integrationSettings']) {
        this.roundOrTruncate = data['integrationSettings']['roundOrTruncate'];
        this.roundOfDecimals = data['integrationSettings']['roundOfDecimals'];
      }
    })
  }

  public fixDecimalPlaces(actvalue: number): number {
    if (!actvalue) { return Number(actvalue); }
    else {
        let value = actvalue.toString();
        if (this.roundOrTruncate == 'T') {
            let strValue = parseFloat(value).toFixed(Number(this.roundOfDecimals) + 1).slice(0, -1);
            return Number(strValue);
        } else {
            let strValue = (Math.round(parseFloat(value) * 100) / 100).toFixed(2);
            return Number(strValue)
        }
    }
  }

  public getPatientAddress(): Observable<any> {
    let payload = {
      personId: this.userInfo.personDetails.personId,
    }
    return Observable.create(observer => {
      this.http.post(`${this.repoUrl}api/mpi/getPatientAddress`, payload).subscribe(response => {
        if (response["status"] === "SUCCESS")
          observer.next(response['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }
  public updateAddress(patientId, patientAddress, linkAddress): Observable<any> {
    let payload = {
      personId: this.userInfo.personDetails.personId,
      patientId: patientId,
      patientAddress: patientAddress,
      linkAddress: linkAddress
    }
    return Observable.create(observer => {
      this.http.post(`${this.repoUrl}api/mpi/updatePatientAddresses`, payload).subscribe(response => {
        if (response["status"] === "SUCCESS")
          observer.next(response['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }
  public setLoginContext(context, restrict?) {
    if(restrict)
      this.featureFlagsService.restrictViewsOnPermission(context['type'])
    if (context['type'] == LOGIN_CONTEXT['TASK']) {
      this.setPatientTask(true)
    }
    sessionStorage.setItem(CTX, JSON.stringify(context));
    this.loginContext = context;
  }
  public getLoginContext() {
    let context = this.loginContext;
    if(!context) {
      context = JSON.parse(sessionStorage.getItem(CTX));
      if(!context) {
        const queryParams = this.route.queryParams['_value'];
        this.router.navigate([`${AppConstant.ROUTE_LOGIN}`], { queryParams: queryParams});
      }else {
        this.featureFlagsService.restrictViewsOnPermission(context['type'])
        this.loginContext = context;
      }
    }
    return context;
  }
  public removeLoginContext() {
    this.loginContext = null;
  }

  public getContractSeq(task, patientInfo): Observable<any> {
    let payload = {
      facilityId: task['facilityId'],
      patientId:patientInfo['patientId']
    };
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}api/lab/patContractSeq`, payload, { reportProgress: true }).subscribe((data) => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    });
  }

  private presentAppPromotion(updateInfo) {
    // updateInfo.configuration.pxAppSettings.showAppPromo = true;

    this.route.queryParamMap.subscribe(params => {
      let action = params.get('action');
      if (this.router.url.includes(AppConstant.ROUTE_WEBVIEW) ||  this.router.url.includes(AppConstant.ROUTE_EMBED_APPT)) {
        action = 'webview'
      }
      if (updateInfo && updateInfo.configuration && updateInfo.configuration.pxAppSettings && updateInfo.configuration.pxAppSettings.showAppPromo) {
        if (!this.cookie.getCookie('APPPROMOCLOSED') || (updateInfo.configuration.pxAppSettings.repeatAppPromo && sessionStorage.getItem('APPPROMOCLOSED') !== 'true')) {
          this.appPromoSubject.next({ show: true, type: 'APPPROMO', expireDays: updateInfo.configuration.pxAppSettings.closeExpireDays, action: action, repeatAppPromo: updateInfo.configuration.pxAppSettings.repeatAppPromo })
        }
      }
    })
  }

  public getPatientTaskDetails(context): Observable<any>{
    return new Observable(observer => {
      let payload = {
        key: context.taskKey,
        taskId: context.taskId,
        patientId: context.patientId,

      };
      this.http.post(`${environment['API_URL']}api/schedules/getPatientTaskDetails`, payload, { reportProgress: true }).subscribe((data) => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }
  public getPatientTaskLists(patientInfo): Observable<any>{
    return new Observable(observer => {
      let payload = {};
      if (patientInfo) {
        payload['patientIds'] = [patientInfo.patientId]
      }
      this.http.post(`${environment['API_URL']}api/activity/getPatientTasks`, payload, { reportProgress: true }).subscribe((data) => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }
  public getPatientTaskClosed(patientInfo): Observable<any>{
    return new Observable(observer => {
      let payload = {
        page: 0,
        patientId: patientInfo.patientId
      };
      if (patientInfo) {
        payload['patientIds'] = [patientInfo.patientId]
      }
      this.http.post(`${environment['API_URL']}api/activity/getPatientClosedTasks`, payload, { reportProgress: true }).subscribe((data) => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }
  public generateOtpForPatientId(patientId): Observable<any>{
    let payload = {
      patientId: patientId
    }
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}api/schedules/generateOTPforPatientId`, payload, { reportProgress: true }).subscribe((data) => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public sectionFocusEmitter(value){
    this.sectionFocusListener.next(value)
  }

  public closeWindow() {
    var win = this.openLocation("about:blank", "_self");
    win.close();
  }

  public setTaskViewSettings(settings) {
    if (settings) {
      this.taskViewSettings = settings;
    } else {
      let context = JSON.parse(sessionStorage.getItem(CTX));
      if (context && context.taskSettings) {
        this.taskViewSettings = context.taskSettings;
      } else {
        this.taskViewSettings = {
          scrollTime: 10,
          minimizeFulfilledSection: true
        }
      }
    }
  }

  public getTaskViewSettings() {
    return this.taskViewSettings
  }

  public setDefaultLanguage(languageList, selectedLangCode) {
    let lang = languageList.find(item => {
      return item.localeCode == selectedLangCode
    })
    console.log("LOCALEEE====>",selectedLangCode,lang)
    this.cookie.setRemaindMe(true);
    if(lang) {
      if(selectedLangCode !== this.currentLanguage) {
        this.setCurrentLanguage(lang);
        this.setDirection(lang);
      }
    }
  }

  public getMoment(val?, format?, strict?, timezone?) {
    let value = moment().locale('en')
    if (format) {
      value = moment(val, format, strict || false).locale('en');
    } else if(val) {
      value = moment(val).locale('en');
    }
    if (timezone) {
      value = value.tz(timezone, true);
    }
    return value;
  }
  public getRegistrationPayload() {
    return this.patientRegistrationPayload.payload;
  }
  public setRegistrationPayload(val) {
    this.patientRegistrationPayload = null;
  }

  private generateSignupData(payload) {
    return {
      patientName: {
        langArray: [{ langName: "English", code: "en", rtl: false, nameList: [{ title: "First name", code: "fName", desc: "Enter first name * ", mandatory: true, maxLength: 30, errMsg: "Please enter first name", regex: "[^a-zA-Z ]" }, { title: "Father Name", code: "mName", desc: "Enter Father Name  * ", mandatory: true, maxLength: 30, errMsg: "Please enter Father Name", regex: "[^a-zA-Z ]" }, { title: "Last name", code: "lName", desc: "Enter last name * ", mandatory: true, maxLength: 30, errMsg: "Please enter second name", regex: "[^a-zA-Z ]" }] }]
      },
      relationship: { hide: true },
      facility: {
        mandatory: false, hide: true
      }, gender: {
        mandatory: true
      }, dob: {
        mandatory: true, min: 1, max: 110, controls: { acceptCalendarType: "HIJRI" }
      }, mobile: {
        mandatory: true, maxLength: 10
      }, payergroup: {
        mandatory: true
      }, payer: {
        mandatory: false
      }, policyname: {
        mandatory: false
      }, email: {
        mandatory: true
      }, policyNumber: {
        mandatory: false
      }, membershipId: {
        mandatory: false
      },
      policyStartDate: { mandatory: false },
      policyEndDate: { mandatory: false },
      citizen: { mandatory: true, acceptVisitor: true },
      address: { hide: true, mandatory: false },
      address2: { hide: true },
      pinCode: { mandatory: false, hide: true },
      city: { mandatory: true },
      state: { mandatory: false },
      country: { mandatory: true },
      careOf: { mandatory: false, hide: true },
      employment: { mandatory: false },
      region: { mandatory: true, dependent: "city" },
      altIdView: { required: true, mandatoryAltIdCodes: { citizen: ["NATID"], nonCitizen: ["ALT1"] } },
      financialDetails: { required: true, allowMultiple: false },
      ...payload
    }
  }

  public setAppType(type: APPTYPE) {
    this.appType = type
    this.featureFlagsService.setAppType(type);
    this.cookie.set('APPTYPE', type);
  }

  public getAppType() {
    return this.appType;
  }

  public getPortalBasePath() {
    if (this.appType != 'webview') {
      return AppConstant.ROUTE_CRM;
    } else {
      return AppConstant.ROUTE_CRM;
    }
  }

  public openLocation(url, self?) {
    let tab = this.appType != 'webview' && !self ? '_blank' : '_self';
    let win;
    if (this.platform.IOS) {
     win = window['location'].href = url;
    } else {
      win = window.open(url, tab)
    }
    return win;
  }

  public getAutoSignon(): Observable<any> {
    return new Observable(observer => {
      try {
        if (document.getElementsByTagName('base')[0].href.indexOf("localhost:4") >= 0) {
          observer.next(false)
          observer.complete();
          return;
        }
      } catch (e) {

      }
      this.getUpdateInfo('configuration').subscribe(data => {
        if (data['features'].externalInterface && data['features'].autoSignOn && !environment['DISABLESSOSIGNIN']) {
          observer.next(true)
          observer.complete();
        } else {
          observer.error()
        }
      }, err => {
        observer.error()
      });
    })
  }
  public getPasswordPolicy() {
    return this.passwordPolicy
  }

  public checkPassword(condition): ValidatorFn {
    return (control: AbstractControl): any => {
      let string = control.value;
      let regex = {
        uppercase: /[A-Z]/g,
        lowercase: /[a-z]/g,
        number: /[0-9]/g,
        special: /[^A-Za-z0-9]/g,
      }
      let check = Object.keys(condition).map(key => {
        let cond = condition[key];
        let check = true;
        switch (key) {
          case 'length':
            check = string.length >= (cond['min'] || 0) && (!cond['max'] || string.length <= cond['max'])
            break;
          case 'uppercase':
          case 'lowercase':
          case 'number':
            let checkLength = cond['min'] || 1;
            check = !cond['enable'] || (regex[key].test(string) && string.match(regex[key]).length >= checkLength)
            break;
          case 'special':
            let checkLen = cond['min'] || 1;
            let spRegex = regex[key];
            if (cond['allowed'])
              check = !cond['enable'] || (regex[key].test(string) && string.match(regex[key]).length >= checkLen)
            break;
          case 'notin':
            check = !cond.values.includes(string);
            break;
          case 'norepeat':
            if (!cond['enable'] || cond['length'] <= 1) {
              check = true
            } else {
              let repeat = ''
              if (cond['length']) {
                repeat = new Array(cond[1] - 1).fill("\\1").join('');
              }
              if (repeat == '') {
                check = true;
              } else {
                let exp = new RegExp(`(.)${repeat}+`, 'g');
                check = !exp.test(string);
              }
            }
            break;
          default:
            check = true;
            break;
        }
        return { key, check };
      }).reduce((r, a) => {
        r[a['key']] = a['check'];
        return r;
      }, {})
      if (Object.keys(check).every(key => check[key])) return null
      return check;
    };
  }

  public getCurrencyForPayment() {
    return this.updateInfo['configuration']['currency'];
  }


  private getRouterBasedUpdate() {
    let contentHeaders = environment['RETRIEVE_LOCALSTORAGE'] ? JSON.parse(localStorage.getItem(AppConstant.CONTENT_HEADER)) : null;
    if (!contentHeaders) {
      contentHeaders = [{
        type: "configuration",
        version: "0"
      }, {
        type: "masters",
        version: "0"
      }, {
        type: "facilities",
        version: "0"
      }, {
        type: "facilityServices",
        version: "0"
      }, {
        type: "facilityProfiles",
        version: "0"
      }, {
        type: "practitionerProfiles",
        version: "0"
      }, {
        type: "directives",
        version: "0"
      }, {
        type: "labels",
        version: "0"
      }]
      if (this.router.url.includes(AppConstant.ROUTE_TASK) || this.router.url.includes(AppConstant.ROUTE_VACCINE) || this.router.url.includes(AppConstant.ROUTE_VIEW)) {
          contentHeaders = [{
            type: "configuration",
            version: "0"
          }, {
            type: "labels",
            version: "0"
          }, {
            type: "masters",
            version: "0"
          }]
      }
      if (this.router.url.includes(AppConstant.ROUTE_VISIT)) {
        contentHeaders = [{
          type: "configuration",
          version: "0"
        }, {
          type: "labels",
          version: "0"
        }, {
          type: "masters",
          version: "0"
        }, {
          type: "facilities",
          version: "0"
        }, {
          type: "facilityServices",
          version: "0"
        }]
      }
    }
    return contentHeaders
  }

  public getConfigurationInfo:Observable<any> = new Observable(observer =>{
    if (this.configLoaded) {
      observer.next(this.updateInfo['configuration'])
      observer.complete()
    } else {
      this.configLoadedSubject.subscribe(_ => {
        observer.next(this.updateInfo['configuration'])
        observer.complete()
      })
    }
  })

  public getMasterConfig: Observable<any> = new Observable(observer => {
    if (this.configLoaded) {
      observer.next(this.updateInfo['masters'])
      observer.complete()
    } else {
      this.configLoadedSubject.subscribe(_ => {
        observer.next(this.updateInfo['masters'])
        observer.complete()
      })
    }
  })

  getVisitRepoUrl(fid) {

    let url = environment['VISIT_API_URLS']?.[fid];
    if (!url)
      url = environment['VISIT_API_URL'];
    return url;
  }

  public syncPatientProfile() {
    this.getUserProfile().subscribe(data => {
      this.setSSOObjectOnUpdate(data);
      console.log('syncPatientProfile')
      this.userUpdateSubject.next(true)
    })

  }

  public fetchMessages(channelId, offset, apptRefNo, showTranscript: boolean = false) {
    const payload = {
      channelId: channelId,
      offset: offset,
      apptRefNo: apptRefNo,
      showTranscript: showTranscript
    };
    return new Observable(observer => {
      this.http.post(environment['API_URL'] + 'api/persons/getPatientAndPractitionerMessages', payload, { reportProgress: true }).subscribe(response => {
        if (response['status'] == 'SUCCESS')
          return observer.next(response);
        return observer.error();
      }, _ => observer.error());
    })
  }

  public pincheck(pincode, standalone?): Observable<any> {
    let payload = {
      pincode,
      locale: this.currentLanguage
    };
    let url = `${this.repoUrl}api/masters/pincheck`
    if (standalone)
      url = `${environment['VISIT_API_URL']}api/patientKiosk/pincheck`
    return Observable.create(observer => {
      this.http.post(url, payload).subscribe((data: any) => {
        observer.next(data);
        observer.complete();
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    });
  }

  public getResourceListingType(forceResourceListing:boolean = false): 'API' | 'NORMAL'{
    if (this.pxAppSettings && this.pxAppSettings.resourceListing && this.pxAppSettings.resourceListing == 'API' && (forceResourceListing)) {
      return 'API';
    } else {
      return 'NORMAL';
      // return 'API';
    }
  }

  public getPatientFacilities(publish: boolean = true) {
    this.http.get(`${environment['API_URL']}${AppConstant.API_PERSONS}/facilitiesforpatient`, {reportProgress: true}).subscribe(data => {
      this.setPatientFacilities(data['data'], publish);
    }, error => {
      this.getErrorMessage(error);
    })
  }

  private getResourceList(): Observable<any> {
    let mobileNum = this.getPersonMobile();
    let payload = {
      facilityId: this.facilityMaster.facilityArray,
      mobileNum: mobileNum
    }
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_CONTENT}/practitionersforfacility`,payload, { reportProgress: true }).subscribe(data => {
        observer.next(data['data']);
      }, error => {
        this.getErrorMessage(error);
      })
    })
  }

  private setPatientFacilities(data, publish) {
    let groupFacility = _.values(_.groupBy(data, 'facilityId'));
    let updatedFacility = [];
    let facilityProfile = [];
    groupFacility.map((f: any) => {
      let sort = f.sort((a: any, b: any) => new Date(b['lastVisitDate']).getTime() - new Date(a['lastVisitDate']).getTime());
      if (sort) {
        updatedFacility.push(sort[0]);
        facilityProfile.push({
          facilityId: sort[0]['facilityId'],
          profile: sort[0]['profiles']
        })
      }
    })
    this.facilityMaster = {
      actualData: data,
      facilityList: updatedFacility,
      facilityProfile: facilityProfile,
      facilityArray: updatedFacility.map(f => f.facilityId)
    }
    // console.log("facilityMaster", this.facilityMaster)
    this.getResourceList().subscribe(resourceData => {
      this.resourceMaster = resourceData;
      this.resourceMaster['facilityServices'] = resourceData['facilities'].map(fac => {
        fac.specialities = fac.specialities.map(spec => {
          let pracList = fac.practitioners.filter(prac => {
            return prac.specialityId === spec.specialityId;
          });
          let videoPrac = fac.practitioners.filter(prac => {
            return prac.specialityId == spec.specialityId && (prac.schedulerControls['consultType'] == 'HOSPVIDEO' || prac.schedulerControls['consultType'] == 'VIDEO');
          })
          let hospPrac = fac.practitioners.filter(prac => {
            return prac.specialityId == spec.specialityId && (prac.schedulerControls['consultType'] == 'HOSPVIDEO' || prac.schedulerControls['consultType'] != 'VIDEO');
          })
          return { ...spec, pracList: pracList, pracCount: pracList.length, VIDEO: videoPrac, VIDEOCOUNT: videoPrac.length, HOSPITAL: hospPrac, HOSPITALCOUNT: hospPrac.length }
        })
        return fac;
      });
      this.resourceMaster['facilities'] = this.facilityMaster['facilityList']

      this.featureFlagsService.initFacility({facilities: this.resourceMaster['facilities']})
      // console.log("===== resourceMaster['facilities'] ======", this.resourceMaster)
    })
  }

  public getAttributeForListingType(type: 'facilities' | 'facilityServices' | 'specialities' | 'facList', forceResourceListing:boolean = false) {
    switch (type) {
      case 'facilities':
        if (this.getResourceListingType(forceResourceListing) == 'API') {
          return this.resourceMaster;
        } else {
          return this.updateInfo['facilities'];
          // return this.updateInfo['facilityServices'];
        }
        break;
      case 'facilityServices':
        if (this.getResourceListingType(forceResourceListing) == 'API') {
          return { facilities: this.resourceMaster['facilityServices']};
        } else {
          return this.updateInfo['facilityServices'];
        }
        break;
      case 'specialities':
        if (this.getResourceListingType(forceResourceListing) == 'API') {
          return this.resourceMaster['facilityServices'];
        } else {
          return this.updateInfo['facilityServices']['facilities'];
        }
        break;
      case 'facList':
        if (this.getResourceListingType(forceResourceListing) == 'API') {
          return this.resourceMaster['facilities'];
        } else {
          return this.updateInfo['facilities']['facilities'];
        }
        break;
    }
  }
  public getPatientReportHistory(patientId, eventCode) {
    const payload = {
      patientId: patientId,
      eventCode: eventCode
    }
    return new Observable(observer => {

      this.http.post(`${this.repoUrl}api/visits/getReportComponentHistory`, payload).subscribe(response => {
        if (response['status'] == 'SUCCESS')
          return observer.next(response['data']);
        return observer.error();
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public updatePxAppPermissions(permission) {
    if (permission) {
      this.pxAppSettings = permission
      this.combinedAppPermissions['BOOKTHROUGHDISCOVERY'] = permission.appointmentWithDiscovery;
      this.combinedAppPermissions['PATIENTSFFROMPERSON'] = permission.groupByPersonId;
      this.combinedAppPermissions['GROUPPATIENTSBYFACILITY'] = permission.groupPatientsByFacility;
      this.combinedAppPermissions['FIRSTTIMEINVITE'] = permission.firstTimeInviteView;
      this.combinedAppPermissions['DISABLEFACILITYCONTEXTFORREG'] = permission.disableFacilityContextForReg;
      this.combinedAppPermissions['ENABLECAREPLAN'] = permission.enableCarePlan;
      this.combinedAppPermissions['FILESELECTIONWIDTH'] = permission.fileUploadWidth;
      this.combinedAppPermissions['FILESELECTIONHEIGHT'] = permission.fileUploadHeight;
      this.combinedAppPermissions['DISABLENUMBERCONVERSION'] = permission.disableNumberConversion;
      this.combinedAppPermissions['PASSBASEDLOGON'] = permission.enablePasswordBasedLogon;
      this.combinedAppPermissions['SKIPOTPVALIDATION'] = permission.skipOTPValidation;
      this.combinedAppPermissions['MINPINLENGTH'] = permission.minPinLength;
      this.combinedAppPermissions['MAXPINLENGTH'] = permission.maxPinLength;
      this.combinedAppPermissions['RESRTRICTPRACTITIONERSHARING'] = permission.restrictPractitionerForSharingDocs;
      this.combinedAppPermissions['ECMAXSERVICEDURATION'] = permission.expressClinicMaxApptDur;
      this.combinedAppPermissions['GROUPFACILITYFORVC'] = true;
      this.combinedAppPermissions['HIDEDRPROFILE'] = permission.hideDoctorProfile;
      this.combinedAppPermissions['VISITFEEDBACK']= permission.feedbackByVisitsYn;
      // this.combinedAppPermissions['HIDEPACKAGEFILTER'] = environment.HIDEPACKAGEFILTER;
      this.combinedAppPermissions['SHOWHOMESERVICEWITHLOC'] = permission.show_home_services;
      this.combinedAppPermissions['ENABLEHOMECAREWITHOUTLOC'] = permission.show_home_services_artimes;
      this.combinedAppPermissions['SHOWMYREQUESTS'] = !permission.hideServiceRequest;
      this.combinedAppPermissions['ENABLEEXPRESSCLINIC'] = permission.enableExpressClinicYn == 'Y';
      this.combinedAppPermissions['ENABLEONSPOTCONSULT'] = permission.hasOnspotConsultation;
      this.combinedAppPermissions['CANVIEWAPPONBIOMETRICFAILURE'] = permission.preventCloseAppOnBiometricFailure;
      this.combinedAppPermissions['CANVERIFYTHROUBHBIOMETRIC'] = permission.enableBiometricAuth;
      this.combinedAppPermissions['ENABLEONSPOTCONSULT'] = permission.onSpotConsultationYn == 'Y';
      this.combinedAppPermissions['ENABLEAPPTOUR'] = permission.enableAppTour;
      this.combinedAppPermissions['ENABLEONLINECONSULT'] = permission.enableOnlineConsultation == 'Y';
      this.combinedAppPermissions['ENABLEPACKAGES'] = permission.enablePackages == 'Y';
      this.combinedAppPermissions['ENABLEEMERGENCY'] = permission.enableEmergency == 'Y' ;
      this.combinedAppPermissions['ENABLEPHONECONSULT'] = permission.enablePhoneConsult == 'Y';
      this.combinedAppPermissions['ENABLEPAYONRECENTBILL'] = permission.enablePayRecentBill == 'Y';
      this.combinedAppPermissions['ENABLEFEEDBACKONHOME'] = permission.hideFeedbackOnHome !=true;
      this.combinedAppPermissions['ENBALEPRINTINPRESCRIPTION'] = permission.enablePrintPrescriptionYn =='Y';
      this.combinedAppPermissions['ENBALEMYALERTS'] = permission.disableAlertsYn !='Y';
      this.combinedAppPermissions['DISABLERECENTLYCONSULTED'] = permission.disablerecentlyconsulted;
      this.combinedAppPermissions['DISPLAYPRICEINFO'] =  permission.displayPriceInfo != 'N';
      this.combinedAppPermissions['ENABLESUMMARYINVIEW'] = !permission.hideHrSummary;
      this.combinedAppPermissions['IGNORESUMMARYPAGE'] = permission.ignoreHrSummaryPage;
      this.combinedAppPermissions['ENABLEAPPTREQUEST'] = permission.enableApptRequest == 'Y';
      this.combinedAppPermissions['SHOWNUHDEEKPOINTS'] = permission.showNuhdeekPointsOnAccount == 'Y';
      this.combinedAppPermissions['MINIMUMEXCHANGEELIGIBLENUHDEEKPOINTS'] = permission.minimumExchangeEligibleNuhdeekPoints
      this.combinedAppPermissions['EXCHANGECOUPONSLINK'] = permission.exchangeCouponsLink

      this.combinedAppPermissions['ENABLEUPCOMING'] = permission.enableUpcoming == 'Y';
      this.combinedAppPermissions['ENABLECANCELLED'] = permission.enableCancelApptList == 'Y';
      this.combinedAppPermissions['ENABLECOMPLETED'] = permission.enableCompletedApptList == 'Y';
      this.combinedAppPermissions['ENABLEINPROCESS'] = permission.enableInProgressApptList == 'Y';

      this.combinedAppPermissions['QUICKVIEWFORCLINIC'] = permission.enableQuickViewForClinic == 'Y';
      this.combinedAppPermissions['QUICKVIEWFORVC']= permission.enableQuickViewForVC == 'Y';
      this.combinedAppPermissions['ENABLEPAYONCONSULTFORINS'] = permission.enablePayOnConsultForInsurance =='Y';
      this.combinedAppPermissions['SHOWAPPLEPAY'] = !permission.hideApplePay;
      this.combinedAppPermissions['HIDETELECONSULTATIONTC']= permission.hideTeleConsultationTC == 'Y';
      this.combinedAppPermissions['WHATSAPPLINK'] = permission.whatsAppLink;
      this.combinedAppPermissions['ENABLEREQUESTCOMPLAINT'] = permission.enableRequestComplaintUI == 'Y';
      this.combinedAppPermissions['HIDESERVICEREQUEST'] = permission.hideServiceRequest == 'Y';
      this.combinedAppPermissions['ENABLELINKPATIENT']= permission.enableLinkPatient == 'Y';
      //Health records in patient view
      this.combinedAppPermissions['ENABLESUMMARYINVIEW'] = permission.hideSummary != 'Y';
      this.combinedAppPermissions['ENABLEVISITSINVIEW'] = permission.hideVisits != 'Y';
      this.combinedAppPermissions['ENABLEREPORTSINVIEW'] = permission.hideReports != 'Y';
      this.combinedAppPermissions['ENABLEPRESCRIPTIONINVIEW'] = permission.hidePrescription != 'Y';
      this.combinedAppPermissions['ENABLEBILLSINVIEW'] = permission.hideBills != 'Y';
      this.combinedAppPermissions['ENABLEPARAMETERINVIEW'] = permission.enableParameter == 'Y';
      this.combinedAppPermissions['ENABLETASKSINVIEW'] = permission.enableTask == 'Y';
      //Health records in detail view
      this.combinedAppPermissions['ENABLESUMMARYINDTLS'] = permission.hideSummary != 'Y';
      this.combinedAppPermissions['ENABLEVISITSINDTLS'] = permission.hideVisits != 'Y';
      this.combinedAppPermissions['ENABLEREPORTSINDTLS'] = permission.hideReports != 'Y';
      this.combinedAppPermissions['ENABLEPRESCRIPTIONINDTLS'] = permission.hidePrescription != 'Y';
      this.combinedAppPermissions['ENABLEBILLINDTLS'] = permission.hideBills != 'Y';
      this.combinedAppPermissions['ENABLEPARAMETERINDTLS'] = permission.enableParameter == 'Y';
      this.combinedAppPermissions['ENABLEPATIENTCALANDARINDTLS'] = permission.enableTask == 'Y';

      this.combinedAppPermissions['ENABLEQUEUENO'] = permission.enableQueueNoInAppt =='Y';
      this.combinedAppPermissions['ENABLEPROMO'] = permission.enablePromoYn == 'Y';
      this.combinedAppPermissions['ENABLECOUPON'] = permission.enableCouponYn =='Y';
      this.combinedAppPermissions['SHOWAVAILABLEPROMO'] = permission.showAvailPromoYn =='Y';
      this.combinedAppPermissions['ORDERPRESCRIPTION'] = permission.orderFromPharmacy =='Y';
      this.combinedAppPermissions['ENABLEDELETEACCFORIOS'] = permission.enableDeleteAccountForIOS == 'Y';
      this.combinedAppPermissions['ENABLEDELETEACCFORANDROID'] = permission.enableDeleteAccountForAndroid == 'Y';
      this.combinedAppPermissions['DELETEACCOUNTTHROUGHWEB'] = permission.deleteAccountThroughWeb;
      this.combinedAppPermissions['ENABLEREFUNDPOLICY'] = permission.enableRefundPolicy =='Y';
      this.combinedAppPermissions['ENABLEVCREJOINOUTERTIME'] = permission.disableVCRejoinOuterTime !='Y';
      this.combinedAppPermissions['ENABLEONSPOTREJOINOUTERTIME'] = permission.disableOnspotRejoinOuterTime !='Y';
      this.combinedAppPermissions['SEPERATEVIEWFORPRACTSPEC'] = permission.seperateViewForPractSpec =='Y';
      this.combinedAppPermissions['EXPRESSCLINICGROUPNEARCOUNT'] = permission.expressClinicGroupNearCount || 3;
      this.combinedAppPermissions['GROUPEXPRESSCLINICBYDISTANCE'] = permission.groupExpressClinicByLocation || true;
      this.combinedAppPermissions['CANENABLELINKPATIENTONACCOUNTTAB'] = permission.canEnableLinkPatientOnAccountTab == 'Y';
      this.combinedAppPermissions['CANENABLEMANAGEINSURNACEPOLICIES'] = permission.canEnableManageInsurancePolicies == 'Y';
      this.combinedAppPermissions['CANENABLEVIEWAPPOINTMENT'] = permission.canEnableViewAppointment == 'Y';
      this.combinedAppPermissions['SHOWPRACTITIONERCHARGE'] = permission.showPractitionerCharge == 'Y';
      //added to enable insurance payment for appointment booking
      this.combinedAppPermissions['CHECKINSAPPLICABILITY'] = permission.isInsApplicableForAppt;
      this.combinedAppPermissions['SHOWMYDEVICEINACCOUNTTAB'] = permission.showMyDevicInAccountTab;
      this.combinedAppPermissions['SHOWDAYSBASEDONSCHEDULE'] = permission.showDaysBasedOnSchedule;
      this.combinedAppPermissions['AUTOSELFWHENNOSELF'] = permission.autoSelfWhenNoSelf == 'Y';
      this.combinedAppPermissions['CANHIDEREGISTER'] = permission.canHideRegister;
      this.combinedAppPermissions['LINKEDPERSONMAXCOUNT'] = permission.linkedPersonMaxCount;
      this.combinedAppPermissions['DOCSHAREASPDF'] = permission.docShareASPdf || true;
      this.combinedAppPermissions['HCMAXSERVICEDURATION'] = permission.homeCareClinicMaxApptDur;
      this.combinedAppPermissions['ENABLEFLOWSHEET'] = permission.enableFlowsheet == 'Y'
      this.combinedAppPermissions['SHOWLIVENOTIFICATION'] = permission.showLiveNotification == 'Y'
      this.combinedAppPermissions['ENABLEREQONLYPACKAGE'] = permission.enableReqOnlyPackage == 'Y';
      this.combinedAppPermissions['USEFACILITYWITHOUTPRACTAVAILABILITY'] = permission.allowFacilityViewWithoutPractitioner == 'Y';
      this.combinedAppPermissions['CANSHOWINSALERTINAPTPREVIEW'] = permission.showInsAlertInAptPreview == 'Y';
      this.combinedAppPermissions['ENABLECONFIGBASEDDEFAULTLANG'] = permission.enableConfigBasedDefaultLang == 'Y';
      this.combinedAppPermissions['DISABLEPAYBEFORECHECKINTIME'] = permission.disablePayBeforeCheckinTime;
      this.combinedAppPermissions['EXPRESSCLINICPERPAGECOUNT'] = permission.expressClinicPerPagecount || null;
      this.combinedAppPermissions['CLEARDEFAULTGENDERONREGISTER'] = permission.clearDefaultGenderOnRegister;
      this.combinedAppPermissions['CLEARDEFAULTDOBONREGISTER'] = permission.clearDefaultDobOnRegister;
      this.combinedAppPermissions['DISABLECOUNTRYMODALIFONECNTRY'] = permission.disableChangeCountryIfOneCountry;
      this.combinedAppPermissions['ENABLECATEGORYBASEDCLINICAPPT'] = permission.enableCategoryBasedClinicAppt;
      this.combinedAppPermissions['CLINICAPPTRECENTCONSULTRESPONSELIMIT'] = permission.clinicApptRecentConsultResponseLimit;

       //ONSPOT OVERLAY FLAG
       this.combinedAppPermissions['ENABLECHECKAPPTOVERLAPFORPATIENT'] = permission.enableCheckApptOverlapForPatient == 'Y';
       this.combinedAppPermissions['ENABLERECENTLYCONSULTEDVIEW'] = permission.disableRecentlyConsultedView != 'Y';

      //OTP TIMER FLAG
      this.combinedAppPermissions['HIDEFORGOTPWDRESENDOTP'] = permission.hideForgotPwdResendOtp == 'Y';
      this.combinedAppPermissions['HIDESIGNUPRESENDOTP'] = permission.hideSignupResendOtp == 'Y';
      this.combinedAppPermissions['DISABLECOUNTRYCODEONCHANGENUMBER'] = permission.disableCountryCodeOnChangeNumber == 'Y';
      this.combinedAppPermissions['SHOWNPSRATINGINPXPORTAL'] = permission.showNpsRatingInPXPortal == 'Y';

      this.combinedAppPermissions['CANPAYLATERFORINSURANCEHOSP'] = permission.canPaylaterForInsuranceHosp == 'Y';
      this.combinedAppPermissions['RESCTICTPAYLATERFORINSURANCEVIDEO'] = permission.restrictPaylaterForInsuranceVideo == 'Y';
      this.combinedAppPermissions['ONSPOTWITHSPECIALITY'] = permission.enableOnspotWithSpeciality == 'Y';
      this.combinedAppPermissions['SHOWONSPOTSPECGROUP'] = permission.hideOnspotSpecGroup !== 'Y';
    }
  }

  public canViewOption(optionName: PERMISSIONSAVAILABLE) {
    return this.combinedAppPermissions[optionName];
  }

  public getBookingConcept(): BookAppointmentConcept {
    if (this.canViewOption('BOOKTHROUGHDISCOVERY')) {
      return 'FACILITYUNSPECIFIC'
    } else {
      return 'FACILITYSPECIFIC'
    }
  }
  public getCurrentLanguage() {
    return this.currentLanguage;
  }

  public setCoupons(data){
    this.setStorage(AppConstant.PX_COUPONS, JSON.stringify(data));
  }

  public clearCoupons() {
    this.setStorage(AppConstant.PX_COUPONS, null);
  }

  public getCoupons(): Observable<any>{
    return Observable.create(observer => {
      let coupons = JSON.parse(sessionStorage.getItem(AppConstant.PX_COUPONS));
      observer.next(coupons);
    })
  }

  public isLoggedIn() {
    let token = this.cookie.check(AppConstant.USER_TOKEN);
    let time = this.cookie.checkTime();
    return token && time;
  }

  public isNpsModalDisplayed() {
    return this.npsModalDisplayed
  }
  public setNpsModalDisplayed() {
    this.npsModalDisplayed = true
  }

  public getPolicyDetails(data) {
    let payload = data.map(d => {
      d.version = 'v1';
      return d;
    })
    return new Observable(observer => {
      this.http.post(`${this.getRepoUrl()}api/schedules/getPatientInsuranceDetails`, payload, { reportProgress: false }).subscribe(data => {
        observer.next(data['data']);
        observer.complete();
      }, error => {

      })
    })
  }

  public getCountryObj(cc, ciso = null) {
    let countryDetail = ALL_COUNTRIES.filter(c => c[2] == cc);
    console.log('country info obj---', countryDetail, cc)
    if (cc) {
      countryDetail = ALL_COUNTRIES.filter(c => c[2] == cc);
    }
    if (ciso) {
      countryDetail = ALL_COUNTRIES.filter(c => c[1] == ciso);
    }
    if (countryDetail.length > 1 && cc) {
      countryDetail.find(c => c[1] == cc)
    }
    return countryDetail;
  }
  public getPatient(uhid): Observable<any> {
    let payload = {
      patientId: uhid,
      personId: this.getPersonId()
    }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/persons/getPatientDemographics`, payload, { reportProgress: true }).subscribe((data) => {
        observer.next(data['data']);
      }, error => {
      })
    });
  }

  public updateFinancialStatus(payload): Observable<any> {
    return Observable.create(observer => {
      this.http.post(`${this.getRepoUrl()}api/schedules/changeFinancialStatus`, payload, { reportProgress: false }).subscribe((data) => {
        observer.next(data);
      }, error => {
        observer.error(error);
      })
    });
  }

  public getCoutryCode(phoneNumberStr) {
    var phoneUtil = PhoneNumberUtil.getInstance()
    try {
      var numberProto = phoneUtil.parse(phoneNumberStr, "")
      console.log('country code log---', numberProto, numberProto['values_']['1'])
      return numberProto['values_']
    } catch (e) {
      console.log('country code error log---', e)
    }
  }

  public sendEnquiry(payload) {
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}api/services/enquiry`, payload, { reportProgress: true })
        .subscribe({
          next: (data) => {
            observer.next(data['data']);
          },
          error: (error) => {
            observer.error(error);
          }
        })
    })
  }

  public setFinancialStatusUpdated(status){
    this.isFinancialStatusUpdated=status;
  }

  public getFinancialStatusUpdated(){
    return this.isFinancialStatusUpdated;
  }

  public getAvailableDaysForPract(payload): Observable<any> {
    payload.isBackgroundProcess = true;
    return new Observable(observer => {
      this.http.post(`${this.getRepoUrl()}api/schedules/getAvailableCountForPractitioner`, payload, { reportProgress: false }).subscribe(data => {
        observer.next(data['data']);
      }, error => {
        observer.error();
      })
    })
  }

  public getPasswordValidation(): Array<any> {
    const isExternalInterface = this.isExtidLogin;
    let validator = []
    let minLength = isExternalInterface ? null : 4;
    let maxLength = isExternalInterface ? null : 4;
    let pattern = this.passwordRegex
    if (!pattern)
      pattern = isExternalInterface ? "^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$" : "^[0-9]*$"
    if (this.minPasswordLength) {
      minLength = this.minPasswordLength
    }
    if (this.maxPasswordLength) {
      maxLength = this.maxPasswordLength
    }
    validator.push(PasswordValidator(pattern, minLength, maxLength))
    return validator
  }

  public getQueueNo(body): Observable<any> {
    // const payload = { "apptRefNo": "171783", "password": "8weJEmWB", "linkType": "P" }
    return Observable.create(observer => {
      this.http.post(`${this.getRepoUrl()}api/patients/getQueueNo`, body, { reportProgress: false }).subscribe((data) => {
        observer.next(data);
      }, error => {
        observer.error(this.getErrorMessage(error));
        // observer.next({type: 'LOGIN'})
      })
    });
  }

  public getRestrictionForPatients(payload): Observable<any> {

    return new Observable(observer => {
      this.http.post(`${this.getRepoUrl()}api/schedules/checkGenderRestrictionForPatients`, payload, { reportProgress: false }).subscribe(data => {
        observer.next(data['data']);
        observer.complete();
      }, error => {

      })
    })
  }

  public showNpsRatingControl(): Observable<any> {
    return new Observable(observer => {
      let payload = {
        source: 'PXAPP',
        personId: this.getPersonId()
      }
      this.http.post(`${environment['API_URL']}api/persons/checkNpsRating`, payload, { reportProgress: true }).subscribe(data => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
        console.log(error)
      })
    })
  }
  public storeNpsRating(action, values, options): Observable<any> {
    return new Observable(observer => {
      let payload = {
        source: 'PXPORTAL',
        action: action,
        values: values,
        options: options
      }
      console.log('payload', payload)
      this.http.post(`${environment['API_URL']}api/persons/postNpsRating`, payload, { reportProgress: true }).subscribe(data => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
        console.log(error)
      })
    })
  }
  public setNormalcyInd(data) {
    this.normalcyInds = data;
  }
  public getNormalcyInd() {
    return this.normalcyInds && this.normalcyInds.length > 0 ? this.normalcyInds : DEFAULTNORMALCY;
  }

  public setMasters() {
    this.getMasterConfig.subscribe(data => {
      let normalcyMaster = data.list.find(d => {
        return d.domain === 'NORMALIND'
      });
      if (normalcyMaster) {
        this.setNormalcyInd(normalcyMaster.codes)
      }
    })

  }

  private setDefaultTimezone(timezone) {
    if (!this.timezones) this.timezones = {}
    this.timezones['DEFAULTTIMEZONE'] = { zoneName: timezone }
  }

  private setFacilitiesTimezone(facilities) {
    console.log('setFacilitiesTimezone', facilities)
    if (!this.timezones) this.timezones = {}
    facilities.forEach(facility => {
      let timezone = facility?.facilityControls?.schedulingRules?.defaultTimezone;
      if (timezone) {
        this.timezones[facility.facilityId] = { zoneName: timezone }
      }
      let offset = facility?.facilityControls?.schedulingRules?.offsetTimeForDirectBooking
      if (offset) {
        this.offsets[facility.facilityId] = offset
      }
    })
  }

  public getFacilityTimezone(facilityId) {
    console.log('Guess timezone', moment.tz.guess())
    if (this.timezones && facilityId && this.timezones[facilityId] && this.timezones[facilityId].zoneName) {
      return this.timezones[facilityId].zoneName
    } else if (this.timezones && this.timezones['DEFAULTTIMEZONE']) {
      return this.timezones['DEFAULTTIMEZONE'].zoneName
    } else {
      return moment.tz.guess()
    }
  }

  public getDirectOffset(facilityId) {
    return this.offsets[facilityId] || 0
  }

  public setPaymentIntervalForReference(data) {
    this.paymentIntervalForReference = data;
  }

  public checkTimeExpiry(expire){
    if(this.getMoment().isBefore(expire)){
      return true;
    }
    else {
      return false;
    }
  }

  public getPaymentIntervalForReference() {
    return this.paymentIntervalForReference;
  }

  public getInsuranceDemographics(idNumber): Observable<any> {
    let payload = {
      idNumber: idNumber
    }
    return new Observable(observer => {
      this.http.post(`${this.getRepoUrl()}api/outpatients/getDemographicsFromInsurance`, payload, { reportProgress: false }).subscribe(data => {
        observer.next(data['data']);
        observer.complete();
      }, error => {

      })
    })
  }

  initDataDog(config) {
    // let config = environment['DATADOG_CONFIG'];
    config['version'] =  APP_VERSION;
    // config['defaultPrivacyLevel'] = privacy;
    datadogRum.init(config);
  }

  public getRegistrationMasters(masterCode): Observable<any> {
    let payload = {
      masterCode: masterCode
    }
    return new Observable(observer => {
      this.http.post(`${this.getRepoUrl()}api/masters/getPatRegistrationMasters`, payload, { reportProgress: false }).subscribe((data) => {
        observer.next(data['data']);
        observer.complete()
      }, error => {
        observer.error(error);
      })
    });
  }
}

export function PasswordValidator(pattern: string, minLength, maxLength): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let errors: any = {}
    if (minLength) {
      if (control.value.length < minLength) {
        errors['minlength'] = { count: minLength }
      }
      if (control.value.length > maxLength) {
        errors['maxlength'] = { count: maxLength }
      }
    }
    let reg = new RegExp(pattern);
    const patternError = reg.test(control.value);
    if (!patternError) {
      errors['pattern'] = { pattern: patternError }
    }
    // console.log('PasswordValidator', errors)
    return Object.keys(errors).length ? errors : null
  };
}
