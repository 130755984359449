<div class="about-hospital-wrapper">
  <div class="header border-bottom">
    <div class="back-button" (click)="closeModal()">
      <img src="./assets/icons/contact-us/left_arrow.svg">
      <div class="header-title bold">{{'Insurance_Details' | translate}}
      </div>
    </div>

    <div class="float-right closeicon pointer" (click)="closeModal()">
      <img src="./assets/icons/close_grey.svg">
    </div>
  </div>

  <div class="contact-us-details">
    <div class="card1 row cursor-pointer border-bottom" *ngIf="billingGrpType=='CASH' && hidePolicyLink">
      <div class="cash-section">
        <div class="cash-content">
          <div class="title-label">
            <img src="./assets/icons/manage-insurance/insurance_prim.svg" alt="">
          </div>
          <div class="cash-title">
            <label>{{'INSURANCEDETAILS#PAGE#TITLE#NOINSURANCE' | translate}}</label>
          </div>
          <p class="cash-msg">{{'INSURANCEDETAILS#PAGE#MSG#NOINSURANCE' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="card1 row cursor-pointer">
        <div id="input-section" class="manage-policy-con" >
          <ng-container *ngIf="billingGrpType=='CASH' || updateView">
            <form [formGroup]="insuranceForm" autocomplete="off" *ngIf="insurance && insurance.length>0">
              <ng-container *ngFor="let groups of insurance;let index=index;">
                <ng-container *ngFor="let controls of groups.child">
                  <div class="relative-div form-input-div" [formGroupName]="groups.name">
                  <ng-container *ngIf="controls.input=='number'">
                    <app-custom-input-group [label]="controls.title | translate" [formControlName]="controls.name" type="number" [readonly]="controls.readonly">
                    </app-custom-input-group>
                  </ng-container>
                  <ng-container *ngIf="controls.input=='search_select'">
                    <app-custom-input-group [label]="controls.title | translate" [formControlName]="controls.name"
                      type="search_select" [selectList]="controls.selectList" [selectOptions]="controls.selectOptions"
                      idval="{{'REFPATREG'+controls.name.toUpperCase()}}" [readonly]="controls.readonly"
                      [selectedValue]="insuranceForm.get([groups.name,controls.name]).value" (dropdownValue)="chooseValue($event)" [emit]="true" [hideSearch]="controls.hideSearch" [enableRefresh] ="controls.enableRefreshView">
                    </app-custom-input-group>
                  </ng-container>
                </div>
                </ng-container>
                </ng-container>
              </form>
          </ng-container>
          <ng-container *ngIf="billingGrpType!=='CASH' && policyInfo">
            <div class="input-group ins-dtl">
              <div class="active-label" *ngIf="updateView">
                <label [for]="random+'cus-sel-inputId'">
                  {{"MANAGEPOLICY#DETAIL#LABEL#ACTIVEPOLICIES" | translate}}
                </label>
              </div>
              <div class="policy-container" >
                <div class="policy-item-con first-row border-bottom" *ngIf="policyInfo.custGroupName">
                  <p>{{"MANAGEPOLICY#DETAIL#LABEL#INSCOMPANY" | translate}}</p>
                  <p class="policy-val"><strong>{{policyInfo.custGroupName}}</strong></p>
                </div>
                <div class="policy-item-con border-bottom" *ngIf="policyInfo.payerName">
                  <p>{{"MANAGEPOLICY#DETAIL#LABEL#PAYERNAME" | translate}}</p>
                  <p class="policy-val"><strong>{{policyInfo.payerName}}</strong></p>
                </div>
                <div class="policy-item-con border-bottom" *ngIf="policyInfo.policyName">
                  <p>{{"MANAGEPOLICY#DETAIL#LABEL#POLICYNAME" | translate}}</p>
                  <p class="policy-val"><strong>{{policyInfo.policyName}}</strong></p>
                </div>
                <div class="policy-item-con border-bottom" *ngIf="policyInfo.policyNumber">
                  <p>{{"MANAGEPOLICY#DETAIL#LABEL#POLICYNUMBER" | translate}}</p>
                  <p class="policy-val"><strong>{{policyInfo.policyNumber}}</strong></p>
                </div>
                <div class="policy-item-con border-bottom" *ngIf="policyInfo.membershipNo">
                  <p>{{"MANAGEPOLICY#DETAIL#LABEL#MEMBERID" | translate}}</p>
                  <p class="policy-val"><strong>{{policyInfo.membershipNo}}</strong></p>
                </div>
                <div class="policy-item-con border-bottom" *ngIf="policyInfo.policyStartDate">
                  <p>{{"MANAGEPOLICY#DETAIL#LABEL#EFFECTIVEFROM" | translate}}</p>
                  <p class="policy-val"><strong>{{getEffectedDate(policyInfo.policyStartDate)}}</strong></p>
                </div>
                <div class="policy-item-con border-bottom" *ngIf="policyInfo.policyExpiryDate">
                  <p>{{"MANAGEPOLICY#DETAIL#LABEL#EFFECTIVETILL" | translate}}</p>
                  <p class="policy-val"><strong>{{getEffectedDate(policyInfo.policyExpiryDate)}}</strong></p>
                </div>
                <div class="policy-item-con border-bottom" *ngIf="policyInfo.policyExpiryDate">
                  <p>{{"MANAGEPOLICY#DETAIL#LABEL#STATUS" | translate}}</p>
                  <p class="policy-val"><strong *ngIf="!checkIsExpired(policyInfo['policyExpiryDate'])" class="expired">{{"MANAGEPOLICY#DETAIL#LABEL#STATUS#EXPIRED" | translate}}</strong>
                    <strong *ngIf="checkIsExpired(policyInfo['policyExpiryDate'])" class="active">{{"MANAGEPOLICY#DETAIL#LABEL#STATUS#ACTIVE" | translate}}</strong>
                  </p>
                </div>
                <div class="button-row">
                <div class="update-button-con" (click)="updateInsurance()" *ngIf="updateView && toUpdatePolicy">
                  <p>{{"MANAGEPOLICY#DETAIL#BUTTON#LABEL#UPDATEPOLICY" | translate}}</p>
                </div>
                <div class="update-button-con" (click)="refreshInsurance()"*ngIf="updateView && !toUpdatePolicy">
                  <p>{{"MANAGEPOLICY#DETAIL#BUTTON#LABEL#REFRESHPOLICY" | translate}}</p>
                </div>
              </div>
                  <div class="button-row"  *ngIf="billingGrpType!=='CASH' && policyInfo && !updateView">
                    <div class="danger" (click)="openInsurancePop()">
                      <p>
                        {{"MANAGEPOLICY#DETAIL#BUTTON#ACTION#LABEL#REMOVE" | translate}}
                      </p>
                    </div>
                    <div class="update-button-con" (click)="updateView=true;">
                      <p>
                        {{"MANAGEPOLICY#DETAIL#BUTTON#ACTION#LABEL#UPDATE" | translate}}
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="noInsurance">
            <div>
              <div class="cash-section">
                <div class="cash-content">
                  <div class="title-label">
                    <img src="./assets/icons/manage-insurance/insurance_prim.svg" alt="">
                  </div>
                  <div class="cash-title">
                    <label>{{'No insurance policies found'}}</label>
                  </div>
                  <div class="border">
                    <img src="./assets/icons/manage-insurance/call.svg" alt="">
                    <p class="cash-msg">{{'INSURANCEDETAILS#PAGE#LABEL#CALL' | translate}}<a href="tel:{{callno}}">{{'Call us'}}</a></p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
  </div>
</div>


<div *ngIf="removeInsurance">
  <app-remove-poliy-modal (complete)="updateBillingGroup($event)" (closeModal)="closeInsurancePop()"></app-remove-poliy-modal>
</div>

<div class="missing-demographics-container" *ngIf="showEditPatient">
  <app-register-new-patient [editDetails]="patient.actualData" [isEdit]="true" (close)="closeEditPatientModal($event)"
    [showMissingMandatoryParams]="true"></app-register-new-patient>
</div>