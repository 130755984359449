import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserService } from '../services/user.service';
import { TranslatePipe } from '../pipes/translate.pipe';
import * as _ from 'lodash';
@Component({
  selector: 'app-manage-insurance',
  templateUrl: './manage-insurance.component.html',
  styleUrls: ['./manage-insurance.component.scss'],
  providers: [TranslatePipe]
})
export class ManageInsuranceComponent implements OnInit {

  public patientList: any;
  public retrievePolicy: boolean = false;
  public selectedPat: any = null;
  public showInsuranceDtl: boolean = false;
  public defaultFacilityIdForReg: any;
  public payGroup: any;
  @Output() completeCoupon: EventEmitter<any>;
  constructor(private user: UserService, private translatePipe: TranslatePipe,) {
    this.completeCoupon = new EventEmitter<any>();
  }



  ngOnInit() {
    this.defaultFacilityIdForReg = this.user.getDefaultFacilityForRegistration()
    let patientList;
    this.user.getUserInfo('linkedPatients').subscribe(
      data => {
        if (data) {
          patientList = data;
        }
    })
    this.listPayerGroup();
    this.patientList = patientList.map(pat => {
      let list = {}
      list['title'] = pat['personname']
      list['actualData'] = JSON.parse(JSON.stringify(pat))
      list['imageUrl'] = pat ? pat['imageurl'] : ""
      list['summary'] = pat ? this.translatePipe.transform(pat['relationshiptype'], 'RELATION') : ""
      list['uhid'] = pat['uhid']
      list['facilityId'] = pat['facilityid']
      list['natoriqmaId'] = list['actualData']['national_id'] ? list['actualData']['national_id'] : list['actualData']['iqama_id']
      return list;
    })
    let tmp = [];
    this.patientList.forEach((p) => {
      tmp.push({
        patientId: p['uhid'],
        facilityId: p['facilityId']
      })
    })
    this.getPatientPolicyDatails(tmp);
    this.choosePatient(this.patientList[0]);
  }

  public refreshPolicyDtl() {
    let tmp = [];
    this.patientList.forEach((p) => {
      tmp.push({
        patientId: p['uhid'],
        facilityId: p['facilityId']
      })
    })
    this.getPatientPolicyDatails(tmp);
  }

  public getPatientPolicyDatails(inparam) {
    this.retrievePolicy = true;
    this.user.getPolicyDetails(inparam).subscribe((data: any) => {
      console.log('ins data---', data);
      this.retrievePolicy = false;
      this.patientList.forEach(p => {
        data.forEach(d => {
          if (p['uhid'] == d['patientId']) {
            p['insuranceDetails'] = d['insuranceDetails'],
            p['insuranceFetched'] = true;
          }
        })
      })
      this.showInsuranceDtl = true;
      console.log('patient list---', this.patientList, this.selectedPat)
    })
  }

  choosePatient(pat) {
    console.log('patient123', pat)
    this.selectedPat = _.cloneDeep(pat)
    pat.selected = true;
    this.patientList.forEach(p => {
      if (p['uhid'] !== pat['uhid']) {
        p.selected = false;
      }
    })
  }

  public listPayerGroup() {
    console.log('defaultFacilityIdForReg', this.defaultFacilityIdForReg);
    this.user.listPayerGroup(this.defaultFacilityIdForReg, false).subscribe(data => {
      data.unshift({
        code: '0001',
        desc: this.translatePipe.transform('SEARCHSELECT#CASH#SELECT'),
        BLNGGRPID: 'CASH'
      })
      this.payGroup = data;
    })
  }

  public getEffectedDate(date) {
    return this.user.getMoment(date).format('DD MMM YYYY')
  }

  public checkIsExpired(date) {
    return this.user.checkTimeExpiry(date);
  }


  public closeModal() {
    this.completeCoupon.emit(false)
  }
  closeInfo() {
    this.selectedPat = null;
  }
}
