import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AppointmentService } from '../services/appointment.service';
import { environment } from '@env/environment';
import { PaymentService } from '../services/payment.service';
import { UserService } from '../services/user.service';
import { COUPON_PARAMS, POINTSTYPEDEFAULT, PROMO_PARAMS } from '../app.const';
import { Subject } from "rxjs";
import * as _ from 'lodash';
import { AltpaymentInputComponent } from '../components/altpayment-input/altpayment-input.component';
import { POINTSTYPE } from '@type';

@Component({
  selector: 'app-onspot-request-accept',
  templateUrl: './onspot-request-accept.component.html',
  styleUrls: ['./onspot-request-accept.component.scss']
})
export class OnspotRequestAcceptComponent implements OnInit {

  @Input() consultData;
  @Input() eventInfo;
  @Input() overlapAppt;
  @Input() selectedSpeciality;
  @Output() payment: EventEmitter<any>;
  @Output() closeConsult: EventEmitter<any>;
  @Output() navigateTopic: EventEmitter<any>;
  private currency_code;
  private payType;
  public paymentGatewayUrl: string;
  private facilityId;

  public canContinue: boolean = false; //check
  public canPayForOnspot: boolean = false; //check
  public isEnablePromo: boolean;
  public isEnableCoupon: boolean;
  public patientPayable: number;
  private paymentData: any;
  public promptLabels: any;
  public showCashPrompt: boolean = false;
  public showInsPrompt: boolean = false;
  public fetchingCharge: boolean = false;
  public policyValidation: any = null;
  private billingGroup: any = null;
  private patientInsurance: any = null;
  public showApplePay: boolean = false;
  public transactionRef: any;
  public myIP: any;
  public currentTime = this.user.getMoment().format("DD MMM YYYY HH:mm a");
  public enterReferral: boolean;
  public isReferralApplicable: boolean = false;
  public referralCode: any;
  public referralControl: any;
  public videoConsultationTC: boolean = false;

  //NewFlow
  private actualInsuranceDetails: any;
  public freeFollowUpYn: string;
  public discountDetails: any;
  public clinicCode
  public chargeDetails: any = {}
  public isEnablePoints
  public selections: any;
  public chargeError: boolean = false;
  private userAction: any = {};
  private tempChargeData: any = null;
  public editPatient: any = null;
  public showEditPatient: boolean = false;

  @ViewChild('coupon', { static: false }) coupon: AltpaymentInputComponent;
  @ViewChild('points', { static: false }) points: AltpaymentInputComponent;

	public readonly pointsType: POINTSTYPE = POINTSTYPEDEFAULT
  public chargeVatInfoRef:any;
  public chargeVatDetails:any;
  constructor(private apptService: AppointmentService, private paymentService: PaymentService, private user: UserService, private cdf: ChangeDetectorRef) {
    this.payment = new EventEmitter<any>();
    this.closeConsult = new EventEmitter<any>();
    this.navigateTopic = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.user.getConfigurationInfo.subscribe(data => {
      this.isEnablePromo = data['pxAppSettings'] && data['pxAppSettings']['enablePromoYn'] == 'Y' ? true : false;
      this.isEnableCoupon = data['pxAppSettings'] && data['pxAppSettings']['enableCouponYn'] == 'Y' ? true : false;
      this.isEnablePoints = data['pxAppSettings'] && data['pxAppSettings']['enablePointsYn'] == 'Y' ? true : false;
      this.isReferralApplicable = data['pxAppSettings'] && data['pxAppSettings']['referral'] && data['pxAppSettings']['referral']['canReferForOnspot'];
      this.referralControl = data['pxAppSettings'] && data['pxAppSettings']['referral'] && data['pxAppSettings']['referral']['controls'];
    })
    this.mapSelections();
    console.log("consultData", this.consultData);
    this.getHisPracCharges();
    this.user.getConfigurationInfo.subscribe(data => {
      this.paymentGatewayUrl = data.features['paymentGateway']['paymentGatewayUrl']
    })
    setTimeout(() => {
      console.log('timeout triggered');
      this.payment.emit({ name: 'rejectRequest' });
    }, 120000);
    this.consultData['consultationCost']['chargeCurrency'] = this.consultData['consultationCost']['chargeCurrency'] || this.user.getCurrency();
    this.getMyip()
  }

  ngAfterViewInit() {
    if ((window as any).ApplePaySession) {
      console.log('3')
      if ((window as any).ApplePaySession.canMakePayments) {
        this.showApplePay = true;
      }
    }
  }

  private mapSelections() {
    this.facilityId = this.consultData['facilityId']
    this.selections = {
      facility: {
        id: this.consultData.facilityId
      },
      patient: {
        id: this.consultData.patientInfo.uhid,
        ...this.consultData.patientInfo
      },
      practitioner: {
        id: this.consultData.practInfo['practId'],
        ...this.consultData.practInfo
      },
      speciality: {
        id: this.consultData.specialityInfo['specCode'],
        ...this.consultData.specialityInfo
      }
    }
    console.log('mapSelections', this.facilityId, this.selections)
  }

  public async getMyip() {
    let res = await fetch("https://api.ipify.org?format=text");
    this.myIP = await res.text()
    console.log('ip ==', this.myIP);
  }
  // For referral code
  public onTextInput(ev?: any) {
    console.log('event', ev)
    let regexValidation = this.referralControl.regex;
    if (regexValidation) {
      const regex = new RegExp(regexValidation, 'g')
      let value = ev.target.value;
      let newVal = value.replace(regex, '');
      ev.target.value = newVal;
      this.referralCode = newVal;
    }
  }

  public closeConsultation() {
    this.closeConsult.emit();
  }
  public navigateToPreviousTopic() {
    this.navigateTopic.emit();
  }

  public getHisPracCharges(forcePaymentWithCash: boolean = false, actualChargeData = null) {
    this.fetchingCharge = true;
    var data = [{
      apptDate: this.user.getMoment().format("YYYY-MM-DD"),
      apptRefNo: null,
      clinicCode: null,
      facilityId: this.consultData['facilityId'],
      forcePaymentWithCash: forcePaymentWithCash,
      "isGeneralSpeciality": this.selectedSpeciality?.isGeneralSpeciality,
      onSpotConsult: 'Y',
      patientId: this.consultData['patientInfo']['uhid'],
      practitionerId: this.consultData.practInfo['practId'],
      selectedSpeciality: this.selectedSpeciality,
      visitType: 'VIDEO',
    }];
    if (forcePaymentWithCash) {
      this.userAction = {
        changeFromInsToCash: true,
        patientId: this.consultData['patientInfo']['uhid'],
        insuranceDetails: actualChargeData.validationDetails,
        billingGroup: this.billingGroup,
        reason: 'USERACTIONWITHCASH'
      }
    }
    this.canPayForOnspot = false;
    this.apptService.getVisitCharge(data).subscribe(response => {
      this.checkInsuranceValidation(response);
    }, err => {
      this.chargeError = true;
      this.fetchingCharge = false;
    })
  }
  private checkInsuranceValidation(response) {
    let data = response[0];
    if (data['checkPolicyValidation']) {
      const validationDetails = data['validationDetails'];
      if (validationDetails?.fetchMissingDemographics) {
        this.getMissingDemographics(data, 'DEMOGRAPHICSMISSING')
      } else if (validationDetails['promptCashAppt']) {
        this.getConfirmationForCashPayment(data, validationDetails.promptLabels);
        return;
      } else if (validationDetails['canPayWithCash']) {
        this.getConfirmationForInsPayment(data, validationDetails.promptLabels);
        return;
      }
    }
    this.bindChargeData(data);
  }
  private getConfirmationForCashPayment(data, promptLabels) {
    this.paymentData = data;
    this.promptLabels = promptLabels;
    this.showCashPrompt = true
  }
  private getConfirmationForInsPayment(data, promptLabels) {
    this.paymentData = data;
    this.promptLabels = promptLabels;
    this.showInsPrompt = true
  }

  public proceedWithCash() {
    this.bindChargeData(_.cloneDeep(this.paymentData));
    this.showCashPrompt = false;
    this.paymentData = null;
    this.promptLabels = null;
  }
  public proceedWithIns() {
    this.bindChargeData(_.cloneDeep(this.paymentData));
    this.showInsPrompt = false;
    this.paymentData = null;
    this.promptLabels = null;
  }

  public retrievePaymentWithCash() {
    this.getHisPracCharges(true, this.paymentData)
    this.showInsPrompt = false;
    this.paymentData = null;
    this.promptLabels = null;
  }


  private bindChargeData(chargeData) {
    this.actualInsuranceDetails = null;
    this.patientPayable = chargeData['patientPayableAmount'];
    this.patientPayable = Number(this.user.fixDecimalPlaces(Number(this.patientPayable)))
    if (chargeData['freeFollowUpYn'] == 'Y') {
      this.freeFollowUpYn = chargeData.freeFollowUpYn;
      this.patientPayable = 0
      this.isEnableCoupon = false;
      this.isEnablePromo = false;
    } else {
      this.freeFollowUpYn = null;
    }
    if (chargeData && chargeData.discount && chargeData.discount.discountApplicable == 'Y') {
      this.discountDetails = {
        applicable: true,
        charge: chargeData['charge']
      }
    } else {
      this.discountDetails = {
        applicable: false,
        charge: null
      }
    }
    this.billingGroup = chargeData['billingGroupCode']
    this.clinicCode = chargeData['clinicCode'];
    this.patientInsurance = chargeData['insuranceFinDtls'] && chargeData['insuranceFinDtls'].length > 0 ? chargeData['insuranceFinDtls'][0] : null;
    this.actualInsuranceDetails = chargeData['insuranceFinDtls'] && chargeData['insuranceFinDtls'].length > 0 ? chargeData['insuranceFinDtls'][0] : null;
    if (chargeData && chargeData.checkPolicyValidation && chargeData.validationDetails) {
      this.policyValidation = chargeData.validationDetails; //validationDetails
    } else {
      this.policyValidation = null;
    }
    if (chargeData['disableCoupon']) {
      this.isEnableCoupon = false
    }
    if (chargeData['disablePoints']) {
      this.isEnablePoints = false
    }
    let chargeDetails: any = {
      discountDetails: this.discountDetails,
      consultationCharge: Number(this.patientPayable),
      discountedPayable: Number(this.patientPayable),
      promoDiscount: 0,
      couponDiscount: 0,
      isPromoApplied: false,
      isCouponApplied: false,
      isFreeFollowUp: false,
      couponTitle: [],
      canPayOptional: false
    }
    this.chargeVatInfoRef=_.cloneDeep(chargeData.parsedCharge);
    if(chargeData && chargeData.parsedCharge){
      this.calculateVatCharges(chargeData.parsedCharge);
    }
    this.chargeDetails = chargeDetails;
    this.calculatePayable()
    this.fetchingCharge = false;
  }

  private generateTransactionParams(forApplePay) {
    return {
      linkedPersonId: this.consultData['patientInfo']['personid'],
      patientId: this.consultData['patientInfo']['uhid'],
      amount: this.patientPayable,
      currency: this.consultData['consultationCost']['chargeCurrency'],
      episodeType: 'R',
      encounterId: '0',
      facilityId: this.facilityId,
      requestType: 'APPTBK',
      forApplePay: forApplePay
    }
  }

  private generateRegistrationParams() {
    var tempReq = {
      resourceId: this.consultData.practInfo['practId'],
      linkedPersonId: this.consultData['patientInfo']['personid'],
      patientPersonId: this.consultData['patientInfo']['personid'],
      uhid: this.consultData['patientInfo']['uhid'],
      facilityId: this.facilityId,
      personName: this.consultData['patientInfo']['personname'],
      dob: this.user.getMoment(this.consultData['patientInfo']['dob']).format("YYYY-MM-DD"),
      gender: this.consultData['patientInfo']['gender'],
      localeCode: this.user.currentLanguage,
      practitionerName: this.consultData.practInfo['practName'],
      specialityName: this.consultData.specialityInfo['specDesc'],
      specialityCode: this.consultData.specialityInfo['specCode'],
      patientName: this.consultData['patientInfo']['personname'],
      discount: null,
      visitType: 'VIDEO',
      autoApptFlagYn: 'Y',
      overlapYN: this.overlapAppt,
      billingGroup: this.billingGroup,
      insuranceFinDtls: this.patientInsurance,
      taskId: this.eventInfo['task_ascn_no'],
      taskGroup: this.eventInfo['task_group_code'],
      referralCode: this.referralCode ? this.referralCode : null,
      eventId: this.eventInfo.event_ascn_no,
      conceptCode: this.eventInfo.concept_code,
      clinicCode: this.clinicCode,
      isPromoCodeAppliedYn: this.getPromoCodeApplystatus('PROMO'),
      isCouponAppliedYn: this.getPromoCodeApplystatus('COUPON'),
      isPointsAppliedYn: this.getPromoCodeApplystatus('POINTS'),
      coupons: this.getAppiledCoupons('COUPON'),
      appliedPromoCodes: this.getAppiledCoupons('PROMO'),
      appliedPoints: this.getAppiledCoupons('POINTS'),
      source: 'PXPORTAL',
      personId: this.user.getPersonId(),
      userAction: this.userAction,
    };
    if (this.freeFollowUpYn == 'Y')
      tempReq['freeFollowUpYn'] = this.freeFollowUpYn

    if (this.patientInsurance) {
      tempReq['isInsurance'] = true;
    }
    if (this.policyValidation)
      tempReq['policyValidationDetails'] = this.policyValidation
    return tempReq;
  }


  public confirmAppt() {
    console.log("confirmAppt on spot",)
    let data = this.generateRegistrationParams()
    this.user.confirmAppointment(data).subscribe(response => {
      console.log("confirmAppointment response on spot", response);
      let d = {
        name: 'book_success',
        status: 'S',
        currency: this.currency_code,
        category: 'CASH',
        medium: 'FACILITY'
      }
      d['amount_to_be_paid'] = this.patientPayable
      this.payment.emit({ name: 'CONFIRM_APPT', reference: response.data.apptRefNumber });
    })
  }

  public initAppointment() {
    this.videoConsultationTC = true;
  }

  public closeVcModal() {
    this.videoConsultationTC = false;
  }

  public continuePay() {
    this.payForAppt()
  }


  public payForAppt() {
    this.currency_code = this.consultData['consultationCost']['chargeCurrency'] || this.user.getCurrency();
    this.patientPayable
    this.payType = 'C'
    this.facilityId = this.consultData['facilityId']
    var payload = this.generateTransactionParams(false)
    console.log("payForAppt payload", payload)
    let request = this.generateRegistrationParams()

    this.apptService.startTransaction(payload, request).subscribe((response: any) => {
      console.log("environment.DEFAULT_EMAIL", environment.DEFAULT_EMAIL);
      let appointment = {
        _orderId: response['transaction_id'],
        saved_card_token: response['saved_card_token'],
        saved_card_details: response['savedCards'] && response['savedCards'].length ? response['savedCards'].map(c => c['cardsInfo']) : [],
        saved_stc_details: response['savedSTCNumber'] && response['savedSTCNumber'].length ? response['savedSTCNumber'].map(c => c['cardsInfo']) : [],
        uhid: this.consultData['patientInfo']['uhid'],
        email: environment.DEFAULT_EMAIL || null,
        mobile_no: ''
      }
      let url = window['location'];
      let transaction_id = response['transaction_id'];
      let retry = `pt=${this.payType}&cost=${this.patientPayable}`;
      let visitType = 'VCN';
      let params = `orderid=${transaction_id}&f=${this.facilityId}&p=${this.consultData.practInfo['practId']}&h=${this.consultData['patientInfo']['uhid']}&v=${visitType}&${retry}`
      let diff = (href, origin) => href.split(origin).join('');
      let returnURL = diff(url['href'].split('?')[0], url['origin'] + '/') + `?s=1&method=APPTBK&p=${btoa(params)}`;
      let paymentInfo = {
        _returnURL: returnURL,
        currency: this.user.getCurrencyForPayment() || this.currency_code,
        charge: this.user.fixDecimalPlaces(this.patientPayable),
        paymentgatewayurl: this.paymentGatewayUrl
      }
      console.log("paymentInfo", paymentInfo)
      this.paymentService.setPaymentParams(appointment, paymentInfo).subscribe(_ => {
        this.payment.emit({ name: 'openPaymentCheckout' });
      })
    })
  }
  public initApplePay() {
    console.log('clicked apple pay button', this.consultData);

    let charge = this.user.fixDecimalPlaces(this.patientPayable)

    const paymentRequest = {
      countryCode: 'SA',
      currencyCode: 'SAR',
      shippingMethods: [
      ],
      lineItems: [
        {
          label: "Appointment charges",
          amount: charge,
        }
      ],
      total: {
        label: 'Total Amount',
        amount: charge,
      },
      supportedNetworks: ['visa', 'mada', 'masterCard'],
      merchantCapabilities: ['supports3DS']
    };

    // Create ApplePaySession
    const session = new (window as any).ApplePaySession(3, paymentRequest);

    session.onvalidatemerchant = async (event: any) => {
      // Call your own server to request a new merchant session.
      console.log('Inside On Validate Merhant', event, event['validationURL'])
      const merchantSession = await this.validateMerchant(event['validationURL']);
      console.log('merchantsession', merchantSession);
      session.completeMerchantValidation(merchantSession);
    };

    session.onpaymentauthorized = (event: any) => {
      // Define ApplePayPaymentAuthorizationResult
      console.log('Payment authorized----', event, event['payment'], JSON.stringify(event['payment']))
      const result = {
        "status": (window as any).ApplePaySession.STATUS_SUCCESS
      };
      let inparams = {
        "merchant_reference": this.transactionRef,
        "amount": charge,
        "currency": this.consultData['consultationCost']['chargeCurrency'],
        "customer_email": environment.DEFAULT_EMAIL,
        "appleData": event['payment'],
        "mobileNumber": this.user.getPersonMobile(),
        "interfaceFrom": "WEB",
        "customer_ip": this.myIP
      }
      this.updateApplePayTransaction(this.transactionRef, inparams)
      session.completePayment(result);
    };

    session.oncancel = (event: any) => {
      // Payment cancelled by WebKit
      console.log('check cancel event---', event);
    };

    this.facilityId = this.consultData['facilityId']

    var payload = this.generateTransactionParams(true)

    console.log("payForAppt payload", payload)
    let request = this.generateRegistrationParams()

    console.log('start transaction request object---', payload)
    this.apptService.startTransaction(payload, request).subscribe((response: any) => {
      console.log('start transaction response---', response)
      this.transactionRef = response.transaction_id;
      setTimeout(() => {
        session.begin();
      }, 500)
    }, err => {
      // this.isTransaction = false
    })
  }
  private validateMerchant(validationUrl) {
    let self = this;
    return new Promise(async function (resolve, reject) {
      let param = {
        validationURL: validationUrl
      }
      self.apptService.verifyMerchant(param).subscribe(data => {
        console.log('validate merchant data---', data)
        return resolve(data);
      }, err => {
        return reject(err)
      });
    })
  }
  public updateApplePayTransaction(txnId, inparam) {
    console.log('update apple pay inparamss---', inparam)
    this.apptService.updateApplePayStatus(inparam).subscribe(data => {
      console.log('update apple pay status---', data)
      this.getTransactionStatus(txnId)
    }, err => {
      console.log('update apple pay error---', err)
    });
  }
  public getTransactionStatus(txnId) {
    this.paymentService.getTransactionDetail(txnId).subscribe(data => {
      console.log('transaction data', data)
      if (data.status == 'S') {
        setTimeout(() => {
          this.payment.emit({ name: 'CONFIRM_APPT', reference: data['response']['apptRefNumber'] });
        }, 1000)
      }
    }, err => {
      console.log('transaction error---', err)
    });
  }

  public applyingPromoCode(event) {
    this.fetchingCharge = event.status
  }
  public applyingCouponCode(event) {
    this.fetchingCharge = event.status
  }
  public appliedPromocode(event) {
    if (event.type == 'APPLY') {
      this.applyDiscount(event.data)
    } else if (event.type == 'REMOVE') {
      this.removeDiscounts();
    }
  }
  public couponApplied(event) {
    if (event.type == 'APPLY') {
      this.chargeDetails.couponTitle = []
      this.applyCouponcode(event.data)
    } else if (event.type == 'REMOVE') {
      this.removeCouponCode()
    }
  }
  public pointsApplied(event) {
    if (event.type == 'APPLY') {
      this.chargeDetails.pointsData = {}
      this.applyPoints(event.data)
    } else if (event.type == 'REMOVE') {
      this.removePoints()
    }
  }
  public applyingPoints(event) {
    this.fetchingCharge = event.status
  }


  private applyDiscount(response) {
    this.chargeDetails.appliedpromocodedtls = response.appliedpromocodedtls;
    this.chargeDetails.promoDiscount = Number(response.calculatedDiscountAmt);
    this.patientPayable = Number(response.netservicecharge)
    this.chargeDetails.discountedPayable = Number(response.netservicecharge)
    this.chargeDetails.isPromoApplied = response.appliedpromocodedtls?.length > 0;
    if(response.netchargedtls && response.netchargedtls.length>0){
      this.chargeVatDetails['vatDisplayAmount']=this.calculateVatDisplayAmount(response.netchargedtls[0].taxAmount,response.netchargedtls[0].taxExemptAmount);
    }
    if (response.pointsApplied) {
      this.applyPoints({ pointsDerivative: response.pointsApplied })
    } else {
      this.removePoints()
    }
    this.handlePostDiscount()
  }

  public removeDiscounts() {
    this.patientPayable = this.chargeDetails['consultationCharge']
    this.chargeDetails['discountedPayable'] = this.chargeDetails['consultationCharge']
    this.chargeDetails.isPromoApplied = false;
    this.chargeDetails.promoDiscount = 0;
    this.patientPayable = Number(this.user.fixDecimalPlaces(Number(this.patientPayable)));
    this.removePoints()
    this.handlePostDiscount()
  }
  
  private handlePostDiscount() {
    let isCouponHandled = this.coupon && this.chargeDetails.isCouponApplied
    let isPointsHandled = this.points && this.chargeDetails.isPointsApplied && this.pointsType == 'PAYMENT'

    if (isCouponHandled || isPointsHandled) {
      if (isCouponHandled) this.coupon.removeAllCoupons(true);
      if (isPointsHandled) this.points.removeAllPoints(!isCouponHandled);
    } else {
      this.calculatePayable()
    }
  }

  private applyCouponcode(response) {
    this.chargeDetails.appliedcouponcodedtls = response.appliedcouponcodedtls;
    this.chargeDetails.couponTitle = this.chargeDetails.appliedcouponcodedtls.map(item => item.couponTitle)
    this.chargeDetails.couponDiscount = Number(response.value);
    this.chargeDetails.isCouponApplied = response.appliedcouponcodedtls.length > 0;
    this.calculatePayable()
  }
  private removeCouponCode() {
    this.chargeDetails.appliedcouponcodedtls = null
    this.chargeDetails.couponTitle = []
    this.chargeDetails.isCouponApplied = false;
    this.chargeDetails.couponDiscount = 0;
    this.calculatePayable()
  }

  private applyPoints(response) {
    console.log('applypoints', response)
    this.chargeDetails.appliedPoints = response.pointsDerivative;
    this.chargeDetails.pointsDiscount = Number(response.pointsDerivative.amount);
    this.chargeDetails.isPointsApplied = this.chargeDetails.pointsDiscount > 0
    if (this.pointsType == 'PAYMENT')
      this.calculatePayable()
  }

  private removePoints() {
    this.chargeDetails.appliedPoints = null;
    this.chargeDetails.pointsDiscount = 0;
    this.chargeDetails.isPointsApplied = false;
    if (this.pointsType == 'PAYMENT')
      this.calculatePayable()

    this.calculateVatCharges(this.chargeVatInfoRef);
  }

  private calculatePayable() {
    let couponDiscount = Number(this.chargeDetails.couponDiscount) || 0;
    let pointsDiscount = this.pointsType == 'PAYMENT' ? (Number(this.chargeDetails.pointsDiscount) || 0) : 0;
    this.patientPayable = Number(this.chargeDetails.discountedPayable) - couponDiscount - pointsDiscount;
    this.chargeDetails._payableWithoutCoupon = this.patientPayable + couponDiscount;
    this.chargeDetails._payableWithoutPoints = this.patientPayable + pointsDiscount;
    this.resetPaymentFlow()
  }
  private resetPaymentFlow() {
    if (this.patientPayable < 0) {
      this.patientPayable = 0;
    }
    this.patientPayable = Number(this.user.fixDecimalPlaces(Number(this.patientPayable)));
    setTimeout(_ => {
      this.cdf.detectChanges();
      this.fetchingCharge = false
    }, 500)
  }

  getPromoCodeApplystatus(type) {
    if (type == 'PROMO' && this.chargeDetails && this.chargeDetails.isPromoApplied)
      return 'Y'
    else if (type == 'COUPON' && this.chargeDetails && this.chargeDetails.isCouponApplied)
      return 'Y'
    else if (type == 'POINTS' && this.chargeDetails && this.chargeDetails.isPointsApplied)
      return 'Y'
    else
      return 'N'
  }

  getAppiledCoupons(type) {
    if (type == 'PROMO' && this.chargeDetails && this.chargeDetails.isPromoApplied)
      return this.chargeDetails.appliedpromocodedtls
    else if (type == 'COUPON' && this.chargeDetails && this.chargeDetails.isCouponApplied)
      return this.chargeDetails.appliedcouponcodedtls
    else if (type == 'POINTS' && this.chargeDetails && this.chargeDetails.isPointsApplied)
      return this.chargeDetails.appliedPoints
    else
      return null
  }
  private calculateVatCharges(parsedCharge){
    let chargeVatDetails = parsedCharge;
    chargeVatDetails['vatDisplayAmount']=this.calculateVatDisplayAmount(chargeVatDetails.vatAmount,chargeVatDetails.vatExemptedAmt)
    this.chargeVatDetails=chargeVatDetails;
    console.info('chargeVatDetails',this.chargeVatDetails)
  }

  private calculateVatDisplayAmount(vatamt,vatexpamt){
    let vatAmount, vatExemptedAmt,vatDisplayAmount;
    vatAmount = (vatamt && vatamt!='') ? parseFloat(vatamt) : 0;
    vatExemptedAmt = (vatexpamt && vatexpamt!='') ? parseFloat(vatexpamt) : 0;
    vatDisplayAmount = vatAmount - vatExemptedAmt;
    vatDisplayAmount = vatDisplayAmount.toString();
    return vatDisplayAmount;
  }


  public async getMissingDemographics(chargeData, type) {
    this.showEditPatient = true;
    this.editPatient = this.user.findLinkedPatient(this.consultData['patientInfo']['uhid'])
    this.tempChargeData = chargeData;
  }

  public closeEditPatientModal(event) {
    this.showEditPatient = false;
    this.editPatient = null;
    if (event?.patientId) {
      this.getHisPracCharges(false, null)
    } else {
      this.bindChargeData(_.cloneDeep(this.tempChargeData));
    }
    this.tempChargeData = null
  }
}
