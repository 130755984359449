import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { UserService } from './user.service';
import {groupBy as _groupBy} from 'lodash';
import { catchError, defaultIfEmpty } from 'rxjs/operators';
import { PartitionerDetailsPipe } from '../pipes/partitioner-details.pipe';
import { TranslatePipe } from '../pipes/translate.pipe';
import { FacilityDetailsPipe } from '../pipes/facility-details.pipe';
@Injectable({
  providedIn: 'root'
})
export class HomeCareService {

  private subject = new Subject<any>();
  public apptSub = new Subject<any>();
  public personData = {};
  private apptInfo: Array<any>;
  public availableServices;
  public location;
  public call_option;
  private facilities: Array<any> = [];
  private isOptionMapped: boolean = false;
  public home;
  constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient, private user: UserService, private practitionerDetailsPipe: PartitionerDetailsPipe, private translatePipe: TranslatePipe, private facilityDetailsPipe: FacilityDetailsPipe) {
    this.user.getUpdateInfo('facilities').subscribe(data => {
      this.facilities = data.facilities.map(fac=>fac.facilityId);
    })
    this.apptInfo = [];
    let localData = localStorage.getItem('HOMECAREMOCKJSON')
    if (localData) {
      this.apptInfo = JSON.parse(localData);
    }
  }


  public getHomecareOptionsOld() {
    let homecareConfig = this.user.getHomecareOptions()
    if (homecareConfig && !this.isOptionMapped) {
      const options = ['title', 'sub_title', 'icon', 'options', 'enable', 'number'];
      Object.keys(homecareConfig).forEach(key => {
        let index = this.availableServices.findIndex(service => {
          return service.action == key;
        })
        if (index > -1) {
          options.forEach(opt => {
            if (homecareConfig[key][opt]) {
              if (opt == 'title' || opt == 'sub_title')
                this.availableServices[index][opt] = this.translatePipe.transform(homecareConfig[key][opt]);
              if (opt == 'enable')
                this.availableServices[index][opt] = homecareConfig[key][opt] == 'true';
              else
                this.availableServices[index][opt] = homecareConfig[key][opt];
            }
          })
        }
      })
      if (homecareConfig.available_locations) {
        options.forEach(opt => {
          if (homecareConfig['available_locations'][opt]) {
            if (opt == 'title' || opt == 'sub_title')
              this.location[opt] = this.translatePipe.transform(homecareConfig['available_locations'][opt]);
            if (opt == 'enable')
              this.location[opt] = homecareConfig['available_locations'][opt] == 'true';
            else
              this.location[opt] = homecareConfig['available_locations'][opt];
          }
        })
      }
      if (homecareConfig.call_option) {
        options.forEach(opt => {
          if (homecareConfig['call_option'][opt]) {
            if (opt == 'title' || opt == 'sub_title')
              this.call_option[opt] = this.translatePipe.transform(homecareConfig['call_option'][opt]);
            if (opt == 'enable')
              this.call_option[opt] = homecareConfig['call_option'][opt] == 'true';
            else
              this.call_option[opt] = homecareConfig['call_option'][opt];
          }
        })
      }
      this.isOptionMapped = true;
    }
    return { availableServices: this.availableServices, location: this.location, call_option: this.call_option}
  }

  public getAvailableServices(): Observable<any>{
    return new Observable(observer => {
      let payload = {
        personId: this.user.getPersonId()
      }
      let getHomeCareInfo = this.http.post(`${environment['API_URL']}api/persons/getHomeCareInfo`, payload, { reportProgress: true }).pipe(catchError(error => of(error)));
      let getHCNursingServiceAndCharge = this.http.post(`${environment['API_URL']}api/persons/getHCNursingServiceAndCharge`, payload, { reportProgress: true }).pipe(catchError(error => of(error)));
      let getHCLabServiceAndCharge = this.http.post(`${environment['API_URL']}api/persons/getHCLabServiceAndCharge`, payload, { reportProgress: true }).pipe(catchError(error => of(error)));

      forkJoin(getHomeCareInfo, getHCNursingServiceAndCharge, getHCLabServiceAndCharge).pipe(defaultIfEmpty([{data:[]}, {data:[]}, {data:[]}])).subscribe(([getHomeCareInfoRes, getHCNursingServiceAndChargeRes, getHCLabServiceAndChargeRes]) => {
        if (!getHomeCareInfoRes || !getHomeCareInfoRes['data']) getHomeCareInfoRes = {data:[]};
        if (!getHCNursingServiceAndChargeRes || !getHCNursingServiceAndChargeRes['data']) getHCNursingServiceAndChargeRes = { data: []};
        if (!getHCLabServiceAndChargeRes || !getHCLabServiceAndChargeRes['data']) getHCLabServiceAndChargeRes = { data: [] };
      
        let getHomeCareInfoValue = getHomeCareInfoRes['data'].map(d => {
          return {
            title: d.practitionerName || this.translatePipe.transform(this.practitionerDetailsPipe.transform(d.practitionerId, d.facilityId, 'resourceName'), 'LOCALE', 'name'),
            subtitle: d.practitionerDesignation,
            cost: d.patientPayable,
            img: d.imgUrl || 'assets/icons/doctor_blue.svg',
            specialityId: d.specialityCode,
            specialityName: d.specialityDesc
          }
        })
        let getHCNursingServiceAndChargeValue = getHCNursingServiceAndChargeRes['data'].map(d => {
          return {
            title: d.serviceName,
            cost: d.patientPayable || d.charge,
            serviceGroupCode: d.serviceGroupCode,
            serviceGroupName: d.serviceGroupName,
          }
        })
        let getHCLabServiceAndChargeValue:any = getHCLabServiceAndChargeRes['data'].map(d => {
          return {
            title: d.serviceName || d.code,
            cost: d.patientPayable || d.charge,
            serviceGroupCode: d.serviceGroupCode,
            serviceGroupName: d.serviceGroupName,
          }
        })

        let getHomeCareInfoGroup = _groupBy(getHomeCareInfoValue, 'specialityId');
        let getHCNursingServiceAndChargeGroup = _groupBy(getHCNursingServiceAndChargeValue, 'serviceGroupCode');
        let getHCLabServiceAndChargeGroup = _groupBy(getHCLabServiceAndChargeValue, 'serviceGroupCode');
        
        this.availableServices[0]['options'] = Object.keys(getHomeCareInfoGroup).map(g => {
          return {
            group: getHomeCareInfoGroup[g][0]['specialityName'],
            list: getHomeCareInfoGroup[g]
          }
        })
        this.availableServices[1]['options'] = Object.keys(getHCNursingServiceAndChargeGroup).map(g => {
          return {
            group: getHCNursingServiceAndChargeGroup[g][0]['serviceGroupName'],
            list: getHCNursingServiceAndChargeGroup[g]
          }
        })
        // this.availableServices[2]['options'] = getHCLabServiceAndChargeValue
        this.availableServices[2]['options'] = Object.keys(getHCLabServiceAndChargeGroup).map(g => {
          return {
            group: getHCLabServiceAndChargeGroup[g][0]['serviceGroupName'],
            list: getHCLabServiceAndChargeGroup[g]
          }
        })

        observer.next(this.availableServices)
      })
    })
  }

  public getAvailableLocations(): Observable<any> {
    return new Observable(observer => {
      let payload = {
        personId: this.user.getPersonId(),
        facilities: this.facilities,
        locale: this.user.currentLanguage
      }
      this.http.post(`${environment['API_URL']}api/persons/getHCAvailableLocation`, payload, { reportProgress: true }).subscribe(data => {
        let value = data['data'].map(d => {
          return {
            title: d.townDesc,
            locationCode: d.areaCode,
            locationName: d.areaDesc,
          }
        })
        let group = _groupBy(value, 'locationCode');
        let location = {}
        location['options'] = Object.keys(group).map(g => {
          return {
            group: group[g][0]['locationName'],
            list: group[g]
          }
        })
        observer.next(location);
      })
    })
  }

  sendData(message: any) {
    console.log("sendData message", message);
    this.personData = message;
    this.subject.next(message);
  }

  clearData() {
    this.subject.next(null);
  }

  getData(): Observable<any> {
    return this.subject.asObservable();
  }

  public getServices(): Observable<Object> {
    return Observable.create(observer => {
      observer.next(null);
    });
  }

  public saveLocalData(bookingInfo, leadInfo) {
    console.log(bookingInfo);
    let finalArray = [];
    let services = bookingInfo['service']['value'].map(s => {
      return { serviceId: s['value'], serviceName: s['name'] }
    })
    let store = {
      apptTypeName: bookingInfo['apptType']['value']['name'],
      apptTypeId: bookingInfo['apptType']['value']['value'],
      billingDetails: {
        category: bookingInfo['billingCategory']['value'],
        group: bookingInfo['billingGroup']['value'],
      },
      careProviderName: bookingInfo['careprovider']['value']['name'],
      careProviderId: bookingInfo['careprovider']['value']['value'],
      services: services,
      serviceGroupName: bookingInfo['serviceGroup']['value']['name'],
      serviceGroupId: bookingInfo['serviceGroup']['value']['value'],
      serviceTypeName: bookingInfo['serviceType']['value']['name'],
      serviceTypeId: bookingInfo['serviceType']['value']['value'],
      serviceUnitName: bookingInfo['serviceUnit']['value']['name'],
      serviceUnitId: bookingInfo['serviceUnit']['value']['value'],
      serviceLocation: bookingInfo['serviceLocation']['value']['address'],
      serviceLatLng: bookingInfo['serviceLocation']['value']['latlng'],
      patientId: leadInfo['patientId'],
      patientName: leadInfo['patientName'],
      duration: bookingInfo['apptdttm']['value']['duration']
    }
    //Temporary
    store['apptCategory'] = 'HC';
    store['apptStatus'] = 'UPCOMING';
    let startDate = bookingInfo['apptdttm']['value']['start_date'].format('DD/MM/YYYY');
    let startTime = bookingInfo['apptdttm']['value']['start_time'].format('HH:mm');
    let endTime = bookingInfo['apptdttm']['value']['end_time'].format('HH:mm');
    if (bookingInfo['isRepeatAppt']) {
      let date = bookingInfo['apptdttm']['value']['start_date'].clone();
      let weekdays = bookingInfo['repeatappt']['value'].map(v => v['isoWeekday']);
      let days = bookingInfo['repeat']['value']['count'];
      while (weekdays.length > 0 && days > 0) {
        if (weekdays.indexOf(date.isoWeekday()) != -1) {
          let calcDate = date.format('DD/MM/YYYY');
          let starting = calcDate + ' ' + startTime;
          let ending = calcDate + ' ' + endTime;
          let format = 'DD/MM/YYYY' + ' ' + 'HH:mm';
          let finalObj = {
            ...store,
            startAt: this.user.getMoment(starting, format).format(),
            endAt: this.user.getMoment(ending, format).format()
          }
          console.log(finalObj);
          finalArray.push(finalObj);
          days -= 1;
        }
        date = date.add(1, 'days');
      }
    } else {
      let starting = startDate + ' ' + startTime;
      let ending = startDate + ' ' + endTime;
      let format = 'DD/MM/YYYY' + ' ' + 'HH:mm';
      let finalObj = {
        ...store,
        startAt: this.user.getMoment(starting, format).format(),
        endAt: this.user.getMoment(ending, format).format()
      }
      finalArray.push(finalObj);
    }
    this.apptInfo = [...this.apptInfo, ...finalArray];
    localStorage.setItem('HOMECAREMOCKJSON', JSON.stringify(this.apptInfo));
    console.log(JSON.stringify(this.apptInfo));
    this.apptSub.next(true);
    // this.router.navigate(['leads', leadInfo['patientId'], 'patientcalendar'], { relativeTo: this.route });
  }

  public getPatientAppointment(patientId):Observable<any> {
    // let localData = localStorage.getItem('HOMECAREMOCKJSON')
    // if (localData) {
    //   this.apptInfo = JSON.parse(localData);
    // }
    // return this.apptInfo.filter(info => patientId.indexOf(info.patientId) !== -1 && this.user.getMoment(info.startAt).format('DD-MM-YYYY') == this.user.getMoment().format('DD-MM-YYYY')).map(info => {
    //   return {
    //     title: info['services'][0]['serviceName'],
    //     start: info['startAt'],
    //     end: info['endAt'],
    //     service: info['services'][0]['serviceId'],
    //     apptType: info['apptCategory'],
    //     careProviderName: info['careProviderName'],
    //     patientId: info['patientId'],
    //     patientName: info['patientName'],
    //     duration: info['duration'],
    //   }
    // })
    let payload = {
      patientIds: patientId,
    }
    return Observable.create(oberserver => {
      this.http.post(environment['API_URL'] + 'api/staticContent/searchAppointments', payload, { reportProgress: true }).subscribe(result => {
        let res = result['data'].map(info => {
          return {
            title: info['services'][0]['serviceName'],
            start: info['startAt'],
            end: info['endAt'],
            service: info['services'][0]['serviceId'],
            apptType: info['apptCategory'],
            careProviderName: info['careProviderName'],
            patientId: info['patientId'],
            patientName: info['patientName'],
            duration: info['duration'],
          }
        })
        oberserver.next(res);
      })
    })
  }
  public getToadayAppt() {
    let appt = this.apptInfo.filter(a => this.user.getMoment().isSame(a['startAt'], 'd'));
    appt = appt.sort((a, b) => a['startAt'] - b['startAt']);
    return appt;
  }

  public getHomecareOptions() {
    let homecareConfig = this.user.getHomecareOptions()
    console.log("==== getHomecareOptions ===>", homecareConfig)
 
    if (homecareConfig && !this.isOptionMapped) {
      const options = ['title', 'sub_title', 'icon', 'options', 'enable', 'number'];
      Object.keys(homecareConfig).forEach(key => {
        let attr = homecareConfig[key]
        if(key == 'services'){
          this.availableServices = this.addIndex(attr);
        }else if(key == 'location'){
          attr['options'] = []
          this.location = this.iterateObjects(attr)
        }else if(key == 'call_option'){
          attr['options'] = []
          this.call_option = this.iterateObjects(attr)
        }else if(key == 'home'){
          this.home = attr
        }
      })
      this.isOptionMapped = true;
    }

    return { availableServices: this.availableServices, location: this.location, call_option: this.call_option, home: this.home}
  }

  public iterateObjects(obj){
    Object.keys(obj).forEach(item => {
      if(item == 'enable')
        obj[item] = obj[item] == 'true';
    })
    return obj;
  }

  public addIndex(service){
    service.map((element, index) => {
      element['_id'] = index
      element['options'] = []
    })
    return service;
  }

  public getAvailableDoctors(param): Observable<any>{
    return new Observable(observer => {
      let payload = {
        personId: this.user.getPersonId(),
        locale: this.user.currentLanguage,
        config: param.config
      }

      this.http.post(`${environment['API_URL']}api/persons/getHomeCareInfo`, payload, { reportProgress: true }).subscribe(data => {
        let getHomeCareInfoValue = data['data'].map(d => {
          return {
            title: d.practitionerName || this.translatePipe.transform(this.practitionerDetailsPipe.transform(d.practitionerId, d.facilityId, 'resourceName'), 'LOCALE', 'name'),
            subtitle: d.practitionerDesignation,
            cost: d.patientPayable,
            img: d.imgUrl || 'assets/icons/doctor_blue.svg',
            specialityId: d.specialityCode,
            specialityName: d.specialityDesc
          }
        })

        let getHomeCareInfoGroup = _groupBy(getHomeCareInfoValue, 'specialityId');

        let index = this.availableServices.findIndex(service => {
          return service._id == param._id;
        })

        this.availableServices[index]['options'] = Object.keys(getHomeCareInfoGroup).map(g => {
          return {
            group: getHomeCareInfoGroup[g][0]['specialityName'],
            list: getHomeCareInfoGroup[g]
          }
        })

        observer.next(this.availableServices)
      })
    })
  }


  public getAvailableCareServices(param): Observable<any>{
    return new Observable(observer => {
      let payload = {
        personId: this.user.getPersonId(),
        locale: this.user.currentLanguage,
        careServiceCode: param.careservicecode,
        config: param.config
      }
      this.http.post(`${environment['API_URL']}api/persons/getHCLabServiceAndCharge`, payload, { reportProgress: true }).subscribe(data => {

        let getHCLabServiceAndChargeValue:any = data['data'].map(d => {
          return {
            title: d.serviceName || d.code,
            cost: d.patientPayable || d.charge,
            serviceGroupCode: d.serviceGroupCode,
            serviceGroupName: d.serviceGroupName,
          }
        })

        let getHCLabServiceAndChargeGroup = _groupBy(getHCLabServiceAndChargeValue, 'serviceGroupCode');
        
        let index = this.availableServices.findIndex(service => {
          return service._id == param._id;
        })

        this.availableServices[index]['options'] = Object.keys(getHCLabServiceAndChargeGroup).map(g => {
          return {
            group: getHCLabServiceAndChargeGroup[g][0]['serviceGroupName'],
            list: getHCLabServiceAndChargeGroup[g]
          }
        })

        observer.next(this.availableServices)
      })

    })
  }

  public getTitle(names){
    if(Array.isArray(names)){
      if (names.length == 0) {
          return '';
      } else {
          let t = names.find(name => {
              return name.locale == this.user.currentLanguage
          })
          if (t) {
              return t.label;
          } else {
              return names[0].label;
          }
      }
    }else{
      return names;
    }
  }

}
