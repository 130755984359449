<!-- <div *ngIf="!registerPatient && !linkWithoutRelation" id="manage-identity-modal-parent" class="manage-identity-wrapper">
  <div class="header-modal border-bottom">
    <div class="title bold">{{'manage_identities'|translate:'LABELS'}}</div>
    <div (click)="closeModal('manage-identity-modal')" class="float-right closeicon pointer">
      <img class="type-image" src="./assets/icons/close.svg">
    </div>
  </div>

  <div class="modal-content adjust-height">
    <div class="header" *ngIf="linkedPatients.length != 0" [appRemoveFeatureOff]="'FT_APP_REGISTER_UNLINK_MEMBER'">
      <div class="title-black bold">{{'linked_records'|translate:'LABELS'}}</div>
      <div *ngIf='!manageLinkedRecords' (click)="manageLinkRecord()"
        class="float-right closeicon pointer title title-case">
        {{'btn_settings'|translate:'LABELS'}}</div>
      <div *ngIf='manageLinkedRecords' (click)="cancel()" class="float-right closeicon pointer title">
        <img src="assets/icons/back.svg" class="back-img type-image"/>
      </div>
    </div>
    <div class="content">
      <div class="card-layout" *ngFor="let item of linkedPatients">
        <div class="card extend-card">
          <div class="card-header extend-card">
            <div class="header-content">
              <div>
                <div class="image avathar-div">
                  <app-person-avatar [url]="item.imageurl"> </app-person-avatar>
                </div>
              </div>
              <div class="details">
                <p class="title">{{item.personname}}</p>
                <p class="sub-title">{{item.relationshiptype|translate:'RELATION'}}</p>
              </div>
              <button class="secondary-button btn-icon_secondary" [appRemoveFeatureOff]="'FT_APP_EDIT_LINK_MEMBER'"
                (click)='editPatient(item); $event.stopPropagation()'>
                <img class="type-image" src="assets/icons/signin/pen.svg" />
              </button>
            </div>
            <div class="link-icon" *ngIf='manageLinkedRecords'>
              <button class="btn-icon" (click)='removeLinkedPatient(item)'
                [appRemoveFeatureOff]="'FT_APP_REGISTER_UNLINK_MEMBER'">
                <img class="type-image" src="assets/icons/calendar/delete.svg" />
              </button>
            </div>
          </div>
          <div class="card-content extend-card">
            <p class="sub-title-black">
              {{item.facilityid | facilityDetails : 'facilityName' | translate : 'LOCALE': 'name'}}<br>
              <span class="sub-title">{{item.uhid}}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf='!manageLinkedRecords'>
      <div class="header" *ngIf="recordsToLink.length != 0">
        <div class="title-black bold">{{'new_records_to_link'|translate:'LABELS'}}</div>
      </div>

      <div class="content">
        <div class="card-layout" *ngFor="let item of recordsToLink; let i=index " >
          <div class="card cursor-pointer" >
            <div class="card-header" (click)="getRelation(item)">
              <div class="header-content">
                <div>
                  <div class="image">
                    <app-person-avatar [url]="item.imageurl"> </app-person-avatar>
                  </div>
                </div>
                <div>
                  <p class="title">{{item.patientName}}</p>
                  <p>
                    <span class="sub-title" *ngIf="false">{{'added_on'|translate:'LABELS'}} - </span>
                    <span class="sub-title-bold">4 Mar 2020</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="card-content" (click)="getRelation(item)" >
              <p class="sub-title-black">
                {{item.facilityId | facilityDetails : 'facilityName' | translate : 'LOCALE': 'name'}}
                <br>
                <span class="sub-title">{{item.patientUhid}}</span>
              </p>
            </div>
            <div class="dropdown-content" *ngIf='itemId === item.patientUhid'
              (click)="selectRelation(item)" [appRemoveFeatureOff]="'FT_APP_REGISTER_LINK_MEMBER'">
              <div class="flex">

              <p class=""><img class="type-image" src="assets/icons/agreement.svg"> <span
                  class="padding sub-title">{{'mng_label_relationship'|translate:'LABELS'}} </span>
                  <br>
                  <span class="title-black">{{relationArray[item.patientUhid]?(relationArray[item.patientUhid]['value']|translate:'LOCALE':'name'):("msg_relatedalert"|translate:'LABELS')}}
                  </span><img class="type-image" src="assets/icons/calendar/drop.svg">
                </p>

                  <button class="secondary-button btn-icon_primary" [disabled]="!relationArray[item.patientUhid]"
                        (click)='toLinkPatients(item); $event.stopPropagation()'>
                        <img class="type-image" src="assets/icons/link_white.svg" />
                      </button>
                    </div>
              <div>
                <p class="title-black">
                  {{relationArray[item.patientUhid]?(relationArray[item.patientUhid]['value']|translate:'LOCALE':'name'):("msg_relatedalert"|translate:'LABELS')}}
                  <span class="align-right">
                    <img class="image" src="assets/icons/calendar/drop.svg">
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div *ngIf='itemId === item.patientUhid && selectRelations' (mouseenter)="selectRelations=true"
            (mouseleave)="selectRelations=false; getRelations=false">
            <div *ngIf='itemId === item.patientUhid && selectRelations' (mouseenter)="selectRelations=true"
            (mouseleave)="selectRelations=false">
            <div *ngIf='itemId === item.patientUhid && selectRelations'>
            <div class="actions">
              <div class="border-bottom title-black" *ngFor='let type of relations'>
                <label class="container"
                  (click)="setRelation(item.patientUhid,type)">{{type.value|translate:'LOCALE':'name'}}
                  <input type="radio" name="radio">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="header" *ngIf="otherOptions && otherOptions.length > 0">
        <div class="title-black bold">{{'other_actions'|translate:'LABELS'}}</div>
      </div>

      <div class="content">
        <ng-container *ngFor="let item of otherOptions">
          <div class="card-layout cursor-pointer other-actions"
            [appRemoveFeatureOff]="item.display">
            <div class="card extend-card" (click)="openModal(item.modal_id)">
              <div class="card-header no-padding justify">
                <div class="link-icon link-icon1 center card-content">
                  <button class="secondary-button btn-icon"><img class="type-image" src={{item.icon}}></button>
                </div>
                <div class="header-content padding">
                  <div>
                    <p class="primary-title bold">{{item.title|translate:'LABELS'}}</p>
                    <p class="sub-title ">{{item.subtitle|translate:'LABELS'}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

</div>

<div id="manage-identity-modal-parent" *ngIf="linkWithoutRelation">
  <div class="header-modal border-bottom">
    <div class="title bold">{{'manage_identities'|translate:'LABELS'}}</div>
    <div (click)="closeModal('manage-identity-modal')" class="float-right closeicon pointer">
      <img class="type-image" src="./assets/icons/close.svg">
    </div>
  </div>
  <div class="modal-content adjust-height">
    <div id="content-section">
      <div class="my-identity identity-group">
        <div class="sub-header">
          <div class="sub-title">{{'my_identity'|translate:'LABELS'}}</div>
        </div>
        <div class="card-elements">
          <div class="card-element" *ngFor="let patient of linkedPatients | identity : 'MY'">
            <div class="card">
              <div class="row">
                <div class="image">
                  <app-person-avatar [url]="patient.imageurl"></app-person-avatar>
                </div>
                <div class="name"> {{patient.personname}} </div>
                <div class="more">
                  <div class="more-button" appPopover host="choose-option" (click)="openPopover(patient, true, false, false, true)">
                    <img src="./assets/icons/more.svg" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="nationalid" [ngClass]="{hide: !patient.nationalid}">
                  <span class="key">{{'national_id' | translate}}</span>
                  <span class="value"> {{patient.nationalid}}</span>
                </div>
                <div class="uhid">
                  <img src="./assets/icons/id-card-blue.svg" /> {{patient.uhid}}
                </div>
              </div>
              
            </div>
          </div>
          <div class="no-identity" *ngIf="(linkedPatients | identity : 'MY').length == 0">
            {{'no_identity_available' | translate}}
          </div>
        </div>
      </div>
      <div class="fam-identity identity-group">
        <div class="sub-header">
          <div class="sub-title">{{'my_family'|translate:'LABELS'}}</div>
          <div class="add-new-member" (click)="openModal('find-record')">
            <img src="./assets/icons/add_plain.svg" /> {{'Add_a_family_member' | translate}}
          </div>
        </div>
        <div class="card-elements">
          <div class="card-element" *ngFor="let patient of linkedPatients | identity : 'FAM'">
            <div class="card">
              <div class="row">
                <div class="image">
                  <app-person-avatar [url]="patient.imageurl"></app-person-avatar>
                </div>
                <div class="name"> {{patient.personname}} </div>
                <div class="more">
                  <div class="more-button" appPopover host="choose-option" (click)="openPopover(patient, true, true, false, true)">
                    <img src="./assets/icons/more.svg" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="nationalid" [ngClass]="{hide: !patient.nationalid}">
                  <span class="key">{{'national_id' | translate}}</span>
                  <span class="value"> {{patient.nationalid}}</span>
                </div>
                <div class="uhid">
                  <img src="./assets/icons/id-card-blue.svg" /> {{patient.uhid}}
                </div>
              </div>
              
            </div>
          </div>
          <div class="no-identity" *ngIf="(linkedPatients | identity : 'FAM').length == 0">
            {{'no_identity_available' | translate}}
          </div>
        </div>
      </div>
      <div class="fam-tolink identity-group" *ngIf="recordsToLink && recordsToLink.length>0"
        [appRemoveFeatureOff]="'FT_APP_HOME_VIEW_EXIST_UNLINKED'">
        <div class="sub-header">
          <div class="sub-title">{{'My_family_to_link' | translate}}</div>
        </div>
        <div class="card-elements">
          <div class="card-element" *ngFor="let patient of recordsToLink">
            <div class="card">
              <div class="row">
                <div class="image">
                  <app-person-avatar [url]="patient.imageurl"></app-person-avatar>
                </div>
                <div class="name"> {{patient.patientName}} </div>
                <div class="more">
                  <div class="more-button" appPopover host="choose-option" (click)="openPopover(patient, false, false, true, true)">
                    <img src="./assets/icons/more.svg" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="nationalid" [ngClass]="{hide: !patient.nationalid}">
                  <span class="key">{{'national_id' | translate}}</span>
                  <span class="value"> {{patient.nationalid}}</span>
                </div>
                <div class="uhid">
                  <img src="./assets/icons/id-card-blue.svg" /> {{patient.patientUhid}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fam-linked-me identity-group" *ngIf="selfLinked && selfLinked.length>0">
        <div class="sub-header">
          <div class="sub-title">{{'members_with_my_access' | translate}}</div>
        </div>
        <div class="card-elements">
          <div class="card-element" *ngFor="let patient of selfLinked">
            <div class="card">
              <div class="row">
                <div class="image">
                  <app-person-avatar [url]="patient.imageurl"></app-person-avatar>
                </div>
                <div class="name"> {{patient.personname}} </div>
                <div class="more">
                  <div class="more-button" appPopover host="choose-option" (click)="openPopover(patient, false, false, false, false)">
                    <img src="./assets/icons/more.svg" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="nationalid" [ngClass]="{hide: !patient.nationalid}">
                  <span class="key">{{'national_id' | translate}}</span>
                  <span class="value"> {{patient.nationalid}}</span>
                </div>
                <div class="uhid">
                  <img src="./assets/icons/id-card-blue.svg" /> {{patient.uhid}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="popover" id="choose-option">
  <div class="options">
    <ul *ngIf="popoverpatient">
      <li (click)="chooseOption('PHOTO')" *ngIf="popoverpatient.enablePhoto">
        <label>
          <input type="file" name="patient-photo" id="patient-photo" size="chars"
            accept="image/png, image/gif, image/jpeg" (change)="uploadPatientPhoto($event.target.files)">
          <img src="./assets/icons/dslr-camera.svg" /> {{'btn_change_picture'|translate}}
        </label>
      </li>
      <li (click)="chooseOption('UNLINK')" *ngIf="popoverpatient.enableUnlink">
        <img src="./assets/icons/unlink.svg" /> {{'btn_remove_link'|translate}}
      </li>
      <li (click)="chooseOption('UNLINKMYACCESS')" *ngIf="popoverpatient.unlinkMyAccess">
        <img src="./assets/icons/unlink.svg" /> {{'btn_remove_link'|translate}}
      </li>
      <li (click)="chooseOption('LINK')" *ngIf="popoverpatient.enableLink">
        <img src="./assets/icons/link.svg" /> {{'btn_link'|translate}}
      </li>
    </ul>
  </div>
</div>
<app-register-patient *ngIf=registerPatient [editDetails]="editDetails" [isEdit]="isEdit"
  (close)="closeModal('register-patient')">
</app-register-patient> -->

<div *ngIf="!registerPatient && !linkWithoutRelation" id="manage-identity-modal-parent" class="manage-identity-wrapper">

  <div class="header-modal border-bottom">
    <div class="title bold">{{'My_family'|translate:'LABELS'}}</div>
    <div (click)="closeModal('manage-identity-modal')" class="float-right closeicon pointer">
      <img class="type-image" src="./assets/icons/close_black.svg">
    </div>
  </div>


  <div class="main-content">
    <div class="main-content-wrapper">
      <div class="content-details" *ngFor="let item of linkedPatients;let index=index">

        <div class="more-user-details"  *ngIf="item['_openOptions']" (clickOutside)="item['_openOptions']=false" [triggerElementId]="'linked-patients-'+index">

          <div class="card1 row cursor-pointer" (click)="editPatient(item)">
            <div class="image">
              <img src="./assets/icons/health-package/edit.svg">
            </div>
            <div class="card-content">
              <div class="title" >
                {{ "Edit_patient" | translate}}
              </div>
            </div>
          </div>

          <div class="card1 row cursor-pointer" (click)="changePhoto()" >
            <div class="image">
              <img src="./assets/icons/manage-account/photo.svg">
            </div>
            <div class="card-content">
              <div class="title" >
               {{ "Update_photo" | translate}}
              </div>
            </div>
          </div>

          <div class="card1 row cursor-pointer"  (click)="updatedRelationship(item)">
            <div class="image">
              <img src="./assets/icons/manage-account/relationship.svg">
            </div>
            <div class="card-content">
              <div class="title" >
                {{ "Update_relationship" | translate}}
              </div>
            </div>
          </div>

          <!-- manage insurance is commented -->
          <!-- <div class="card1 row cursor-pointer">
            <div class="image">
              <img src="./assets/icons/manage-account/manage_insurance.svg">
            </div>
            <div class="card-content">
              <div class="title" >
                {{ "Manage_my_insurance" | translate}}
              </div>
            </div>
          </div> -->

          <div class="card1 row cursor-pointer" (click)="chooseOption('UNLINK')">
            <div class="image">
              <img src="./assets/icons/manage-account/remove.svg">
            </div>
            <div class="card-content">
              <div class="remove-title" >
                {{ "Remove_patient" | translate}}
              </div>
            </div>
          </div>
        </div>
        
        <div class="content-details-title">
          <div class="card-details">
            <div class="person-avatar-wrapper">
              <app-person-avatar [url]="item.imageurl" [name]="item.personname">
              </app-person-avatar>
            </div>
            <div class="content-user-name">
              <span>{{item.personname}}</span>
              <span class="user-details">
                {{item.gender}},
                {{item.dob | age}},
                {{item.relationshiptype|translate:'RELATION'}},
                {{item.uhid}}
              </span>
            </div>

            <div class="more-icon cursor-pointer" [id]="'linked-patients-'+index" (click)="triggerMoreOptions(item);$event.stopPropagation()">
              <span>
                <img class="type-image" src="./assets/icons/manage-account/More-icon.svg">
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="add-a-member-options" *ngIf="addMemberModal" id="choose-add-member-option" (clickOutside)="addMemberModal=false" [triggerElementId]="'add-member-manage-patients'">
      <div class="card1 row cursor-pointer">
        <div class="image">
          <img src="./assets/icons/manage-account/add_user.svg">
        </div>
        <div class="card-content" [appRemoveFeatureOff]="'FT_APP_REGISTER_FAMILY_MEMBER'" (click)="openModal('register-patient')">
          <div class="title">
            {{ "Register_a_patient" | translate}}
          </div>
        </div>
      </div>
      <div class="card1 row cursor-pointer" [appRemoveFeatureOff]="'FT_APP_FIND_FAMILY_MEMBER'" (click)="openModal('find-record');linkSameNumber = true">
        <div class="image">
          <img src="./assets/icons/manage-account/link.svg">
        </div>
        <div class="card-content">
          <div class="title">
            {{ "Link_patient(same_mobile_number)" | translate}}
          </div>
        </div>
      </div>
      <div class="card1 row cursor-pointer" [appRemoveFeatureOff]="'FT_APP_FIND_FAMILY_MEMBER'" (click)="openModal('find-record')">
        <div class="image">
          <img src="./assets/icons/manage-account/link.svg">
        </div>
        <div class="card-content">
          <div class="title">
            {{ "Link_patient(different_mobile_number)" | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer">
    <button class="add-member-btn" (click)="addMember();$event.stopPropagation()"  host="choose-add-member-option" id="add-member-manage-patients">
      <img src="./assets/icons/manage-account/add_plain.svg">
      {{'Add_a_member'|translate}}
    </button>
  </div>


  <!-- select relation type is commented -->

</div>

<app-register-new-patient *ngIf="registerPatient" [editDetails]="editDetails" [isEdit]="isEdit" (close)="closeModal('register-new-patient')"></app-register-new-patient>

<app-link-other-mobile *ngIf="findOtherPatient" (initLinkPatients)="initLinkPatients($event)"
  (initAddPatients)="initAddPatients($event)"
  (close)="closeModal('find-record')" [linkSameNumber]="linkSameNumber"
  [linkedPatients]="linkedPatients" [prefillNationalId]="prefillNationalId">
</app-link-other-mobile>
<app-update-relation-modal *ngIf="isUpdateRelation" [patient]="isUpdateRelation" (close)="closeUpdateRelation($event)"></app-update-relation-modal>