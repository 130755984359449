import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, combineLatest, forkJoin } from 'rxjs';
import { environment } from '@env/environment';
import { InterceptorService } from './interceptor.service';
import * as AppConstant from '../app.string';
import { UserService } from './user.service';
import { visitType } from '@type';
import { FeatureFlagsService } from './feature-flags.service';
import * as moment from 'moment';
import { APPTACTION, APPTFLAGS, APPTVIEWTYPE, CHECKINSTATUS, PERMISSIONSAVAILABLE } from '@type';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  private repoUrl: string;
  private UpcomingAppointments:any;
  public recentConsultatedPatient: any;
  public slotBlockInfo:any;

  constructor(private http: HttpClient, private user: UserService, private feature: FeatureFlagsService) {
    this.repoUrl = this.user.getRepoUrl();
  }

 /**
  * getAppointmentServiceCost
  */
 public getAppointmentServiceCost(data: any): Observable<any> {
  return Observable.create(observer => {
    this.http.post(`${this.repoUrl}api/schedules/getchargeforequip`, data, { reportProgress: false }).subscribe(success => {
      observer.next(success);
    }, error => {
      observer.error(this.getErrorMessage(error));
    })
  })
}

  public getUpcomingAppointment(_id: string): Observable<any> {
    let payload = {
      personId: _id
    }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_APPOINTMENT}/getupcoming`, payload, { reportProgress: true }).subscribe(success => {
        const parsedData = this.parseApptData(success['data'])
        this.setUpcomingAppointments(parsedData.appointments);
        this.callRecentConsultatedPatient()
        this.handleChargeDataForUpcomingAppointments(parsedData.appointments).subscribe(res => {
          success['data']['appointments'] = res
          observer.next(success);
        })
        // observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }
  public getRecentlyConsultedAppts(_id: string, limit?: any): Observable<any> {
    let payload;
    if(limit) {
      payload = {
        personId: _id,
        limit: limit
      }
    } else {
      payload = {
        personId: _id
      }
    }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_PERSONS}/getRecentPractitioners`, payload, { reportProgress: true }).subscribe(response => {
        observer.next(response['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }
  public getAppointmentDiscount(facilityId: string, partitionerId: string, visitType: visitType, apptRefNo?:string): Observable<any> {
    let payload = {
      practitionerId: partitionerId,
      facilityId: facilityId
    }
    if (visitType == 'VIDEO') {
      payload['visitType'] = 'VIDEO';
    }
    if (apptRefNo) {
      payload['apptRefNo'] = apptRefNo;
    }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_APPOINTMENT}/getConsultationDiscount`, payload, { reportProgress: true }).subscribe(response => {
        observer.next(response['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }
  public getErrorMessage(error) {
    if (typeof error === 'string')
      return error;
    if (error && error.error && error.error.error && error.error.error.message)
      return error.error.error.message;
    if (error.error && error.error.err && error.error.err.message) {
      return error.error.err.message
    } else if (error.error && error.error.message) {
      return error.error.message
    }
    return 'Something went wrong';
  }
  public startTransaction(data: any, request:any): Observable<any> {
    let payload = {
      personId: this.user.getPersonId(),
      ...data,
      request: {
        personId: this.user.getPersonId(),
        source: 'PXPORTAL',
        userAgent: navigator.userAgent,
        // person_id: this.user.getPersonId(),
        patientPayableAmount: data.amount, // Modify this condition if multiple request/amount is split for payment
        ...request
      }
    }
    console.log("startTransaction payment",payload)
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/payments/startTransaction`, payload, { reportProgress: true }).subscribe(response => {
        observer.next(response['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }
  public getVisitCharge(data:any): Observable<any> {
    let payload = data.map(d => {
      d.version = 'v1'
      return d;
    });
    return Observable.create(observer => {
      this.http.post(`${this.repoUrl}api/schedules/getchargeforappt`, payload).subscribe(response => {
        observer.next(response['data']);
        observer.complete()
      }, error => {
        observer.error(error);
      })
    })
  }
  public requestAppointment(data:any): Observable<any> {
    let payload = {
      ...data,
      personId: this.user.getPersonId(),
      personName: this.user.getPersonName(),
      mobile: this.user.getPersonMobile(),
      email: this.user.getPersonEmail()
    };
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_APPOINTMENT}/request`, payload, { reportProgress: true }).subscribe(response => {
        observer.next(response['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public getFormContent(data: any): Observable<any>{
    let payload = {
      ...data
    };
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/services/getFormContent`, payload, { reportProgress: true }).subscribe(response => {
        observer.next(response['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public checkInAppt(data: any): Observable<any>{
    let payload = {
      ...data,
      personId: this.user.getPersonId(),
    };
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_APPOINTMENT}/checkin`, payload, { reportProgress: true }).subscribe(response => {
        observer.next(response['data']);
        this.user.npsRatingSubject.next(true)
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }
  public initCancelPackage(data: any): Observable<any>{
    let payload = {
      ...data,
      personId: this.user.getPersonId(),
      localeCode: this.user.getCurrentLanguage()
    };
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_APPOINTMENT}/cancelWithPackage`, payload, { reportProgress: true }).subscribe(response => {
        observer.next(response['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }
  public initHCCancelAppt(data: any): Observable<any>{
    let payload = {
      ...data,
      personId: this.user.getPersonId(),
    };
    return Observable.create(observer => {
      this.http.post(`${this.repoUrl}api/outpatients/cancelHomeCareAppointment`, payload).subscribe(response => {
        observer.next(response['data']);
        observer.complete()
      }, error => {
        observer.error(error);
      })
    })
  }
  public cancelAppt(data: any): Observable<any>{
    let payload = {
      ...data,
      personId: this.user.getPersonId(),
    };
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_APPOINTMENT}/cancel`, payload, { reportProgress: true }).subscribe(response => {
        observer.next(response['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }
  public rescheduleAppointment(data: any): Observable<any>{
    let payload = {
      personId: this.user.getPersonId(),
      ...data
    }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_APPOINTMENT}/change`, payload, { reportProgress: true }).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }
  public saveFormContent(data: any): Observable<any>{
    let payload = {
      ...data
    }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/services/recordForm`, payload, { reportProgress: true }).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public getSavedCard(): Observable<any>{
    return Observable.create(observer => {
      this.http.get(`${environment['API_URL']}api/payments/getSavedCards`,  { reportProgress: true }).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public removeSavedCard(data: any): Observable<any>{
    let payload = {
      ...data
    }
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}api/payments/removeSavedCard`, payload, { reportProgress: true }).subscribe(success => {
        observer.next(success);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public confirmPackageRequest(data:any): Observable<any> {
    let payload = {
      ...data,
      personId: this.user.getPersonId()
    };
    return Observable.create(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_SERVICES}/request`, payload, { reportProgress: true }).subscribe(response => {
        observer.next(response);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public getPractListBasedOnNxtAvail(data:any): Observable<any> {
    let payload = data;
    return Observable.create(observer => {
      this.http.post(`${this.repoUrl}api/schedules/getPractListBasedOnNxtAvail`, payload).subscribe(response => {
        observer.next(response['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public getBookingRestrictions(patientId, appointmentType, flowType): Observable<any> {
    let payload = {
      patientId: patientId,
      appointmentType: appointmentType,
      flowType: flowType
    };
    return Observable.create(observer => {
      if (this.feature.featureOn('FT_APP_BOOKWITHRESTRICTIONS')) {
        this.http.post(`${this.repoUrl}api/schedules/getBookingRestrictions`, payload).subscribe(response => {
          observer.next(response['data']);
        }, error => {
          observer.next(null);
        })
      } else {
        observer.next(null)
      }
    })
  }
  public getQueue(data:any): Observable<any> {
    let payload = data;
    return Observable.create(observer => {
      this.http.post(`${this.repoUrl}api/patients/getQueueNo`, payload).subscribe(response => {
        observer.next(response['data']);
      }, error => {
        observer.error(this.getErrorMessage(error));
      })
    })
  }

  public verifyMerchant(payload): Observable<any> {
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}api/payments/verifyMerchant`, payload, { reportProgress: true }).subscribe((res) => {
        if (res['status'] == 'SUCCESS')
          return observer.next(res['data']);
        else {
          // this.getErrorMessage(res)
          observer.error(this.getErrorMessage(res));
        }
      }, error => {
        // this.getErrorMessage(error)
        observer.error(this.getErrorMessage(error))
      })
    })
  }

  public updateApplePayStatus(payload): Observable<any> {
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}api/payments/updateApplePayStatus`, payload, { reportProgress: true  }).subscribe((res) => {
        if (res['status'] == 'SUCCESS')
          return observer.next(res['data']);
        else {
          // this.getErrorMessage(res)
          observer.error(this.getErrorMessage(res));
        }
      }, error => {
        // this.getErrorMessage(error)
        observer.error(this.getErrorMessage(error))
      })
    })
  }

  public setUpcomingAppointments(params) {
    this.UpcomingAppointments = params;
    console.log(params);
  }
  public getUpcomingAppointmentsValue() {
    return this.UpcomingAppointments;
  }

  private parseApptData(appts: any) {
    appts.appointments = appts.appointments.map(appt => { 
      appt.serviceDetails = this.getClinicServiceDetails(appt);
      return appt;
    })
    return appts;
  }
  private getClinicServiceDetails(appt) {
    let totalServiceCost = 0;
    let totalDuration = 0;
    if (appt['resourceDetail'] && appt['resourceDetail']['servicesToOrder']) {
      let bookedServices = appt['resourceDetail']['servicesToOrder'].map(s => {
        let obj = {
          directiveCode: s['directiveCode'],
          directiveName: s['directiveDesc'],
        }
        let concept = s['concepts'].map(c => {
          totalServiceCost += Number(c['serviceCost']);
          let duration = c.serviceHm == 'M' ? Number(c.serviceTime) : (Number(c.serviceTime) * 60);
          totalDuration += duration;
          return {
            ...obj,
            conceptCode: c['code'],
            conceptName: c['desc'],
            localNames: c['localNames'],
            details: {
              servicetime: c['serviceTime'],
              servicetimehm: c['serviceHm'],
              servicecost: c['serviceCost']
            }
          }
        })
        return concept;
      })
      bookedServices = [].concat.apply([], bookedServices);
      let durStr;
      var maxServiceDuration;
      this.user.getConfigurationInfo.subscribe(data => {
        if(data['pxAppSettings']) {
          maxServiceDuration = data['pxAppSettings']['expressClinicMaxApptDur'];
        }
      })
      totalDuration = totalDuration < maxServiceDuration ? totalDuration : maxServiceDuration
      if (totalDuration > 60)
        durStr = moment.utc(moment.duration(totalDuration, "minutes").asMilliseconds()).format("HH [hours] mm [minutes]")
      else
        durStr = moment.utc(moment.duration(totalDuration, "minutes").asMilliseconds()).format("mm [minutes]")
      const serviceDetails = {
        isServiceAppt: true,
        totalServiceCost: totalServiceCost,
        totalDuration: totalDuration,
        durStr: durStr,
        bookedServices: bookedServices
      }
      return serviceDetails
    } else {
      const serviceDetails = {
        isServiceAppt: false
      }
      return serviceDetails
    }
  }

  public getConsultationDiscount(data): Observable<any> {
    
    let payload = data.map(d => {
      d.personId = this.user.getPersonId()
      return d;
    })
    // if(payload.length == 1)
    //   payload = payload[0];
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}api/appointments/getConsultationDiscount`, payload, { reportProgress: true }).subscribe(data => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
          observer.next([])
          observer.complete();
      })
    })
  }

  public getcheckApptOverlapForPatient(payload): Observable<any> {
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}api/appointments/checkApptOverlapForPatient`, payload, { reportProgress: true }).subscribe(data => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
          observer.next([])
          observer.complete();
      })
    })
  }

  public getCancelledAppointments(month, year): Observable<any> {
    let payload = {
      personId: this.user.getPersonId(),
      filter: 'MONTH',
      month: month,
      year: year
    }
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_APPOINTMENT}/getCancelledAppointments`, payload, { reportProgress: true }).subscribe(data => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
        observer.error([])
        observer.complete();
      })
    })
  }

  public handleChargeDataForUpcomingAppointments(appts:Array<any>, bgProcess:boolean = false): Observable<any>  {
    return new Observable(observer => {
      if (appts.length > 0) {
        let chargePayload = appts.map(appt => {
          let specObj = this.user.getSpecialityByPractId(appt.resourceId, appt.facilityId)
          return {
            apptDate: moment(appt.appointmentDateTime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD'),
            apptFromTime: moment(appt.appointmentDateTime, 'YYYY-MM-DD HH:mm').format('HH:mm'),
            apptRefNo: appt.appointmentId,
            clinicCode: appt.clinicCode,
            facilityId: appt.facilityId,
            patientId: appt.uhid,
            practitionerId: appt.resourceId,
            visitType: appt.appointmentType,
            sendErrorInResponse: true,
            isBackgroundProcess: bgProcess,
            checkOverrideParams: true,
            cancelAllowed: appt.cancelAllowed,
            changeAllowed: appt.changeAllowed,
            checkInAllowed: appt.checkInAllowed,
            isOnspot: appt.isOnspot,
            isPackage: appt.isPackage,
            specialityId: specObj ? specObj.specialityId : null
          }
        })
        let discountPayload = appts.map(appt => {
          return {
            apptDate: moment(appt.appointmentDateTime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD'),
            apptFromTime: moment(appt.appointmentDateTime, 'YYYY-MM-DD HH:mm').format('HH:mm'),
            apptRefNo: appt.appointmentId,
            clinicCode: appt.clinicCode,
            facilityId: appt.facilityId,
            patientId: appt.uhid,
            practitionerId: appt.resourceId,
            visitType: appt.appointmentType,
            isBackgroundProcess: bgProcess
          }
        });

        chargePayload = chargePayload.filter(appt => {
          if(appt && appt.visitType != 'HC'){
            return appt
          }
        })
        discountPayload = discountPayload.filter(appt => {
          if(appt && appt.visitType != 'HC'){
            return appt
          }
        })

        if((chargePayload && chargePayload.length >  0 )|| (discountPayload && discountPayload.length >  0 ) ){
        combineLatest([this.getVisitCharge(chargePayload), this.getConsultationDiscount(discountPayload)]).subscribe(([chargeResponse, discountResponse]) => {
          let mappedappts = appts.map(appt => {
            let discountData = discountResponse.find(res => res.apptRefNo == appt.appointmentId);
            let chargeData = chargeResponse.find(res => res.apptRefNo == appt.appointmentId);
            if (!chargeData || chargeData['status'] == 'ERROR')  {
              if(appt.appointmentType != 'HC'){
                  appt.errorFetchingCharge = true;
                  appt.errorMessage = chargeData['displayErrorMessage'] ? chargeData['displayErrorMessage']: null
              }
              return appt;
            }
            let chargeDetails: any = {
              consultationFee: Number(chargeData['consultationFee']) || 0,
              hospitalFee: Number(chargeData['hospitalFee']) || 0,
              totalAmount: Number(chargeData['totalAmount']),
              payableAmount: Number(chargeData['patientPayableAmount']),
              discountAmount: 0,
              netPayable: Number(chargeData['patientPayableAmount']),
              freeFollowUpYn: chargeData['freeFollowUpYn'],
              insuranceFinDtls: chargeData['insuranceFinDtls'],
              isInsurancePatient: !!chargeData['insuranceFinDtls'],
              billingGroupCode: chargeData['billingGroupCode'],
              promoDiscount: 0,
              couponDiscount: 0,
              isPromoApplied: false,
              isCouponApplied: false,
              isFreeFollowUp: false,
              couponTitle: [],
              disableCoupon: chargeData.disableCoupon,
              checkOverride: chargeData.checkOverride,
              overrideValues: chargeData.overrideValues,
              parsedCharge:chargeData.parsedCharge
            }
            if (discountData) {
              if (discountData.criteriaId == 'DISCOUNT-PERCENTAGE') {
                chargeDetails.discountPercentage = Number(discountData.criteriaValue);
                chargeDetails.discountAmount = (chargeDetails.payableAmount) * (chargeDetails.discountPercentage / 100);
                chargeDetails.netPayable = chargeDetails.payableAmount - chargeDetails.discountAmount;
              } else if (discountData.criteriaId == 'DISCOUNT-AMOUNT') {
                chargeDetails.discountAmount = Number(discountData.criteriaValue);
                chargeDetails.netPayable = chargeDetails.payableAmount - chargeDetails.discountAmount;
              }
            }
            console.log('chargeData');
            if (chargeData.discount && chargeData.discount.discountApplicable == 'Y')
            chargeDetails.discountDetails = {
                applicable: true,
                charge: chargeData['charge']
              }
            else 
              chargeDetails.discountDetails = {
                applicable: false,
                charge: null
              }

            appt.chargeDetails = chargeDetails;
            return appt;
          })
          observer.next(mappedappts);
        }, err => {
          console.log('Error:', err)
        },
          () => {
            console.log('Completed')
          })
        }else{
          observer.next(appts)
        }
      } else {
        observer.next([]);
      }
    })
  }

  public processScheduleFlag(selectedAppointment, viewType: APPTVIEWTYPE): APPTFLAGS {
    const rejoinFlag: PERMISSIONSAVAILABLE = selectedAppointment.isOnspot ? 'ENABLEONSPOTREJOINOUTERTIME' : 'ENABLEVCREJOINOUTERTIME'
    
    let canCheckInVC: boolean = false;
    let canCheckInIP: boolean = false;
    let actionType: APPTACTION = 'NONE';
    let checkinStatus: CHECKINSTATUS = 'UNKNOWN';
    let canRejoin: boolean = false;

    if (selectedAppointment.appointmentType == 'VIDEO') {
      checkinStatus = this.isCheckedIn(selectedAppointment.consultationStatus, 'VIDEO')
    } else {
      checkinStatus = this.isCheckedIn(selectedAppointment.checkedIn, 'HOSPITAL')
    }

    if (selectedAppointment.chargeDetails && selectedAppointment.chargeDetails.checkOverride) {
      let overrideParams = selectedAppointment.chargeDetails.overrideValues
      console.log('overrideParams', overrideParams)
      let checkInAllowed = overrideParams.checkInAllowed
      if (checkInAllowed === 'false') {
        selectedAppointment['checkInAllowed'] = 'false'
      } else if (checkInAllowed === 'true' && checkinStatus == 'YETTOCHECKIN') {
        selectedAppointment['checkInAllowed'] = 'true'
      }

      let cancelAllowed = overrideParams.cancelAllowed
      if (cancelAllowed === 'false') {
        selectedAppointment['cancelAllowed'] = 'false'
      } else if (cancelAllowed === 'true' && checkinStatus == 'YETTOCHECKIN') {
        selectedAppointment['cancelAllowed'] = 'true'
      }

      let changeAllowed = overrideParams.changeAllowed
      if (changeAllowed === 'false') {
        selectedAppointment['changeAllowed'] = 'false'
      } else if (changeAllowed === 'true' && checkinStatus == 'YETTOCHECKIN') {
        selectedAppointment['changeAllowed'] = 'true'
      }
    }
    console.log('selectedAppointment', selectedAppointment)
    const enableRejoinOuterTime = this.user.canViewOption(rejoinFlag);
    const canCancelAppt: boolean = this.checkForCancelAppt(selectedAppointment['cancelAllowed'], selectedAppointment.facilityId, selectedAppointment.appointmentDateTime, selectedAppointment.consultationStatus);
    const canRescheduleAppt: boolean = this.checkForRescheduleAppt(selectedAppointment['changeAllowed'], selectedAppointment.facilityId, selectedAppointment.appointmentDateTime, selectedAppointment.consultationStatus);


    if (selectedAppointment.appointmentType == 'VIDEO') {
      canCheckInVC = this.checkApptCheckinVC(selectedAppointment['checkInAllowed'], selectedAppointment.facilityId, selectedAppointment.appointmentDateTime, selectedAppointment.consultationStatus, selectedAppointment.isOnspot)
      actionType = this.checkAvailableActions(canCheckInVC, selectedAppointment, viewType == 'LISTCARD');
      canRejoin = enableRejoinOuterTime || this.canRejoin(selectedAppointment['checkInAllowed'], selectedAppointment.facilityId, selectedAppointment.appointmentDateTime, selectedAppointment.consultationStatus, selectedAppointment.isOnspot);
    } else {
      canCheckInIP = this.checkApptCheckinIP(selectedAppointment['checkInAllowed'], selectedAppointment.facilityId, selectedAppointment.appointmentDateTime, selectedAppointment.checkedIn)
      actionType = this.checkAvailableActions(canCheckInIP, selectedAppointment, false);
    }
    

    return {
      canCancelAppt: canCancelAppt,
      canRescheduleAppt: canRescheduleAppt,
      canCheckInVC: canCheckInVC,
      canCheckInIP: canCheckInIP,
      actionType: actionType,
      checkinStatus: checkinStatus,
      canRejoin: canRejoin
    }
  }

  public checkForCancelAppt(allowed, facilityId, appointmentDateTime, consultationStatus) {
    let returnVal = false;
    // if () {
    if (allowed == 'true' && (!consultationStatus || (consultationStatus && consultationStatus != 'FINISH' && consultationStatus != 'CANCEL'))) {
      let facility = this.user.getAttributeForListingType('facilities', true)['facilities'].find(fac => fac.facilityId == facilityId);
      if (facility) {
        if (facility['facilityControls'] && facility['facilityControls']['schedulingRules'] && facility['facilityControls']['schedulingRules']['cancelPolicy']) {
          let policy = facility['facilityControls']['schedulingRules']['cancelPolicy'];
          let dateTimeToCheck = moment(appointmentDateTime, 'YYYY-MM-DD HH:mm');
          returnVal = this.checkWithinPolicyTime(dateTimeToCheck, policy, facilityId);
        } else {
          returnVal = false;
        }
      }
    }
    return returnVal;
  }
  public checkForRescheduleAppt(allowed, facilityId, appointmentDateTime, consultationStatus) {
    let returnVal = false;
    if (allowed == 'true' && (!consultationStatus || (consultationStatus && consultationStatus != 'FINISH' && consultationStatus != 'CANCEL'))) {
      let facility = this.user.getAttributeForListingType('facilities', true)['facilities'].find(fac => fac.facilityId == facilityId);
      if (facility) {
        if (facility['facilityControls'] && facility['facilityControls']['schedulingRules'] && facility['facilityControls']['schedulingRules']['changePolicy']) {
          let policy = facility['facilityControls']['schedulingRules']['changePolicy'];
          let dateTimeToCheck = moment(appointmentDateTime, 'YYYY-MM-DD HH:mm');
          returnVal = this.checkWithinPolicyTime(dateTimeToCheck, policy, facilityId);
        } else {
          returnVal = false;
        }
      }
    }
    return returnVal;
  }
  public checkApptCheckinIP(allowed, facilityId, appointmentDateTime, checkedIn) {
    let returnVal = false;
    if (allowed == 'true' && checkedIn == 'false') {
      let facility = this.user.getAttributeForListingType('facilities', true)['facilities'].find(fac => fac.facilityId == facilityId);
      if (facility) {
        if (facility['facilityControls'] && facility['facilityControls']['schedulingRules'] && facility['facilityControls']['schedulingRules']['checkInPolicy']) {
          let policy = facility['facilityControls']['schedulingRules']['checkInPolicy'];
          let dateTimeToCheck = moment(appointmentDateTime, 'YYYY-MM-DD HH:mm');
          returnVal = this.checkWithinPolicyTime(dateTimeToCheck, policy, facilityId);
        } else {
          returnVal = false;
        }
      }
    }
    return returnVal;
  }
  public checkApptCheckinVC(allowed, facilityId, appointmentDateTime, consultationStatus, isOnspot) {
    let returnVal = false;
    let attr = 'videoConsult';
    if (isOnspot) {
      attr = 'onspotConsult'
    }
    if (!allowed) return returnVal;
    if ((!consultationStatus || !(['FINISH', 'CANCEL'].includes(consultationStatus)))) {
      let facility = this.user.getAttributeForListingType('facilities', true)['facilities'].find(fac => fac.facilityId == facilityId);
      if (facility) {
        if (facility['facilityControls'] && facility['facilityControls']['schedulingRules'] && facility['facilityControls']['schedulingRules'][attr] && facility['facilityControls']['schedulingRules'][attr]['policy']) {
          let policy = facility['facilityControls']['schedulingRules'][attr]['policy'];
          let dateTimeToCheck = moment(appointmentDateTime, 'YYYY-MM-DD HH:mm');
          returnVal = this.checkWithinPolicyTime(dateTimeToCheck, policy, facilityId);
          console.log('VCCHECKINPOLICY',dateTimeToCheck, policy)
        } else {
          returnVal = false;
        }
      }
    }
    return returnVal;
  }

  public canRejoin(allowed, facilityId, appointmentDateTime, consultationStatus, isOnspot) {
    let returnVal = false;
    let attr = 'videoConsult';
    if (isOnspot) {
      attr = 'onspotConsult'
    }
    if (!allowed) return returnVal
    if (this.isCheckedIn(consultationStatus, 'VIDEO') == 'PAUSE') { 
      let facility = this.user.getAttributeForListingType('facilities', true)['facilities'].find(fac => fac.facilityId == facilityId);
      if (facility) {
        if (facility['facilityControls'] && facility['facilityControls']['schedulingRules'] && facility['facilityControls']['schedulingRules'][attr] && facility['facilityControls']['schedulingRules'][attr]['policy']) {
          let policy = facility['facilityControls']['schedulingRules'][attr]['policy'];
          let dateTimeToCheck = moment(appointmentDateTime, 'YYYY-MM-DD HH:mm');
          returnVal = this.checkWithinPolicyTime(dateTimeToCheck, policy, facilityId);
          console.log('VCREJOINPOLICY', dateTimeToCheck, policy)
        } else {
          returnVal = false;
        }
      }
    }
    return returnVal;
  }

  public isCheckedIn(status, type): CHECKINSTATUS {
    if (type == 'VIDEO') {
      if (status && status == 'FINISH') return 'CHECKEDIN';
      else if (status && status == 'CANCEL') return 'CANCELLED';
      else if (['PAUSE', 'STARTED', 'WAITING'].includes(status)) return 'PAUSE';
      else return 'YETTOCHECKIN'
    } else {
      if (status == 'true') return 'CHECKEDIN'
      else return 'YETTOCHECKIN'
    }
  }

  public checkAvailableActions(canCheckIn, appointment, defaultQuestionnarie): APPTACTION {
    let defaultAction: APPTACTION = defaultQuestionnarie ? 'QUESTIONNARIE' : 'NONE'
    if (appointment.chargeDetails && appointment.chargeDetails.freeFollowUpYn == 'Y') {
      return canCheckIn ? 'CHECKIN' : defaultAction
    }
    if (appointment.paymentDetail && appointment.paymentDetail.status == 'PAID') {
      return canCheckIn ? 'CHECKIN' : defaultAction
    } else if (appointment.errorFetchingCharge) {
      return 'ERROR';
    }
    let appointmentCharge = appointment.chargeDetails ? appointment.chargeDetails.totalAmount : 0;
    if (appointment.appointmentType != 'VIDEO' && appointment.chargeDetails && (!appointment.chargeDetails.isInsurancePatient || (appointment.chargeDetails.isInsurancePatient && this.checkPractitionerControls(appointment.facilityId, appointment.resourceId, 'PAYFORINSURANCEPATIENT', null)))) {
      if (appointmentCharge > 0 && this.checkAppointmentMasterControls() && this.checkPractitionerControls(appointment.facilityId, appointment.resourceId, 'PAYONCHECKINVISIT', null)) {
        return canCheckIn ? 'PAYCHECKIN' : 'PAY'
      } else {
        return canCheckIn && (appointment.paymentDetail.status == 'PAID' || appointmentCharge == 0) ? 'CHECKIN' : defaultAction
      }
    } else if (appointment.appointmentType == 'VIDEO') {
      if (appointmentCharge > 0 && this.checkAppointmentMasterControls() && this.checkPractitionerControls(appointment.facilityId, appointment.resourceId, 'PAYONCHECKINVC', null)) {
        return canCheckIn ? 'PAYCHECKIN' : 'PAY'
      } else {
        return canCheckIn && (appointment.paymentDetail.status == 'PAID' || appointmentCharge == 0) ? 'CHECKIN' : defaultAction
      }
    } else {
      return canCheckIn && (appointment.paymentDetail.status == 'PAID' || appointmentCharge == 0) ? 'CHECKIN' : defaultAction
    }
    return null; // be sure function does not reach here. and don't remove this line. if reached some condition is missing above
  }
  private checkWithinPolicyTime(dateTimeToCheck, policy, facilityId) {
    let validate: boolean = true;
    let currDateTime = moment();
    let facilityTimezone = this.user.getFacilityTimezone(facilityId);
    let lowerRefDateTime = moment('1999-01-01');
    let upperRefDateTime = moment('2099-12-31');

    if (policy) {
      if (policy.minPeriod != "") {
        lowerRefDateTime = this.getRefDateTime(policy.minPeriod, policy.minPeriodUnit, policy.minPeriodFactor, dateTimeToCheck, facilityTimezone);
      }
      if (policy.maxPeriod != "") {
        upperRefDateTime = this.getRefDateTime(policy.maxPeriod, policy.maxPeriodUnit, policy.maxPeriodFactor, dateTimeToCheck, facilityTimezone);
      }
    }
    console.log('checkWithinPolicyTime service', currDateTime.format(), lowerRefDateTime.format(), upperRefDateTime.format())
    if ((currDateTime.isBefore(lowerRefDateTime)) || (currDateTime.isAfter(upperRefDateTime))) {
      validate = false;
    }
    return validate;
  }
  private getRefDateTime(period, unit, direction, dateTimeToCheck, timezone) {
    let refDateTime = this.user.getMoment(dateTimeToCheck, null, null, timezone);
    if (unit == "H") {
      if (direction == "BEFORE") {
        refDateTime = refDateTime.subtract(period, 'hours');
      }
      else if (direction == "AFTER") {
        refDateTime = refDateTime.add(period, 'hours');
      }
    }
    else if (unit == "M") {
      if (direction == "BEFORE") {
        refDateTime = refDateTime.subtract(period, 'minutes');
      }
      else if (direction == "AFTER") {
        refDateTime = refDateTime.add(period, 'minutes');
      }
    }
    else if (unit == "D") {
      if (direction == "BEFORE") {
        refDateTime = refDateTime.subtract(period, 'days');
      }
      else if (direction == "AFTER") {
        refDateTime = refDateTime.add(period, 'days');
      }
    }
    return refDateTime;
  }

  private checkAppointmentMasterControls() {
    let isPaymentGatewayEnabled = false;
    let isPayableServices = false;
    this.user.getConfigurationInfo.subscribe(data => {
      let appConfig = data;
      isPaymentGatewayEnabled = appConfig.features.paymentGateway.enabled
      isPayableServices = appConfig.features.payableServices['appointments']
      // return isPaymentGatewayEnabled && isPayableServices
    })

    return isPaymentGatewayEnabled && isPayableServices
  }

  public checkPractitionerControls(facilityId, practId, type: 'BOOK' | 'PAYONBOOKVISIT' | 'PAYONBOOKVC' | 'PAYLATERVISIT' | 'PAYLATERVC' | 'PAYONCHECKINVISIT' | 'PAYONCHECKINVC' | 'PAYFORINSURANCEPATIENT', selectedPractitionerObj) {
    let practitioner = null;
    if (this.user.getBookingConcept() == 'FACILITYUNSPECIFIC' && selectedPractitionerObj && selectedPractitionerObj.isDiscoveredDoctor) {
      practitioner = selectedPractitionerObj;
    }
    let facility = this.user.getAttributeForListingType('facilityServices', true)['facilities'].find(fac => fac.facilityId == facilityId);
    if (facility || practitioner) {
      if (!practitioner)
        practitioner = facility.practitioners.find(pract => pract.resourceId == practId);
      if (practitioner) {
        if (type == 'BOOK')
          return practitioner.schedulerControls && practitioner.schedulerControls.directBooking == 'true';
        if (type == 'PAYONBOOKVISIT')
          return practitioner.schedulerControls && practitioner.schedulerControls.payableOnline == 'true';
        if (type == 'PAYONBOOKVC')
          return practitioner.schedulerControls && practitioner.schedulerControls.payableOnlineForVidConsultation == 'Y';
        if (type == 'PAYLATERVISIT')
          return practitioner.schedulerControls && practitioner.schedulerControls.optionalPay == 'Y';
        if (type == 'PAYLATERVC')
          return practitioner.schedulerControls && practitioner.schedulerControls.optionalPayForVideo == 'Y';
        if (type == 'PAYONCHECKINVISIT')
          return practitioner.schedulerControls && practitioner.schedulerControls.payableOnVisit == 'true';
        if (type == 'PAYONCHECKINVC')
          return practitioner.schedulerControls && practitioner.schedulerControls.payableVisitForVidConsultation == 'Y';
        if (type == 'PAYFORINSURANCEPATIENT')
        return this.user.canViewOption('ENABLEPAYONCONSULTFORINS');
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  public getCompletedAppointments(month, year): Observable<any> {
    let payload = {
      personId: this.user.getPersonId(),
      filter: 'MONTH',
      month: month,
      year: year
    }
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_APPOINTMENT}/getCompletedAppointments`, payload, { reportProgress: true }).subscribe(data => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
        observer.error([])
        observer.complete();
      })
    })
  }

  public getInprocessTask(): Observable<any> {
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}/homeCare/getInprogressForHC`, {}, { reportProgress: true }).subscribe(data => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
        observer.error([])
        observer.complete();
      })
    })
  }

  private callRecentConsultatedPatient(){
    this.getRecentConsultatedPatient().subscribe(data=>{
      this.recentConsultatedPatient = data;
    })
  }

  public getRecentConsultatedPatientValue(){
    return this.recentConsultatedPatient;
  }

  public getRecentConsultatedPatient(): Observable<any> {
    let payload = {
      personId: this.user.getPersonId(),
      locale: this.user.getCurrentLanguage(),
    }
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}${AppConstant.API_APPOINTMENT}/getLatestApptInfoForPerson`, payload, { reportProgress: true }).subscribe(data => {
        observer.next(data['data']);
      }, error => {
        observer.error();
      })
    })
  }

  public setSlotBlockRouteInfo(routeToSlotYN){
    this.slotBlockInfo=routeToSlotYN;
  }

  public getSlotBlockRouteInfo(){
    return this.slotBlockInfo;
  }

  public getSpecialitiesForOnspot(): Observable<any> {
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}api/persons/getSpecialitiesForOnspot`, {}, { reportProgress: true }).subscribe(data => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
        observer.error([])
        observer.complete();
      }, () => {
      })
    })
  }

  public getNextAvailablityForSpeciality(specialities): Observable<any> {
    let payload = {
      specialities: specialities
    }
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}api/appointments/getNextAvailablityForSpeciality`, payload, { reportProgress: true }).subscribe(data => {
        observer.next(data['data']);
      }, error => {
        observer.error();
      })
    })
  }

  public getSymptomsBySearch(searchSting): Observable<any> {
    let payload ={
      "directiveCode" : "SYMPTOMS",
      "searchString" : searchSting,
      isBackgroundProcess: true,
      locale: this.user.getCurrentLanguage(),
    }
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}api/appointments/searchSymptoms`, payload, { reportProgress: true }).subscribe(data => {
        observer.next(data['data']);
      }, error => {
        observer.error();
      })
    })
  }
  public handleAlternatePayments(data, request): Observable<any> {
    let payload = {
      ...data,
      currency: this.user.getCurrency(),
      personId: this.user.getPersonId(),
      localeCode: this.user.getCurrentLanguage(),
      request: {
        ...request,
        personId: this.user.getPersonId(),
        localeCode: this.user.getCurrentLanguage(),
      }
    }
    return new Observable(observer => {
      this.http.post(`${environment['API_URL']}api/payments/updateAlternatePayments`, payload, { reportProgress: true }).subscribe(data => {
        observer.next(data['data']);
        observer.complete();
      }, error => {
        observer.error()
        observer.complete();
      })
    })
  }
}
