import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { Validators, AbstractControl, FormControl, FormGroup } from '@angular/forms';
import '@vaadin/vaadin-date-picker/vaadin-date-picker.js';
import { UserService } from '../services/user.service';
import * as moment from 'moment';
import * as momentHijiri from 'moment-hijri';
import { TranslatePipe } from '../pipes/translate.pipe';
import { GENDER_ALNAHDI, VISITOR, REG_DEFAULT_DATE_FORMAT } from '@const';
import { FormControlGenerateService } from '../services/form-control-generate.service';
import * as _ from 'lodash';
import { combineLatestWith, Observable } from 'rxjs';
momentHijiri().format('iYYYY/iM/iD');
moment.locale('en')


@Component({
  selector: 'app-register-new-patient',
  templateUrl: './register-new-patient.component.html',
  styleUrls: ['./register-new-patient.component.scss'],
  providers: [TranslatePipe]
})
export class RegisterNewPatientComponent implements OnInit {
  @Output() close: EventEmitter<any>;
  @Output() signup: EventEmitter<any>;
  @Output() standaloneRegister: EventEmitter<any> = new EventEmitter();
  @Input() editDetails: any;
  @Input() isEdit: any;
  @Input() isPatientSignup: boolean;
  @Input() standalone: boolean;
  @Input() standaloneParams: any;
  @Input() charges: any;
  @Input() showMissingMandatoryParams: any;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  public isAlnahdiRegistration: boolean = true;
  public idValidateJson: any;
  public registerControls: any = []
  public registerFormAlnahdi: FormGroup;
  public uiProcessed: boolean = false;
  public citizenCtrlNahdi: string = ''
  public fromInsurance: boolean = false
  public facilityId: any;
  public curDate;

  public patientFinancialDetails: any = null;
  private nationalityType: string;
  private facilityList: any;
  private policyDataObject: any;
  private formAutofilled: boolean = false;
  public isFormNotValid: boolean;

  public altIdTypes: any;
  public mobileNumber: string = null;


  public payGroupList: Array<any>
  public relationList: Array<any>
  public cityList: Array<any> = []
  public townList: Array<any> = []

  public insuranceError: boolean = false;
  public regError: boolean = false;
  public insuranceErrorMessage: string = null;
  public regErrorMessage: string = null;
  private callno: string = ''
  consetCheckbox : boolean = false;
  showConsetModal : boolean = false;
  TcModal : boolean = false;
  tcCheckbox : boolean = false;
  public currency_code: any
  policyConfigSettings: any;
  viewInsCompModal;
  insCompSearch = '';
  public restrictDuplicateInsObj: any = {};
  private canClearPrefilledForm: boolean = false;
  private demographicsPrefill: any;
  private lastFetchedId: any;
  private registerDetails = null;
  policyDetails: { custGroupName: string; policyName: any; payerName: any; policyNumber: any; membershipNo: any; policyStartDate: string; policyExpiryDate: string; policy: any; value: any; data: any; };

  public occupationList: Array<any> = []
  public maritalList: Array<any> = []
  public religionList: Array<any> = []

  constructor(private user: UserService, private translatePipe: TranslatePipe, private generate: FormControlGenerateService) {
    this.close = new EventEmitter<any>;
    this.callno = this.translatePipe.transform('REGISTER#CALL#NUMBER', 'LABELS')
    if (!this.isAlnahdiRegistration) {
      this.constructStaticForm();
    } else {
      this.constructAlnahdiForm();
    }
  }

  constructStaticForm() {
  }

  constructAlnahdiForm() {
    this.curDate = moment().format('YYYY-MM-DD');
  }

  ngOnInit() {
    console.log('is edit',this.isEdit, this.editDetails);
    if (!this.isAlnahdiRegistration) {
      this.initStaticForm();
    } else {
      this.initAlnahdiForm();
    }
  }

  //Validators
  private nameValidator = (control: AbstractControl): { validName: boolean } => {
    let nameRegex = "^[A-Za-z]*" //^[\s\S]* //^1[0-9]{9}$
    let arabicREgex = /[\u0600-\u06FF]/
    if (control.value) {
      if (arabicREgex.test(control.value))
        return { validName: true };
    }
    return null;
  };
  private nationalValidator = (control: AbstractControl): { validField: boolean } => {
    let nationalRegex = (this.idValidateJson && this.idValidateJson['nationalId'] && this.idValidateJson['nationalId']['pattern']) ? this.idValidateJson['nationalId']['pattern'] : "^1[0-9]{9}$" //|| "^1[0-9]{9}$";
    let iqmaRegex = (this.idValidateJson && this.idValidateJson['iqamaId'] && this.idValidateJson['iqamaId']['pattern']) ? this.idValidateJson['iqamaId']['pattern'] : "^2[0-9]{9}$" //|| "^2[0-9]{9}$";
    let boarderRegex = (this.idValidateJson && this.idValidateJson['boarderId'] && this.idValidateJson['boarderId']['pattern']) ? this.idValidateJson['boarderId']['pattern'] : "^[3-9][0-9]{9}$"  //|| "^[3-9][0-9]{9}$"
    let visitor = this.registerFormAlnahdi && this.registerFormAlnahdi.get(['basic', 'visitor']) && this.registerFormAlnahdi.get(['basic', 'visitor']).value ? this.registerFormAlnahdi.get(['basic', 'visitor']).value[0].checked : null;
    if (control.value) {
      if (!visitor) {
        if (!control.value.match(nationalRegex) && !control.value.match(iqmaRegex)) {
          return { validField: true };
        }
      } else {
        if (!control.value.match(boarderRegex))
          return { validField: true };
      }
    }
    return null;
  };
  private passportValidator = (control: AbstractControl): { validField: boolean } => {
    let passportRegex = (this.idValidateJson && this.idValidateJson['passport'] && this.idValidateJson['passport']['pattern']) ? this.idValidateJson['passport']['pattern'] : "^[a-zA-Z0-9-]+$"  //"^[\s\S]*" //|| "^[A-Z][0-9]*$" ;// "^[A-Z][0-9]{8}$";
    if (control.value) {
      if (!control.value.match(passportRegex)) {
        return { validField: true };
      }
    }
    return null;
  };
  private ageRangeValidator = (control: AbstractControl): { futureDate: boolean } => {
    if (moment(control.value, 'DD-MM-YYYY').isAfter(moment().format())) {
      return { futureDate: true };
    }
    return null;
  };

  private initStaticForm() {
  }
  //Alnahdi flow
  private initAlnahdiForm() {
    this.getFacilityInfo().subscribe(facility => {
      if (facility) {
        this.facilityId = facility.facilityId;
        this.initPayerGroupsAlnahdi();
      }
    })
  }

  private initPayerGroupsAlnahdi() {
    const payerGroup$ = this.user.listPayerGroup(this.facilityId, this.standalone)
    const altIdTypes$ = this.user.getMasters('altIdTypes', this.standalone);
    const masterConfig$ = this.user.getMasterConfig;
    this.user.getCityDetails().subscribe(data => {
      this.cityList = data.map(l => {
        let val = [l.townDesc, l.cityDesc, l.stateDesc].filter(d => !!d).join(',')
        return { ...l, desc: val }
      });;
      this.townList = data.map(l => {
        let val = [l.townDesc, l.cityDesc, l.stateDesc].filter(d => !!d).join(',')
        return { ...l, desc: val }
      });
      this.modifyTownList();
    });
    const occupationObserver = this.user.getRegistrationMasters('occupation');
    const maritalStatusObserver = this.user.getRegistrationMasters('maritalStatus');
    const religionObserver = this.user.getRegistrationMasters('religion');
    const formObserver = this.user.getRegistrationMasters('config');

    payerGroup$.pipe(combineLatestWith(altIdTypes$, masterConfig$, occupationObserver, maritalStatusObserver, religionObserver, formObserver)).subscribe(([payers, altTypes, masterConfig, occupationData, maritalStatusData, religionData, formDataConfig]) => {
      payers.unshift({
        code: null,
        desc: this.translatePipe.transform('SEARCHSELECT#CASH#SELECT'),
        type: 'CLEAR'
      })
      this.payGroupList = payers;
      let relation = masterConfig.list.find(d => {
        return d.domain === 'RELATIONSHIP_TYPE'
      });
      if (relation) {
        this.relationList = relation['codes'];
      }
      this.altIdTypes = altTypes.codes;
      if (altTypes.idValidationJson) {
        this.idValidateJson = altTypes.idValidationJson;
        console.log('validate json---', this.idValidateJson)
      }
      if (altTypes.demographicsPrefill && altTypes.demographicsPrefill.enable) {
        this.demographicsPrefill = altTypes.demographicsPrefill.settings;
      }
      if (occupationData && occupationData.length) this.setOccupationData(occupationData)
      if (maritalStatusData && maritalStatusData.length) this.setMaritalData(maritalStatusData)
      if (religionData && religionData.length) this.setReligionData(religionData)
      if (formDataConfig && formDataConfig.length) this.registerDetails = formDataConfig;
      this.initFormForNahdi()
    })
  }
  private modifyTownList() {
    if (!this.registerControls || !this.registerControls[1])
      return;
    let ind = this.registerControls[1].child.findIndex(c => { return c.name == 'state' });
    let ind1 = this.registerControls[1].child.findIndex(c => { return c.name == 'town' });
    let ind2 = this.registerControls[1].child.findIndex(c => { return c.name == 'city' });

    if (ind >= 0)
      this.registerControls[1].child[ind].selectList = _.cloneDeep(this.cityList).filter((c, i) => this.cityList.findIndex(f => f.stateCode == c.stateCode) == i);
    if (ind1 >= 0) {
      this.registerFormAlnahdi.controls['contact']['controls']['city']
      if (this.registerFormAlnahdi.get(['contact', 'city']) && this.registerFormAlnahdi.get(['contact', 'city']).value) {
        this.registerControls[1].child[ind1].selectList = _.cloneDeep(this.townList).filter(all => {
          return all['cityCode'] == this.registerFormAlnahdi.get(['contact', 'city']).value
        })
      } else {
        this.registerControls[1].child[ind1].selectList = _.cloneDeep(this.townList);
      }
    }
    if (ind2 >= 0)
      if (this.registerFormAlnahdi.get(['contact', 'state']) && this.registerFormAlnahdi.get(['contact', 'state']).value) {
        this.registerControls[1].child[ind2].selectList = _.cloneDeep(this.townList).filter((c, i) => this.townList.findIndex(f => f.cityCode == c.cityCode) == i).filter(all => {
          return all['stateCode'] == this.registerFormAlnahdi.get(['contact', 'state']).value
        })
      } else {
        this.registerControls[1].child[ind2].selectList = _.cloneDeep(this.townList).filter((c, i) => this.townList.findIndex(f => f.cityCode == c.cityCode) == i);
      }
  }

  private initFormForNahdi() {
    let required = [Validators.required, this.nationalValidator], length = [Validators.minLength(10), Validators.maxLength(10)]
    let controls = [...required, ...length]
    let defaultRelation = null;
    let defaultGender = 'M';
    // if (true)
    //   defaultGender = null;
    let nameControl = [Validators.required, this.nameValidator]//this.nameValidator
    // this.policyConfigSettings = { type: 'control', name: 'payerGroup', value: '', validators: [], input: 'search_select', title: 'PAT_REGISTRATION#FORM#BTN#INSURANCECOMPANY', selectList: this.payGroupList, selectOptions: { name: 'desc', value: 'code', choose: 'desc', first: 'SEARCHSELECT#CASH#SELECT' }, order: 5, hide: true };
    this.registerControls = [
      {
        type: 'group',
        name: 'basic',
        title: 'PAT_REGISTRATION#BREADCRUMB#TITLE#BASIC',
        child: [
          { type: 'control', name: 'visitor', value: false, validators: null, input: 'checkbox', radioOptions: { name: 'name', value: 'value' }, radioList: _.cloneDeep(VISITOR), order: 1, readonly: false },
          { type: 'control', name: 'nationalid', value: '', validators: controls, input: 'number', title: 'PAT_REGISTRATION#FORM#LABEL#NATIONALID', maxLength: length, order: 2, breakWeb: true },
          { type: 'control', name: 'country', value: this.user.getDefaultCountryDetailForSignIn(), validators: [Validators.required], input: 'country_select_img', title: 'PAT_REGISTRATION#FORM#LABEL#COUNTRY', order: 3, readonly: true },
          this.policyConfigSettings,
          { type: 'control', name: 'fName', value: '', validators: nameControl, input: 'name', title: 'PAT_REGISTRATION#FORM#LABEL#FIRSTNAME', maxLength: null, order: 6, breakWeb: true },
          { type: 'control', name: 'middlename', value: '', validators: nameControl, input: 'name', title: 'PAT_REGISTRATION#FORM#LABEL#MIDDLENAME', maxLength: null, order: 7 },
          { type: 'control', name: 'lName', value: '', validators: nameControl, input: 'name', title: 'PAT_REGISTRATION#FORM#LABEL#LASTNAME', maxLength: null, order: 8 },
          { type: 'control', name: 'dob', value: '', validators: [Validators.required, this.ageRangeValidator], input: 'date', title: 'PAT_REGISTRATION#FORM#LABEL#DOB', dateMax: this.curDate, colSize: 6 },
          { type: 'control', name: 'gender', value: defaultGender, validators: [Validators.required], input: 'search_select', selectList: _.cloneDeep(GENDER_ALNAHDI), selectOptions: { name: 'name', value: 'value', choose: 'name', translate: true, first: 'SEARCHSELECT#CLEAR#VALUES' }, title: 'PAT_REGISTRATION#FORM#LABEL#GENDER', hideSearch: true, colSize: 6 },
          { type: 'control', name: 'relationship', value: defaultRelation, validators: [Validators.required], input: 'search_select', title: 'PAT_REGISTRATION#FORM#LABEL#RELATIONSHIP', selectList: _.cloneDeep(this.relationList), selectOptions: { name: 'value', value: 'code', key: 'name', locale: true, hidesearch: true }, order: 9, usePipe: true, pipe: 'RELATIONFILTER' },

          { type: 'control', name: 'occupation', value: '', validators: [Validators.required], input: 'search_select', selectList: this.occupationList, selectOptions: { name: 'desc', value: 'code', choose: 'desc', translate: false, first: 'SEARCHSELECT#CLEAR#VALUES' }, title: 'PAT_REGISTRATION#FORM#LABEL#OCCUPATION', hideSearch: false, order: 12 },
          { type: 'control', name: 'maritalStatus', value: '', validators: [Validators.required], input: 'search_select', selectList: this.maritalList, selectOptions: { name: 'desc', value: 'code', choose: 'desc', translate: false, first: 'SEARCHSELECT#CLEAR#VALUES' }, title: 'PAT_REGISTRATION#FORM#LABEL#MARITALSTATUS', hideSearch: false, order: 13 },
          { type: 'control', name: 'religion', value: '', validators: [Validators.required], input: 'search_select', selectList: this.religionList, selectOptions: { name: 'desc', value: 'code', choose: 'desc', translate: false, first: 'SEARCHSELECT#CLEAR#VALUES' }, title: 'PAT_REGISTRATION#FORM#LABEL#RELIGION', hideSearch: false, order: 14 },
        ]
      }
    ];

    const defaults = {
      visitor: {
        radioList: _.cloneDeep(VISITOR)
      },
      nationalid: {
        validators: controls,
        maxLength: length,
        breakWeb: true
      },
      country: {
        value: this.user.getDefaultCountryDetailForSignIn(),
      },
      fName: {
        validators: nameControl,
        breakWeb: true
      },
      middlename: {
        validators: nameControl,
      },
      lName: {
        validators: nameControl,
      },
      dob: {
        validators: [this.ageRangeValidator],
        dateMax: this.curDate,
      },
      gender: {
        value: defaultGender,
        selectList: _.cloneDeep(GENDER_ALNAHDI),
      },
      relationship: {
        value: defaultRelation,
        selectList: _.cloneDeep(this.relationList), 
      },
      occupation: {
        selectList: this.occupationList,
      },
      maritalStatus: {
        selectList: this.maritalList,
      },
      religion: {
        selectList: this.religionList,
      },
      payerGroup: {
        selectList: this.payGroupList,
      }
    }

    if (this.registerDetails) {
      this.registerControls = this.registerDetails.map(group => {
        group.child = group.child.map(c => {
          if (defaults[c.name]) {
            c = { ...c, ...defaults[c.name] };
          }
          if (c.required) {
            if (!c.validators) c.validators = []
            c.validators.push(Validators.required);
          }
          if (c.name === 'payerGroup') {
            this.policyConfigSettings = c;
          }
          return c
        })
        return group;
      })
    }
    this.registerFormAlnahdi = this.generate.generateForm(this.registerControls);
    this.uiProcessed = true;
    this.manageValueChanges();
    if (this.editDetails) {
      console.log('edit profile');
      this.fromInsurance = true;
      this.getPatientBillingGrp();
    }
  }

  private manageValueChanges() {
    console.log('value changes')
    this.registerFormAlnahdi.get(['basic', 'visitor']).valueChanges.subscribe(value => {
      let uniqueIdInd = 1;
      console.log('manageValueChanges', value);
      if (value[0].checked) {
        this.visitorView();
      } else {
        this.citizenCtrlNahdi = '';
        this.registerControls[0].child[uniqueIdInd]['title'] = 'PAT_REGISTRATION#FORM#LABEL#NATIONALID';
        this.registerFormAlnahdi.get(['basic', 'nationalid']).setValidators([Validators.required]);
        let removeIndex = this.registerControls[0].child.findIndex(c => c.name == 'passport');
        console.debug('removeIndex', removeIndex)

        this.registerControls[0].child = this.registerControls[0].child.slice(0, removeIndex).concat(this.registerControls[0].child.slice(removeIndex + 1))
        let tempGroup = this.registerFormAlnahdi.get('basic') as FormGroup;
        tempGroup.removeControl('passport');
        this.registerControls[0].child = this.registerControls[0].child.sort((a, b) => a.order - b.order)
      }
      this.registerFormAlnahdi.get(['basic', 'nationalid']).updateValueAndValidity();
    });
    this.registerFormAlnahdi.get(['basic', 'nationalid']).valueChanges.subscribe(value => {
      if (this.citizenCtrlNahdi != 'V') {
        if (value.startsWith('1')) {
          this.citizenCtrlNahdi = 'N';
        } else if (value.startsWith('2')) {
          this.citizenCtrlNahdi = 'INT'
        }
      }
      this.alterCitizen()
    })
    let refreshView = false;
    this.user.payerGrpUpdated$.subscribe((data) => {
      refreshView = true;
    })
    this.registerFormAlnahdi.get(['basic', 'payerGroup']).valueChanges.subscribe(value => {
      // this.insCompSearch = value;
      if (this.canClearPrefilledForm) {
        this.clearPrefilledForm();
      }
      if (value && !this.restrictDuplicateInsObj[value] && (!this.editDetails || (this.editDetails && refreshView))) {
        if(this.canClearPrefilledForm) this.restrictDuplicateInsObj[value] = value;
        this.fromInsurance = true;
        const payer = this.payGroupList.find(p => p.code == value);
        let religionObj = this.religionList.find(r => r.code === this.registerFormAlnahdi.get(['basic', 'religion'])?.value?.code);
        let occupationObj = this.occupationList.find(r => r.code === this.registerFormAlnahdi.get(['basic', 'occupation'])?.value?.code);
        let maritalObj = this.maritalList.find(r => r.code === this.registerFormAlnahdi.get(['basic', 'maritalStatus'])?.value?.code);

        let inparam = {
          facilityId: this.facilityId,
          payer: payer,
          policyGroup: payer ? payer.BLNGGRPID : null,
          idNumber: this.registerFormAlnahdi.get(['basic', 'nationalid']).value,
          passport: this.registerFormAlnahdi.get(['basic', 'passport'])?.value,
          custGroupCode: value,
          firstName: this.registerFormAlnahdi.get(['basic', 'fName']).value,
          middleName: this.registerFormAlnahdi.get(['basic', 'middlename']).value,
          lastName: this.registerFormAlnahdi.get(['basic', 'lName']).value,
          visitor: this.registerFormAlnahdi.get(['basic', 'visitor']).value?.[0]?.checked || false,
          dob: this.registerFormAlnahdi.get(['basic', 'dob']).value,
          gender: this.registerFormAlnahdi.get(['basic', 'gender'])?.value?.value,
          country: this.registerFormAlnahdi.get(['basic', 'country']).value?.[1],
          religion: religionObj?.code || '',
          religionDesc: religionObj?.desc || '',
          occupation: occupationObj?.code || '',
          occupationDesc: occupationObj?.desc || '',
          maritalStatus: maritalObj?.code || '',
          maritalStatusDesc: maritalObj?.desc || '',
        }
        if (this.registerFormAlnahdi.valid) {
          this.patientFinancialDetails = null;
          this.user.getPatientPolicy(inparam).subscribe(data => {
            this.showConfirmationPop(value, data)
          }, err => {
            if (this.canClearPrefilledForm) delete this.restrictDuplicateInsObj[value];
            this.openInsurancePop(err.error)
          })
        }
      }
    })
    this.registerFormAlnahdi.get(['basic', 'relationship']).valueChanges.subscribe(value => {
      console.log(value)
      console.log('this.registerFormAlnahdi ', this.registerFormAlnahdi)
    })
  }

  public visitorView() {
    console.log('value[0].checked', this.registerControls);
        this.registerControls[0].child[1]['title'] = 'PAT_REGISTRATION#FORM#LABEL#BORDERID'
        this.registerFormAlnahdi.get(['basic', 'nationalid']).clearValidators();
        this.citizenCtrlNahdi = 'V';
        let requiredPass = [Validators.required, this.passportValidator], lengthPass = [];
        if (this.idValidateJson && this.idValidateJson['passport'] && this.idValidateJson['passport']['max']) {
          lengthPass.push(Validators.maxLength(this.idValidateJson['passport']['max']));
        }
        if (this.idValidateJson && this.idValidateJson['passport'] && this.idValidateJson['passport']['min']) {
          lengthPass.push(Validators.minLength(this.idValidateJson['passport']['min']));
        }
        let controlsPass = [...requiredPass, ...lengthPass]
        let group = this.registerFormAlnahdi.get('basic') as FormGroup;
        group.addControl('passport', new FormControl('', controlsPass));
        let newControl = { type: 'control', name: 'passport', value: '', validators: controlsPass, input: 'text', title: 'PAT_REGISTRATION#FORM#LABEL#PASSPORT', order: 4 }
        this.registerControls[0].child.push(newControl)
        this.registerControls[0].child = this.registerControls[0].child.sort((a, b) => a.order - b.order)
        this.registerFormAlnahdi.get(['basic', 'passport'])?.valueChanges.subscribe(data => {
          this.checkForDemographics('AUTOCOMPLETE');
        })
  }

  public async showConfirmationPop(value, info) {
    if (info) {
      let policyInfo = {
        custGroupName: '',
        policyName: info['financialDetails'] && info['financialDetails'].length > 0 ? info['financialDetails'][0]['policyName'] : null,
        payerName: info['financialDetails'] && info['financialDetails'].length > 0 ? info['financialDetails'][0]['payerName'] : null,
        policyNumber: info['financialDetails'] && info['financialDetails'].length > 0 ? info['financialDetails'][0]['policyNo'] : null,
        membershipNo: info['financialDetails'] && info['financialDetails'].length > 0 ? info['financialDetails'][0]['membershipId'] : null,
        policyStartDate: info['insDetails'] && info['insDetails']['InsurancePolicy_StartDate'] ? moment(info['insDetails']['InsurancePolicy_StartDate'].split("+")[0]).format(REG_DEFAULT_DATE_FORMAT) : null,
        policyExpiryDate: info['insDetails'] && info['insDetails']['InsurancePolicy_ExpiryDate'] ? moment(info['insDetails']['InsurancePolicy_ExpiryDate'].split("+")[0]).format(REG_DEFAULT_DATE_FORMAT) : null,
        policy: info['financialDetails'] && info['financialDetails'].length > 0 ? info['financialDetails'][0]['policy'] : null,
        value, 
        data:info
      }
      this.policyDetails = policyInfo;
    }
  }

  public fillAutofillParams() {
    const {value, data} = this.policyDetails;
    this.policyDetails = null;
    if (this.canClearPrefilledForm) delete this.restrictDuplicateInsObj[value];
    this.policyDataObject = data;
    if (data) {
      let fields = this.prefillPatientDetails(data.patientDetails);
      if (data.financialDetails) {
        this.patientFinancialDetails = data.financialDetails
      }
      this.readonlyPrefilledFields(true, fields)
      if (this.registerFormAlnahdi.valid) {
        // this.proceedRegister();
        this.continueTcTermsAndConditions();
      }
    } else {
      this.readonlyPrefilledFields(null, false);
    }
  }
  public alterCitizen() {
    const citizenNationality = this.user.getDefaultCountryDetailForSignIn();
    const selectedNationality = this.registerFormAlnahdi.get(['basic', 'country']).value;
    const countryIndex = this.registerControls[0].child.findIndex(d => d.name == 'country')
    console.log(citizenNationality, selectedNationality, countryIndex)
    console.log('alter citizsen---', this.citizenCtrlNahdi)
    this.nationalityType = this.citizenCtrlNahdi == 'V' ? 'V' : this.citizenCtrlNahdi == 'INT' ? 'IN' : 'N';
    if (this.citizenCtrlNahdi == 'N' && citizenNationality[1] != selectedNationality[1]) {
      this.registerFormAlnahdi.get(['basic', 'country']).setValue(citizenNationality);
    }
    if (this.citizenCtrlNahdi != 'N' && citizenNationality[1] == selectedNationality[1]) {
      this.registerFormAlnahdi.get(['basic', 'country']).setValue(null)
    }
    if (countryIndex > -1) {
      this.registerControls[0].child[countryIndex].readonly = this.citizenCtrlNahdi == 'N'
      this.registerControls[0].child[countryIndex].skipCountryCode = this.citizenCtrlNahdi != 'N' ? [citizenNationality[1]] : null;
    }
    console.log('alterCitizen', this.registerFormAlnahdi.get(['basic', 'payerGroup']).value, moment().format())
    this.registerFormAlnahdi.get(['basic', 'payerGroup']).setValue(null);
    this.checkForDemographics('AUTOCOMPLETE')
  }

  private getFacilityInfo(): Observable<any> {
    return new Observable(observer => {
      this.user.getUpdateInfo('facilities').subscribe(facdata => {
        this.facilityList = facdata.facilities;
        if (this.standalone && this.standaloneParams && this.standaloneParams.fid) {
          let facility = this.facilityList.find(fac => {
            return fac.facilityId == this.standaloneParams.fid;
          })
          observer.next(facility || this.facilityList[0]);
          observer.complete;
          return;
        }
        this.user.getConfigurationInfo.subscribe(data => {
          if (data.identityRules && data.identityRules.newRegistration && data.identityRules.newRegistration.defaultFacilityForRegistration) {
            let facility = this.facilityList.find(fac => {
              return fac.facilityId == data.identityRules.newRegistration.defaultFacilityForRegistration;
            })
            observer.next(facility || this.facilityList[0]);
            observer.complete;
            return;
          }
          observer.next(this.facilityList[0]);
          observer.complete;
          return;
        })
      })
    })
  }
  private clearPrefilledForm() {
    if (this.formAutofilled) {
      this.registerFormAlnahdi.get(['basic', 'gender']).setValue(null);
      this.registerFormAlnahdi.get(['basic', 'dob']).setValue(null);
      this.registerFormAlnahdi.get(['basic', 'fName']).setValue(null);
      this.registerFormAlnahdi.get(['basic', 'middlename']).setValue(null);
      this.registerFormAlnahdi.get(['basic', 'lName']).setValue(null);
      this.patientFinancialDetails = null;
      this.readonlyPrefilledFields(false)
    }
  }

  private readonlyPrefilledFields(status,fieldsArr?) {
    let fields = fieldsArr&& fieldsArr.length>0 ? fieldsArr : ['gender', 'dob', 'fName', 'middlename', 'lName'];
    fields.forEach(f => {
      this.registerControls.forEach(r => {
        r.child.forEach(c => {
          if (c.name == f) {
            c.readonly = this.canClearPrefilledForm && status;
          }
        })
      })
    })
    this.formAutofilled = status
  }

  private async openInsurancePop(err) {
    if (err && err.data && err.data.displayErrorMessage && err.data.message) {
      this.insuranceErrorMessage = err.data.message;
    }
    this.insuranceError = true;
  }

  public closeRegisterPatient() {
    this.closeModal();
  }
  public closeModal(patientId = null, patients = null, action = null) {
    this.registerFormAlnahdi.reset();
    this.close.emit({ patientId, patients, action });
  }
  public isRequiredField(group: string, field: string) {
    let form_field = this.registerFormAlnahdi.get([group, field]);
    if (!form_field || !form_field.validator) {
      return false;
    }
    let validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }
  getError(group: string, field: string) {
    if (this.isRequiredField(group, field)) {
      if (this.isFormNotValid && this.registerFormAlnahdi.value[group][field].length == 0) return true;
      if (this.registerFormAlnahdi.controls[group]['controls'][field] && this.registerFormAlnahdi.controls[group]['controls'][field].errors && (this.registerFormAlnahdi.controls[group]['controls'][field].dirty || this.registerFormAlnahdi.controls[group]['controls'][field].touched))
        return true;
      return false;
    } else
      return false;
  }
  getErrorValidation(group: string, field: string) {
    return this.registerFormAlnahdi.controls[group]['controls'][field]
  }
  public getSource() {
    return this.fromInsurance;
  }

  public proceedRegister() {
    this.showConsetModal = true;
  }

  private getRegisterParams() {
    console.log('nationlaity check---', this.nationalityType, this.altIdTypes)
    let genderVal = this.registerFormAlnahdi.get(['basic', 'gender']) && this.registerFormAlnahdi.get(['basic', 'gender']).value ? this.registerFormAlnahdi.get(['basic', 'gender']).value : this.registerFormAlnahdi.get(['basic', 'gender']).value
    let relationVal = this.registerFormAlnahdi.get(['basic', 'relationship']) ? this.registerFormAlnahdi.get(['basic', 'relationship']).value : null
    let religionObj = this.religionList.find(r => r.code === this.registerFormAlnahdi.get(['basic', 'religion'])?.value?.code);
    let occupationObj = this.occupationList.find(r => r.code === this.registerFormAlnahdi.get(['basic', 'occupation'])?.value?.code);
    let maritalObj = this.maritalList.find(r => r.code === this.registerFormAlnahdi.get(['basic', 'maritalStatus'])?.value?.code);

    let payload: any = {
      facilityId: this.facilityId,
      sourceFacilityId: this.facilityId,
      prefix: '',
      firstName: this.registerFormAlnahdi.get(['basic', 'fName']) ? this.registerFormAlnahdi.get(['basic', 'fName']).value : this.registerFormAlnahdi.get(['basic', 'fNameR']).value,
      secondName: this.registerFormAlnahdi.get(['basic', 'middlename']) ? this.registerFormAlnahdi.get(['basic', 'middlename']).value : null,
      thirdName: null,
      familyName: this.registerFormAlnahdi.get(['basic', 'lName']) ? this.registerFormAlnahdi.get(['basic', 'lName']).value : this.registerFormAlnahdi.get(['basic', 'lNameR']).value,
      gender: genderVal ? genderVal.value : null,
      email: this.registerFormAlnahdi.get(['contact', 'email']) && this.registerFormAlnahdi.get(['contact', 'email']).value ? this.registerFormAlnahdi.get(['contact', 'email']).value : this.user.getPersonEmail(),
      mobile: this.registerFormAlnahdi.get(['contact', 'mobile']) && this.registerFormAlnahdi.get(['contact', 'mobile']).value ? this.registerFormAlnahdi.get(['contact', 'mobile']).value : this.user.getPersonMobile(),
      dob: this.registerFormAlnahdi.get(['basic', 'dob']) && this.registerFormAlnahdi.get(['basic', 'dob']).value ? moment(this.registerFormAlnahdi.get(['basic', 'dob']).value, 'DD-MM-YYYY').format(REG_DEFAULT_DATE_FORMAT) : moment(this.registerFormAlnahdi.get(['basic', 'dob']).value, 'DD-MM-YYYY').format(REG_DEFAULT_DATE_FORMAT),
      relationshipType: relationVal ? relationVal.code : 'OTR',
      altIds: [],
      address1: this.registerFormAlnahdi.get(['contact', 'address1']) ? this.registerFormAlnahdi.get(['contact', 'address1']).value : "",
      address2: this.registerFormAlnahdi.get(['contact', 'address2']) ? this.registerFormAlnahdi.get(['contact', 'address2']).value : "",
      billingType: this.registerFormAlnahdi.get(['finance', 'billingGroup']) && this.registerFormAlnahdi.get(['finance', 'billingGroup']).value == 'C' ? 'C' : 'O',
      citizenYn: this.nationalityType == 'N' ? 'Y' : 'N',
      countryCode: this.user.getPersonCountryCode(),
      visaType: this.nationalityType == 'V' ? 'V' : null,
      religion: religionObj?.code || '',
      religionDesc: religionObj?.desc || '',
      occupation: occupationObj?.code || '',
      occupationDesc: occupationObj?.desc || '',
      maritalStatus: maritalObj?.code || '',
      maritalStatusDesc: maritalObj?.desc || '',
    }
    if (this.registerFormAlnahdi.get(['basic', 'gender']) && this.registerFormAlnahdi.get(['basic', 'gender']).value == 'M') {
      payload['fatherName'] = this.registerFormAlnahdi.get(['basic', 'guardianName']) ? this.registerFormAlnahdi.get(['basic', 'guardianName']).value : "";
    } else {
      payload['fatherHusName'] = this.registerFormAlnahdi.get(['basic', 'guardianName']) ? this.registerFormAlnahdi.get(['basic', 'guardianName']).value : "";
    }
    if (this.altIdTypes && this.altIdTypes.length)
      this.altIdTypes.forEach(type => {
        if (this.registerFormAlnahdi.get(['basic', type.code])) {
          payload.altIds.push({
            altIdType: type.code,
            altId: this.registerFormAlnahdi.get(['basic', type.code]).value
          })
        } else {
          if (type.controls.nationalOrInternational == this.nationalityType && this.nationalityType !== 'V') {
            payload.altIds.push({
              altIdType: type.code,
              altId: this.registerFormAlnahdi.get(['basic', 'nationalid']).value
            })
          }
        }
      });
    if (this.nationalityType == 'V') {
      payload.altIds.push({
        altIdType: 'ALT1',
        altId: this.registerFormAlnahdi.get(['basic', 'nationalid']) && this.registerFormAlnahdi.get(['basic', 'nationalid']).value ? this.registerFormAlnahdi.get(['basic', 'nationalid']).value : null,
      })
      payload.altIds.push({
        altIdType: 'ALT2',
        altId: this.registerFormAlnahdi.get(['basic', 'passport']) && this.registerFormAlnahdi.get(['basic', 'passport']).value ? this.registerFormAlnahdi.get(['basic', 'passport']).value : null,
      })
    }
    payload.altIds.push({
      altIdType: 'ALT3',
      altId: this.registerFormAlnahdi.get(['contact', 'mobile']) && this.registerFormAlnahdi.get(['contact', 'mobile']).value ? this.registerFormAlnahdi.get(['contact', 'mobile']).value : (this.editDetails && this.mobileNumber ? this.mobileNumber : this.user.getPersonMobile()),
    })
    let stv;

    if (this.registerFormAlnahdi.get(['contact', 'town']) && this.registerFormAlnahdi.get(['contact', 'town']).value)
      stv = this.registerFormAlnahdi.get(['contact', 'town']).value
    else if (this.registerFormAlnahdi.get(['contact', 'city']) && this.registerFormAlnahdi.get(['contact', 'city']).value)
      stv = this.registerFormAlnahdi.get(['contact', 'city']).value
    else if (this.registerFormAlnahdi.get(['contact', 'state']) && this.registerFormAlnahdi.get(['contact', 'state']).value)
      stv = this.registerFormAlnahdi.get(['contact', 'state']).value;
    let sto = this.cityList.find(c => { return c.townCode == stv || c.cityCode == stv || c.state == stv })
    if (sto && (this.registerFormAlnahdi.get(['contact', 'town']) || this.registerFormAlnahdi.get(['contact', 'city']) || this.registerFormAlnahdi.get(['contact', 'state']))) {
      payload = {
        ...payload,
        state: sto.stateCode,
        city: sto.cityCode,
        town: sto.townCode
      }
    }
    if (this.nationalityType != 'N') {
      payload = {
        ...payload,
        nationality: this.registerFormAlnahdi.get(['basic', 'country']) && this.registerFormAlnahdi.get(['basic', 'country']).value && this.registerFormAlnahdi.get(['basic', 'country']).value[1] ? this.registerFormAlnahdi.get(['basic', 'country']).value[1].toUpperCase() : this.registerFormAlnahdi.get(['contact', 'country']).value.toUpperCase(),
      }
    }
    if (this.nationalityType == 'N') {
      payload = {
        ...payload,
        nationality: this.registerFormAlnahdi.get(['basic', 'country']) && this.registerFormAlnahdi.get(['basic', 'country']).value && this.registerFormAlnahdi.get(['basic', 'country']).value[1] ? this.registerFormAlnahdi.get(['basic', 'country']).value[1].toUpperCase() : null,
      }
    }
    if (this.registerFormAlnahdi.get(['finance', 'billingGroup']) && this.registerFormAlnahdi.get(['finance', 'billingGroup']).value == 'X') {
      let financialDetails = [{
        payerGroupCode: this.registerFormAlnahdi.get(['finance', 'payerGroup']).value,
        payer: this.registerFormAlnahdi.get(['finance', 'payer']).value,
        policy: this.registerFormAlnahdi.get(['finance', 'policyName']).value,
        policyNo: this.registerFormAlnahdi.get(['finance', 'policyNumber']).value,
        policyStartDtm: moment(this.registerFormAlnahdi.get(['finance', 'startDate']).value, 'DD-MM-YYYY').format(REG_DEFAULT_DATE_FORMAT),
        policyEndDtm: moment(this.registerFormAlnahdi.get(['finance', 'endDate']).value, 'DD-MM-YYYY').format(REG_DEFAULT_DATE_FORMAT)
      }];
      payload['financialDetails'] = financialDetails;
    }
    if (this.registerFormAlnahdi.get(['basic', 'passport']) && this.registerFormAlnahdi.get(['basic', 'passport']).value) {
      payload['passportNo'] = this.registerFormAlnahdi.get(['basic', 'passport']).value
    }
    if (this.policyDataObject && this.policyDataObject['insDetails']) {
      let financialDetails = [{
        payerGroupCode: this.registerFormAlnahdi.get(['basic', 'payerGroup']) ? this.registerFormAlnahdi.get(['basic', 'payerGroup']).value : null,
        payer: this.policyDataObject['insDetails']['Policy_Holder'] ? this.policyDataObject['insDetails']['Policy_Holder'] : null,
        policy: this.policyDataObject['insDetails']['Class_Name'] ? this.policyDataObject['insDetails']['Class_Name'] : null,
        policyNo: this.policyDataObject['insDetails']['Policy_Number'] ? this.policyDataObject['insDetails']['Policy_Number'] : null,
        policyStartDtm: this.policyDataObject['insDetails'].InsurancePolicy_StartDate ? moment(this.policyDataObject['insDetails'].InsurancePolicy_StartDate.split("+")[0]).format(REG_DEFAULT_DATE_FORMAT) : null,
        policyEndDtm: this.policyDataObject['insDetails'].InsurancePolicy_ExpiryDate ? moment(this.policyDataObject['insDetails'].InsurancePolicy_ExpiryDate.split("+")[0]).format(REG_DEFAULT_DATE_FORMAT) : null
      }];
      payload['financialDetails'] = financialDetails;
    }
    if (this.patientFinancialDetails)
      payload['financialDetails'] = this.patientFinancialDetails;
    if (this.editDetails)
      payload['patientId'] = this.editDetails['patientId'] || this.editDetails['uhid']
    return payload;
  }

  public openDialer() {
    this.user.openLocation("tel:" + this.callno, true);
  }

  public closeInsModal(type) {
    this.insuranceError = false;
    this.insuranceErrorMessage = null;
    if (type == 'CASH') {
      this.registerFormAlnahdi.get(['basic', 'payerGroup']).setValue(null);
    }
  }

  private errorRegPat(err) {
    this.regError = true;
    if (err && err.error && err.error.error && err.error.error.showErrorLabel && err.error.error.errorLabel) {
      this.regErrorMessage = err.error.error.errorLabel
    }
  }

  public closeRegModal(err) {
    this.regError = false;
    this.regErrorMessage = null
  }

  private getPatientBillingGrp() {
    console.log('get Patient BillingGrp', this.editDetails)
    let tmp = [];
    tmp.push({
      patientId: this.editDetails['uhid'],
      facilityId: this.editDetails['facilityid'],
      getPaientAppStructure: true
    })
    this.user.getPatientPolicyDetails(tmp).subscribe((data: any) => {
      if (data.length > 0) {
        let tmpIns = data[0]['insuranceDetails'];
        if (tmpIns.length > 0) {
          this.editDetails['billingGrp'] = tmpIns[0]['billingGrpId'];
          this.patientFinancialDetails = tmpIns.filter(t => t.settlememtInd == 'X')
          if (tmpIns[0]['billingGrpId'] !== 'CASH')
            this.editDetails['insuranceObj'] = tmpIns[0]
        }
      }
      if (this.editDetails['billingGrp']) {
        this.editPatientInfo()
        this.getPatient()
      }
      console.log('ins data---', data, this.editDetails);
    })
  }
  private findInd(inpName) {
    return this.registerControls[0].child.findIndex(c => c.name == inpName)
  }
  private editPatientInfo() {
    console.log('editPatientInfo', this.editDetails, this.registerControls);
      this.editDetails.momentDOB = moment(this.editDetails.dob.split("+")[0]);
      if (this.editDetails.gender) {
        let foundGender = GENDER_ALNAHDI.find(g => g.value == this.editDetails.gender)
        this.registerFormAlnahdi.get(['basic', 'gender']).setValue(foundGender);
        if (this.editDetails['billingGrp'] != 'CASH' && this.canClearPrefilledForm) {
          let ind = this.findInd('gender')
          if (ind >= 0) {
            this.registerControls[0].child[ind].readonly = true;
          }
        }
      }
      if (this.editDetails.dob) {
        let dob = moment(this.editDetails.dob.split("+")[0]);
        this.registerFormAlnahdi.get(['basic', 'dob']).setValue(dob);
        if (this.editDetails['billingGrp'] != 'CASH' && this.canClearPrefilledForm) {
          let ind = this.findInd('dob')
          if (ind >= 0) {
            this.registerControls[0].child[ind].readonly = true;
          }
        }
      }
  
      if (this.editDetails.national_id) {
        this.registerFormAlnahdi.get(['basic', 'nationalid']).setValue(this.editDetails.national_id)
        let ind = this.findInd('nationalid')
        if (ind >= 0) {
          this.registerControls[0].child[ind].readonly = true;
        }
      }
      if (this.editDetails.iqama_id) {
        this.registerFormAlnahdi.get(['basic', 'nationalid']).setValue(this.editDetails.iqama_id)
        let ind = this.findInd('nationalid')
        if (ind >= 0) {
          this.registerControls[0].child[ind].readonly = true;
        }
      }
      let visitorInd = this.findInd('visitor')
      let payerInd = this.findInd('payerGroup')
      if (visitorInd > -1) {
        const indChar = this.registerFormAlnahdi.get(['basic', 'nationalid'])?.value?.substring(0, 1)
        if (indChar && Number(indChar) > 2) {
          this.registerControls[0].child[visitorInd].radioList = [{ name: 'PAT_REGISTRATION#FORM#VISITOR', checked: true }];
          this.visitorView();
          console.log('after changes', this.registerControls)
          if (this.idValidateJson && this.idValidateJson.visitor && this.idValidateJson.visitor.hideOnEditForVisitor) {
            this.registerControls[0].child[visitorInd].hide = true;
          }
        } else if (!(this.idValidateJson && this.idValidateJson.visitor && this.idValidateJson.visitor.showOnEdit)) {
          this.registerControls[0].child[visitorInd].hide = true;
        }
        this.registerControls[0].child[visitorInd].readonly = true;
      }
      if (this.editDetails['insuranceObj'] && this.editDetails['insuranceObj']['custGroupCode']) {
        this.registerFormAlnahdi.get(['basic', 'payerGroup']).setValue(this.editDetails['insuranceObj']['custGroupCode'])
        if (payerInd > -1) {
          this.registerControls[0].child[payerInd]['enableRefreshView'] = true;
        }
      }
      if (this.editDetails['relationshiptype'] && this.editDetails['gender']) {
        this.updateRelationInForm(this.editDetails['gender'])
      }
      if (this.editDetails['billingGrp'] == 'CASH' && payerInd > -1) {
        let tmp = _.cloneDeep(this.payGroupList)
        tmp.push({
          BLNGGRPID: "CASH",
          code: "CASH",
          desc: "CASH"
        })
        this.registerControls[0].child[payerInd].selectList = tmp;
        setTimeout(() => {
          this.registerFormAlnahdi.get(['basic', 'payerGroup']).setValue('CASH')
        }, 1500)
      }
    console.log('update form--', this.registerControls)
    // if (this.editDetails['billingGrp'] != 'CASH')
    //   this.readonlyPrefilledFields(true);
  }
  public updateRelationInForm(gender) {
    var relationlist = _.cloneDeep(this.relationList)
    let relationsByGender = relationlist.filter((r) => r['controls']['section']['gender']['code'] == 'A' || r['controls']['section']['gender']['code'] == gender)
    let tmp = relationsByGender.find((r) => r.code == this.editDetails['relationshiptype'])
    if (tmp)
      this.registerFormAlnahdi.get(['basic', 'relationship']).setValue(tmp)
  }

  private parseName(input) {
    var fullName = input || "";
    var result = {
      firstName: '',
      secondName: '',
      lastName: ''
    };
    if (fullName.length > 0) {
      var nameTokens = fullName.split(' ')
      console.log('name length check---', nameTokens)
      if (nameTokens.length > 2) {
        result.firstName = nameTokens[0],
          result.secondName = nameTokens.slice(1, -1).join(' '),
          result.lastName = nameTokens[nameTokens.length - 1]
      } else if (nameTokens.length < 2) {
        result.firstName = nameTokens[0],
          result.secondName = '',
          result.lastName = ''
      } else {
        result.firstName = nameTokens[0],
          result.secondName = '',
          result.lastName = nameTokens[nameTokens.length - 1]
      }
    }
    return result;
  }

  public getPatient(names?) {
    this.user.getPatient(this.editDetails['uhid']).subscribe(data => {
      if (data['familyName'])
        data['lastName'] = data['familyName']
      this.setUserNames(data);
      console.log('get patient', data, this.editDetails)
      if (!this.editDetails.gender && data.gender && ['M', 'F'].includes(data.gender)) {
        this.registerFormAlnahdi.get(['basic', 'gender']).setValue(data.gender);
        if (this.editDetails['billingGrp'] != 'CASH') {
          this.updateFieldProperty()
          let ind = this.findInd('gender');
          this.registerControls[0].child[ind].readonly = this.canClearPrefilledForm;
        }
        this.updateRelationInForm(data.gender)
      }
      let mobileNumber, passportNo, iqamaId, nationalId;
      if (data['altIds'] && data['altIds'].length > 0) {
        data['altIds'].forEach((a) => {
          if (a['altIdType'] == 'ALT3')
            mobileNumber = a['altId']
          if (a['altIdType'] == 'ALT2')
            passportNo = a['altId']
          if (a['altIdType'] == 'ALT1')
            iqamaId = a['altId']
          if (a['altIdType'] == 'NATID')
            nationalId = a['altId']
        })
        if (passportNo) {
          this.registerFormAlnahdi.get(['basic', 'passport']).setValue(passportNo)
        }
        mobileNumber = parseInt(mobileNumber)
        var cc = this.user.getCoutryCode('+' + mobileNumber.toString());
        if (cc) {
          this.mobileNumber = cc[2].toString();
          var ccObj = this.user.getCountryObj(cc[1])
          if (ccObj && ccObj[0])
            this.registerFormAlnahdi.get(['basic', 'country']).setValue(ccObj[0])
        }
        console.info('passportNo', passportNo, this.registerFormAlnahdi)
        if (!this.editDetails['national_id'] && !this.editDetails['iqama_id']) {
          if (iqamaId || nationalId)
            this.registerFormAlnahdi.get(['basic', 'nationalid']).setValue(nationalId || iqamaId)
          this.registerControls[0].child[1].readonly = true;
        }
        if (data.nationlity) {
          let nationality = this.user.getCountryObj(null, data.nationlity.toLowerCase())
          if (nationality && nationality.length) {
            this.registerFormAlnahdi.get(['basic', 'country']).setValue(nationality[0]);
          }
        }
        if (data.occupationCode) {
          let occ = this.occupationList.find(r => r.code === data.occupationCode)
          this.registerFormAlnahdi.get(['basic', 'occupation']).setValue(occ || null)
        }
        if (data.maritalStatusCode) {
          let mar = this.maritalList.find(r => r.code === data.maritalStatusCode)
          this.registerFormAlnahdi.get(['basic', 'maritalStatus']).setValue(mar || null);
        }
        if (data.religionCode) {
          let rel = this.religionList.find(r => r.code === data.religionCode)
          this.registerFormAlnahdi.get(['basic', 'religion']).setValue(rel || null);
        }
        if (this.showMissingMandatoryParams) {
          this.hideDemographics()
        }
        console.log('register form ', this.registerFormAlnahdi);
      }
    }, err => {
      this.setUserNames(names)
    })
  }

  private hideDemographics() {
    this.registerControls.forEach(group => [
      group.child.forEach(control => [
        control.hide = this.registerFormAlnahdi.get([group.name, control.name]).value || control.hide
      ])
    ])
  }

  public updateFieldProperty() {}
  private setUserNames(nameObj) {
    if (nameObj.firstName) {
      this.registerFormAlnahdi.get(['basic', 'fName']).setValue(nameObj.firstName);
      if (this.editDetails['billingGrp'] != 'CASH' && this.canClearPrefilledForm)
        this.registerControls[0].child[4].readonly = true
    }
    if (nameObj.secondName) {
      this.registerFormAlnahdi.get(['basic', 'middlename']).setValue(nameObj.secondName);
      if (this.editDetails['billingGrp'] != 'CASH' && this.canClearPrefilledForm)
        this.registerControls[0].child[5].readonly = true
    }
    if (nameObj.lastName) {
      this.registerFormAlnahdi.get(['basic', 'lName']).setValue(nameObj.lastName);
      if (this.editDetails['billingGrp'] != 'CASH' && this.canClearPrefilledForm)
        this.registerControls[0].child[6].readonly = true
    }
  }
  public checkConset(value) {
    console.log("c",value)
    this.consetCheckbox = value;
  }
  public closeConsetModal() {
    this.showConsetModal = false;
    this.consetCheckbox = false;
  }
  public openTcModal() {
    this.TcModal = true;
  }
  public cancelTermsAndConditions() {
    this.TcModal = false;
    this.tcCheckbox = false;
  }
  public continueTcTermsAndConditions() {
    this.isFormNotValid = false;
    let payload = this.getRegisterParams()
    console.log('payload---', JSON.stringify(payload))
    this.user.addLinkedPatient(payload).subscribe(data => {
      console.log('update success params---', data)
      this.user.updateAfterPatientModification(data['repoToken'], data['token']['token']);
      this.user.setLinkedPatient(data);
      this.closeModal(data.uhid)
    }, err => {
      console.log('error addLinkedPatient', err)
      if (err.error && err.error.error && err.error.error.showErrorLabel) {
        this.errorRegPat(err)
      }
    });
  }
  public checkTermsAndConditions(value) {
    this.tcCheckbox = value;
  }

  public updatePatientInfo() {
    this.isFormNotValid = false;
    let payload = this.getRegisterParams()

    this.user.addLinkedPatient(payload).subscribe(data => {
      console.log('update success params---', data)
      this.user.updateAfterPatientModification(data['repoToken'], data['token']['token']);
      this.user.setLinkedPatient(data);
      this.closeModal(data.uhid)
      this.clearPrefilledForm();
    });
  }

  public checkRegisterFormDisable() {
    if (this.registerFormAlnahdi && this.registerFormAlnahdi.valid)
      return null;
    return true;
  }
  
  setPayerGroupValue(p) {
    this.registerFormAlnahdi.get(['basic', 'payerGroup']).setValue(p['code'])
    this.viewInsCompModal = false;
  }
  public checkForDemographics(source) {
    if (!this.demographicsPrefill) return;
    const citizenMapping = {
      N: 'CITIZEN',
      INT: 'NONCITIZEN',
      V: 'VISITOR'
    }
    let currentNationality = citizenMapping[this.citizenCtrlNahdi];
    let primaryId = this.registerFormAlnahdi.get(['basic', 'nationalid'])?.value
    const autofillFields = {
      nationalId: this.citizenCtrlNahdi == 'N' ? primaryId : null,
      iqamaId: this.citizenCtrlNahdi == 'INT' ? primaryId : null,
      borderId: this.citizenCtrlNahdi == 'V' ? primaryId : null,
      passport: this.registerFormAlnahdi.get(['basic', 'passport'])?.value
    }
    const mapping = this.demographicsPrefill?.[currentNationality]
    if (mapping && autofillFields[mapping.field] && (mapping.length == -2 || autofillFields[mapping.field]?.length == mapping.length) && this.lastFetchedId != autofillFields[mapping.field] && source == mapping.type) {
      this.lastFetchedId = autofillFields[mapping.field]
      this.user.getInsuranceDemographics(autofillFields[mapping.field]).subscribe(response => {
        this.prefillPatientDetails(response)
      })
    }
  }
  private prefillPatientDetails(patientDetails) {
    let fields = []
    if (patientDetails && ['M', 'F'].includes(patientDetails.gender)) {
      let foundGender = GENDER_ALNAHDI.find(g => g.value == patientDetails.gender)
      this.registerFormAlnahdi.get(['basic', 'gender']).setValue(foundGender);
      fields.push('gender')
    }
    else
      this.registerFormAlnahdi.get(['basic', 'gender']).setValue('');

    if (patientDetails && patientDetails.dateofbirth) {
      let dob = moment(patientDetails.dateofbirth.split("+")[0]);
      fields.push('dob')
      this.registerFormAlnahdi.get(['basic', 'dob']).setValue(dob);

    }
    if (patientDetails && patientDetails.firstName) {
      this.registerFormAlnahdi.get(['basic', 'fName']).setValue(patientDetails.firstName);
      fields.push('fName')
    }
    if (patientDetails && patientDetails.secondName && patientDetails.secondName.length > 0) {
      this.registerFormAlnahdi.get(['basic', 'middlename']).setValue(patientDetails.secondName);
      fields.push('middlename')
    }
    if (patientDetails && patientDetails.lastName) {
      this.registerFormAlnahdi.get(['basic', 'lName']).setValue(patientDetails.lastName);
      fields.push('lName')
    }
    return fields;
  }

  public inputBlur(groupName, controlName) {
    let allowedGroups = ['basic']
    let allowedControls = ['nationalid', 'passport']

    if (allowedGroups.includes(groupName) && allowedControls.includes(controlName)) {
      this.checkForDemographics('BLUR')
    }
  }
  private setOccupationData(occupationList) {
    this.occupationList = occupationList || []
  }
  private setMaritalData(maritalList) {
    this.maritalList = maritalList || []
  }
  private setReligionData(religionList) {
    this.religionList = religionList || []
  }
}