import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from '../pipes/pipes.module';
import { InsuranceDetailsComponent } from './insurance-details.component';
import { CustomInputGroupModule } from '../custom-input-group/custom-input-group.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RemovePoliyModalModule } from '../remove-poliy-modal/remove-poliy-modal.module';
import { RegisterNewPatientModule } from '../register-new-patient/register-new-patient.module';



@NgModule({
  declarations: [InsuranceDetailsComponent],
  imports: [
    CommonModule,
    PipesModule,
    NgbCarouselModule,
    CustomInputGroupModule, FormsModule, ReactiveFormsModule, RemovePoliyModalModule, RegisterNewPatientModule
  ],
  exports: [
    InsuranceDetailsComponent
  ]
})
export class InsuranceDetailsModule { }
