<div id="book-appointment" class="right-nav showModal display-modal" overflow-scroll="true">
  <div class="component">
    <div class="header border-bottom">
      <div class="font-primary-subtitle bold">
        <ng-container *ngIf="showMissingMandatoryParams; else NORMAL">
          <ng-container >{{"MISSING#DEMOGRAPHICS#MODAL#TITLE" | translate}}</ng-container>
        </ng-container>
        <ng-template #NORMAL>
          <ng-container *ngIf="isEdit">{{'sub_edit_patient' | translate}}</ng-container>
          <ng-container *ngIf="!isEdit">{{'sub_register_patient' | translate}}</ng-container>
        </ng-template>
      </div>
      <div (click)="closeRegisterPatient()" class="float-right closeicon pointer">
        <img class="type-image" src="./assets/icons/close_black.svg">
      </div>
    </div>
    <div class="content" style="overflow-y: scroll;">
      <div class="form">
        <div class="patient-pay-wrapper" *ngIf="standalone && charges && charges['registrationCharges']">
          <img src="assets/icons/info.svg" alt="">
          <div class="patient-pay">
            {{'New Patient Registration Charges'}} {{currency_code}} {{charges['registrationCharges']}} {{'will be applicable'}}
          </div>
        </div>
        <div class="ins-info-wrapper" *ngIf="!showMissingMandatoryParams">
          <div class="ins-info-card">
            <img src="./assets/icons/infoicon.svg" alt="">
            {{'REGPAT#INFO#MESSAGE#MANDATORY' | translate}}
          </div>
        </div>
        <div class="ins-info-wrapper" *ngIf="showMissingMandatoryParams">
          {{"MISSING#DEMOGRAPHICS#MODAL#MESSAGE" | translate}}
        </div>
        <form [formGroup]="registerFormAlnahdi" autocomplete="off" *ngIf="registerControls && registerControls.length>0">
          <ng-container *ngFor="let groups of registerControls;let index=index;">
          <!-- <fieldset [formGroupName]="groups.name" [ngClass]="{'box-form-css':groups.name=='basic_box'}"> -->
            <ng-container *ngFor="let controls of groups.child">
              <div class="break" [ngClass]="{'break-web': controls.breakWeb}"></div>
              <div class="relative-div form-input-div" [formGroupName]="groups.name" *ngIf="!controls.hide">
                <ng-container *ngIf="controls.input=='text'">
                  <app-custom-input-group [label]="controls.title" [formControlName]="controls.name" type="text"
                    [required]="isRequiredField(groups.name, controls.name)" [readonly]="controls.readonly"
                    [maxLength]="controls.maxLength" [minLength]="controls.minLength" [hasError]="getError(groups.name,controls.name)" (blur)="inputBlur(groups.name, controls.name)">
                  </app-custom-input-group>
                  <!-- <p *ngIf="getError(groups.name,controls.name)">Enter valid name</p> -->
                  <app-form-input-error [control]="getErrorValidation(groups.name,controls.name)">
                  </app-form-input-error>
                </ng-container>
                <ng-container *ngIf="controls.input=='number'">
                  <app-custom-input-group [label]="controls.title" [formControlName]="controls.name" type="number"
                    [required]="isRequiredField(groups.name, controls.name)" [readonly]="controls.readonly"
                    [maxLength]="controls.maxLength" [minLength]="controls.minLength" [hasError]="getError(groups.name,controls.name)" (blur)="inputBlur(groups.name, controls.name)">
                  </app-custom-input-group>
                  <app-form-input-error [control]="getErrorValidation(groups.name,controls.name)">
                  </app-form-input-error>
                </ng-container>
                <ng-container *ngIf="controls.input=='alphanumeric'">
                  <app-custom-input-group [label]="controls.title" [formControlName]="controls.name" type="text"
                    [required]="isRequiredField(groups.name, controls.name)" [readonly]="controls.readonly"
                    [maxLength]="controls.maxLength" [minLength]="controls.minLength" [hasError]="getError(groups.name,controls.name)" (blur)="inputBlur(groups.name, controls.name)">
                  </app-custom-input-group>
                  <app-form-input-error [control]="getErrorValidation(groups.name,controls.name)">
                  </app-form-input-error>
                </ng-container>
                <ng-container *ngIf="controls.input=='name'">
                  <app-custom-input-group [label]="controls.title" [formControlName]="controls.name" type="name"
                    [required]="isRequiredField(groups.name, controls.name)" [readonly]="controls.readonly"
                    [maxLength]="controls.maxLength" [hasError]="getError(groups.name,controls.name)" (blur)="inputBlur(groups.name, controls.name)">
                  </app-custom-input-group>
                  <app-form-input-error [control]="getErrorValidation(groups.name,controls.name)">
                  </app-form-input-error>
                </ng-container>
                <ng-container *ngIf="controls.input=='date'">
                  <app-custom-input-group [label]="controls.title" [formControlName]="controls.name" type="date"
                    [required]="isRequiredField(groups.name, controls.name)" [readonly]="controls.readonly" [preferWheel]="true"
                    [dateMax]="controls.dateMax" [dateMin]="controls.dateMin"
                    [dateValue]="registerFormAlnahdi.get([groups.name,controls.name]).value" [hasError]="getError(groups.name,controls.name)">
                  </app-custom-input-group>
                  <app-form-input-error [control]="getErrorValidation(groups.name,controls.name)">
                  </app-form-input-error>
                </ng-container>
                <ng-container *ngIf="controls.input=='custom_radio'">
                  <app-custom-input-group [label]="controls.title" [formControlName]="controls.name" type="custom_radio"
                    [required]="isRequiredField(groups.name, controls.name)" [readonly]="false"
                    [radioList]="controls.radioList" [radioOptions]="controls.radioOptions"></app-custom-input-group>
                  <app-form-input-error [control]="getErrorValidation(groups.name,controls.name)">
                  </app-form-input-error>
                </ng-container>
                <ng-container *ngIf="controls.input=='segment'">
                  <app-custom-input-group [label]="controls.title" [formControlName]="controls.name" type="segment"
                    [required]="isRequiredField(groups.name, controls.name)" [readonly]="false"
                    [radioList]="controls.radioList" [radioOptions]="controls.radioOptions"></app-custom-input-group>
                  <app-form-input-error [control]="getErrorValidation(groups.name,controls.name)">
                  </app-form-input-error>
                </ng-container>
                <ng-container *ngIf="controls.input=='default_radio'">
                  <app-custom-input-group [label]="controls.title" [formControlName]="controls.name"
                    type="default_radio" [required]="isRequiredField(groups.name, controls.name)" [readonly]="false"
                    [radioList]="controls.radioList" [radioOptions]="controls.radioOptions"></app-custom-input-group>
                  <app-form-input-error [control]="getErrorValidation(groups.name,controls.name)">
                  </app-form-input-error>
                </ng-container>
                <ng-container *ngIf="controls.input=='select'">
                  <app-custom-input-group [label]="controls.title" [formControlName]="controls.name" type="select"
                    [required]="isRequiredField(groups.name, controls.name)" [selectList]="controls.selectList"
                    [selectOptions]="controls.selectOptions" [readonly]="false"
                    [selectedValue]="registerFormAlnahdi.get([groups.name,controls.name]).value"  [hasError]="getError(groups.name,controls.name)">
                  </app-custom-input-group>
                  <app-form-input-error [control]="getErrorValidation(groups.name,controls.name)">
                  </app-form-input-error>
                </ng-container>
                <ng-container *ngIf="controls.input=='search_select' || controls.input=='relation'">
                  <app-custom-input-group [label]="controls.title"
                    [required]="isRequiredField(groups.name, controls.name)" [formControlName]="controls.name"
                    type="search_select" [selectList]="controls.selectList" [selectOptions]="controls.selectOptions"
                    idval="{{'REFPATREG'+controls.name.toUpperCase()}}" [readonly]="controls.readonly"
                    [selectedValue]="registerFormAlnahdi.get([groups.name,controls.name]).value"
                    [conditionalFieldValue]="controls.conditionalField && registerFormAlnahdi.get([groups.name,controls.conditionalField]) ?
                    registerFormAlnahdi.get([groups.name,controls.conditionalField]).value: null"  [hasError]="getError(groups.name,controls.name)" [hideSearch]="controls.hideSearch" [enableRefresh] ="controls.enableRefreshView">
                  </app-custom-input-group>
                  <app-form-input-error [control]="getErrorValidation(groups.name,controls.name)">
                  </app-form-input-error>
                </ng-container>
                <ng-container *ngIf="controls.input=='country_select_img'">
                  <app-custom-input-group [label]="controls.title"
                    [required]="isRequiredField(groups.name, controls.name)" [formControlName]="controls.name"
                    type="country_select_img" [selectList]="controls.selectList"
                    [selectOptions]="controls.selectOptions" [skipCountryCode]="controls.skipCountryCode"
                    idval="{{'REFPATREG'+controls.name.toUpperCase()}}" [readonly]="controls.readonly"
                    [hasError]="getError(groups.name,controls.name)">
                  </app-custom-input-group>
                  <app-form-input-error [control]="getErrorValidation(groups.name,controls.name)">
                  </app-form-input-error>
                </ng-container>
                <ng-container *ngIf="controls.input=='checkbox'">
                  <app-custom-input-group [label]="controls.title" [formControlName]="controls.name"
                  type="checkbox" [required]="isRequiredField(groups.name, controls.name)" [readonly]="controls.readonly"
                  [radioList]="controls.radioList" [radioOptions]="controls.radioOptions"></app-custom-input-group>
                  <app-form-input-error [control]="getErrorValidation(groups.name,controls.name)">
                  </app-form-input-error>
                </ng-container>
              </div>
            </ng-container>
          <!-- </fieldset> -->
        </ng-container>
        </form>
      </div>
    </div>
    <div class="footer">
      <ng-container *ngIf="showMissingMandatoryParams; else NORMALFOOTER">
        <button class="secondary-btn" (click)="closeModal(null, null, 'CWCSH')">{{"MISSING#DEMOGRAPHICS#BTN#CASH" | translate}}</button>
        <button class="primary-button" (click)="updatePatientInfo()" [disabled]="registerFormAlnahdi && registerFormAlnahdi.invalid">{{"MISSING#DEMOGRAPHICS#BTN#SUBMIT" | translate}}</button>
      </ng-container>
      <ng-template #NORMALFOOTER>
        <ng-container *ngIf="!editDetails">
          <button class="secondary-btn" (click)="proceedRegister()" [disabled]="registerFormAlnahdi && registerFormAlnahdi.invalid">{{(isEdit?'btn_update':'REGISTER#CONFIRM#BUTTON#CASHREGISTER')|translate}}</button>
          <button class="primary-button" (click)="viewInsCompModal = true" [disabled]="registerFormAlnahdi && registerFormAlnahdi.invalid">{{'PAT_REGISTRATION#FORM#BTN#INSURANCECOMPANY'|translate}}</button>
        </ng-container>
        <ng-container *ngIf="editDetails">
          <button class="primary-button" (click)="updatePatientInfo()" [disabled]="registerFormAlnahdi && registerFormAlnahdi.invalid">{{'btn_update'|translate}}</button>
        </ng-container>
      </ng-template>
    </div>
  </div>
  <div id="insurance-error-modal-container" *ngIf="insuranceError">
    <div class="insurance-error-modal">
      <div class="error-modal-header">
        <div class="font-primary-subtitle">{{"ttl_insurance" | translate}}</div>
        <div class="cursor-pointer" (click)="closeInsModal('CLOSE')"><img class="type-image" src="./assets/icons/close_black.svg"></div>
      </div>
      <div class="error-modal-content">
        <div class="content-enclosure">
          <div class="image-wrapper">
            <img src="./assets/icons/registerpat/oops_ins.svg">
          </div>
          <div class="errormessage font-default-subtitle bold">{{"REGISTER#INS#FETCH#TITLE"| translate}}</div>
          <div class="errormessage">{{insuranceErrorMessage || "REGISTER#INS#FETCH#SUBHEADER"| translate}}</div>
          <div class="alert-con err-ins-con">
            <img src="./assets/icons/registerpat/phone_ins.svg" alt="">
            <p class="ins-error-p cursor-pointer">
              {{"REGISTER#INS#FETCH#MSG"| translate}}<span
                (click)="openDialer()">{{"REGISTER#INS#FETCH#MSG#CALL"| translate}}</span>
            </p>
          </div>
        </div>
        <div class="footer-enclosure">
          <button class="primary-button-outline" (click)="closeInsModal('CASH')">{{"lbl_continue_with_cash_close"| translate}}</button>
          <button class="primary-button" (click)="closeInsModal('CASH')">{{"lbl_continue_with_cash"| translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <div id="insurance-error-modal-container" *ngIf="regError">
    <div class="insurance-error-modal">
      <div class="error-modal-header">
        <div class="font-primary-subtitle">{{"ttl_insurance" | translate}}</div>
        <div class="cursor-pointer" (click)="closeRegModal('CLOSE')"><img class="type-image" src="./assets/icons/close_black.svg"></div>
      </div>
      <div class="error-modal-content">
        <div class="content-enclosure">
          <div class="image-wrapper">
            <img src="./assets/icons/registerpat/oops_ins.svg">
          </div>
          <div class="errormessage">{{regErrorMessage || "REGISTER#PAT#ERROR#SUBHEADER"| translate}}</div>
        </div>
        <div class="footer-enclosure">
          <button class="primary-button full-width-mobile" (click)="openDialer()">{{"REGISTER#PAT#ERROR#BUTTON#CALLUS"| translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <!-- consent  -->
  <div id="consent-error-modal-container" *ngIf="showConsetModal">
    <div class="consent-error-modal">
      <div class="consent-modal-header">
        <div class="font-primary-subtitle">
          {{"Consent" | translate}}</div>
      </div>
      <div class="consent-modal-content">

        <div class="consent-checkbox-text-wrapper">
        <div class="consent-checkbox">
          <input type="checkbox" class="checkbox" [(ngModel)]="consetCheckbox" (change)="checkConset(consetCheckbox)">

        </div>
        <div class="consent-text">
          <span class="consent-message">{{'I_hereby_declare_that_the_information_provided_is_accurate_and_correct.' | translate}}</span>
        </div>
        </div>

        <div class="consent-footer-wrapper">
        <div class="consent-footer">
          <button class="cancel-btn" (click)="closeConsetModal()">{{"Cancel"| translate}}</button>
          <button class="continue-btn" (click)="continueTcTermsAndConditions()" [disabled]="!consetCheckbox">{{"Continue"| translate}}</button>
        </div>
        </div>
      </div>
    </div>
  </div>
   <!-- terms and conditions -->
  <div id="tc-error-modal-container" *ngIf="TcModal">
    <div class="tc-error-modal">
      <div class="tc-modal-header">
        <div class="font-primary-subtitle">
          {{"Terms_and_Conditions" | translate}}</div>
      </div>
      <div class="tc-modal-content">

        <div class="tc-text-wrapper">
        <p>
          {{'Lorem_ipsum_dolor_sit_amet,_consectetur_adipiscing_elit,_sed_do_eiusmod_tempor_incididunt ut_labore_et_dolore_magna_aliqua._Ut_enim_ad_minim_veniam,_quis_nostrud_exercitation_ullamco ' | translate }}
        </p>
        <div class="tc-text-wrapper-list">
        <ol class="list">
          <li>{{'Lorem_ipsum_dolor_sit_amet,_consectetur_adipiscing_elit.'|translate}}</li>
          <li>{{'Lorem_ipsum_dolor_sit_amet,_consectetur_adipiscing_elit.'|translate}}</li>
          <li>{{'Lorem_ipsum_dolor_sit_amet,_consectetur_adipiscing_elit.'|translate}}</li>
        </ol>  
        </div>
        </div>
        </div>

        <div class="tc-footer">
          <div class="tc-checkbox-text-wrapper">
            <div class="tc-checkbox">
              <input type="checkbox" class="checkbox" [(ngModel)]="tcCheckbox" (change)="checkTermsAndConditions(tcCheckbox)">
            </div>
            <div class="tc-text">
              <span class="tc-message">{{'I_accept_to_the_Terms_and_Conditions' | translate}}</span>
            </div>
            </div>
            <div class="btn-outer-wrapper">
            <div class="btn-wrapper">
          <button class="cancel-btn" (click)="cancelTermsAndConditions()">{{"Cancel"| translate}}</button>
          <button class="continue-btn" (click)="continueTcTermsAndConditions()"  [disabled]="!tcCheckbox">{{"Continue"| translate}}</button>
          </div>
          </div>
      </div>
    </div>
  </div>
  <div class="ins-comp-container" *ngIf="viewInsCompModal">
  <!-- <div class="ins-comp-container"> -->
    <div class="ins-comp-modal">
      <div class="ins-comp-header">
        <div class="font-primary-subtitle">
          {{"Select_insurance_company" | translate}}</div>
          <img src="./assets/icons/close_black.svg" (click)="viewInsCompModal = false" alt="">
      </div>
      <div class="ins-comp-content">
        <div class="search-wrapper">
          <img src="./assets/icons/search.svg" alt="">
          <input type="text" [(ngModel)]="insCompSearch" [placeholder]="'Search' | translate" name="" id="">
        </div>
        <div class="pay-group-list">
          <div class="pay-group-item" *ngFor="let p of payGroupList | searchFilter:insCompSearch:'desc'" (click)="setPayerGroupValue(p)">
              {{p['desc']}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ins-comp-container ispolicy-details" *ngIf="policyDetails">
    <!-- <div class="ins-comp-container"> -->
      <div class="ins-comp-modal">
        <div class="ins-comp-header">
          <div class="font-primary-subtitle">
            {{"Confirm_policy_details" | translate}}</div>
            <img src="./assets/icons/close_black.svg" (click)="policyDetails = null" alt="">
        </div>
        <div class="ins-comp-content">
          <div class="policy-container" >
            <div class="policy-item-con first-row border-bottom" *ngIf="policyDetails.custGroupName">
              <p>{{"MANAGEPOLICY#DETAIL#LABEL#INSCOMPANY" | translate}}</p>
              <p class="policy-val">{{policyDetails.custGroupName}}</p>
            </div>
            <div class="policy-item-con border-bottom" *ngIf="policyDetails.payerName">
              <p>{{"MANAGEPOLICY#DETAIL#LABEL#PAYERNAME" | translate}}</p>
              <p class="policy-val">{{policyDetails.payerName}}</p>
            </div>
            <div class="policy-item-con border-bottom" *ngIf="policyDetails.policyName">
              <p>{{"MANAGEPOLICY#DETAIL#LABEL#POLICYNAME" | translate}}</p>
              <p class="policy-val">{{policyDetails.policyName}}</p>
            </div>
            <div class="policy-item-con border-bottom" *ngIf="policyDetails.policyNumber">
              <p>{{"MANAGEPOLICY#DETAIL#LABEL#POLICYNUMBER" | translate}}</p>
              <p class="policy-val">{{policyDetails.policyNumber}}</p>
            </div>
            <div class="policy-item-con border-bottom" *ngIf="policyDetails.membershipNo">
              <p>{{"MANAGEPOLICY#DETAIL#LABEL#MEMBERID" | translate}}</p>
              <p class="policy-val">{{policyDetails.membershipNo}}</p>
            </div>
            <div class="policy-item-con border-bottom" *ngIf="policyDetails.policyStartDate">
              <p>{{"MANAGEPOLICY#DETAIL#LABEL#EFFECTIVEFROM" | translate}}</p>
              <p class="policy-val">{{policyDetails.policyStartDate}}</p>
            </div>
            <div class="policy-item-con border-bottom" *ngIf="policyDetails.policyExpiryDate">
              <p>{{"MANAGEPOLICY#DETAIL#LABEL#EFFECTIVETILL" | translate}}</p>
              <p class="policy-val">{{policyDetails.policyExpiryDate}}</p>
            </div>
          </div>
          <div class="consent-checkbox-text-wrapper">
            <div class="consent-checkbox">
              <input type="checkbox" class="checkbox" [(ngModel)]="consetCheckbox" (change)="checkConset(consetCheckbox)">
    
            </div>
            <div class="consent-text">
              <span class="consent-message">{{'I_hereby_declare_that_the_information_provided_is_accurate_and_correct.' | translate}}</span>
            </div>
            </div>
          <div class="button-bar"><button class="primary-button" [disabled]="!consetCheckbox" (click)="fillAutofillParams()">
            {{'REGISTER#PAT#INS#BUTTON#REGISTER' | translate}}
          </button></div>
        </div>
      </div>
    </div>
</div>
